import * as actionTypes from "./actionTypes"

export const fetchShelfsDataRequest = () => ({
  type: actionTypes.FETCH_SHELFS_DATA_REQUEST,
})

export const fetchShelfsDataSuccess = shelfsData => ({
  type: actionTypes.FETCH_SHELFS_DATA_SUCCESS,
  payload: shelfsData,
})

export const fetchShelfsDataError = error => ({
  type: actionTypes.FETCH_SHELFS_DATA_ERROR,
  error,
})

export const updateExpirationDateRequest = shelfId => ({
  type: actionTypes.UPDATE_EXPIRATION_DATE_REQUEST,
  payload: shelfId,
})

export const updateExpirationDateSuccess = updatedShelf => ({
  type: actionTypes.UPDATE_EXPIRATION_DATE_SUCCESS,
  payload: updatedShelf,
})

export const updateExpirationDateError = error => ({
  type: actionTypes.UPDATE_EXPIRATION_DATE_ERROR,
  error,
})

export const fetchProductsByIpc = ipcNo => ({
  type: actionTypes.FETCH_PRODUCTS_BY_IPC_REQUEST,
  payload: ipcNo,
})

export const fetchShelfByIpcAndColumn = (locId, columnNo) => ({
  type: actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_REQUEST,
  payload: { locId, columnNo },
})

export const fetchShelfByIpcAndColumnSuccess = data => ({
  type: actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_SUCCESS,
  payload: data,
})

export const fetchShelfByIpcAndColumnFailure = error => ({
  type: actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_FAILURE,
  error,
})
