import React, { useState, useMemo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { addVehicleStock } from "../../../store/vehicle/actions"
import { fetchWarehouseStock } from "../../../store/warehouse/actions"
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"

const AddItemVehicleStockModal = ({
  isOpen,
  toggle,
  goods,
  selectedDeliveryVehicleId,
}) => {
  const [selectedGood, setSelectedGood] = useState(null)
  const [quantities, setQuantities] = useState([0, 0, 0]) // 3つの数量を管理
  const [searchQuery, setSearchQuery] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: "goods_id",
    direction: "ascending",
  })
  const [selectedWarehouseId, setSelectedWarehouseId] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedWarehouseId) {
      dispatch(fetchWarehouseStock(selectedWarehouseId))
    }
  }, [selectedWarehouseId, dispatch])

  const warehouseStocks =
    useSelector(state => state.warehouseReducer.warehouseStocks) || []
  const warehouses = useSelector(state => state.warehouseReducer.warehouses)

  const handleAddItem = () => {
    if (!selectedGood) return

    // quantities[0]が百の位、quantities[1]が十の位、quantities[2]が一の位とする
    const stockCount = quantities[0] * 100 + quantities[1] * 10 + quantities[2]

    const newItem = {
      delivery_vehicle_id: selectedDeliveryVehicleId,
      goods_id: selectedGood.goods_id,
      stock_count: stockCount, // 百の単位、十の単位、一の単位から計算された数値を送信
    }
    dispatch(addVehicleStock(newItem))

    // 選択した商品や数値をクリアする
    setSelectedGood(null)
    setQuantities([0, 0, 0])

    toggle() // モーダルを閉じる
  }

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const sortedGoods = useMemo(() => {
    let sortableGoods = [...warehouseStocks]
    sortableGoods.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1
      }
      return 0
    })
    return sortableGoods
  }, [warehouseStocks, sortConfig])

  const filteredGoods = sortedGoods.filter(
    good =>
      good.goods_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      good.company_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleRowClick = good => {
    setSelectedGood(good)
  }

  const handleQuantityChange = (index, increment) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities]
      newQuantities[index] = Math.min(
        9,
        Math.max(0, newQuantities[index] + increment)
      )
      return newQuantities
    })
  }

  const handleResetQuantities = () => {
    setQuantities([0, 0, 0])
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>配送車在庫に新規商品を追加</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="warehouseSelect">倉庫選択</Label>
          <Input
            id="warehouseSelect"
            name="select"
            type="select"
            value={selectedDeliveryVehicleId}
            disabled
            onChange={e => setSelectedWarehouseId(e.target.value)}
          >
            <option value="">倉庫を選択してください</option>
            {warehouses.map(warehouse => (
              <option
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id}
              >
                {warehouse.warehouse_code} - {warehouse.address}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="search">商品検索</Label>
          <Input
            id="search"
            type="text"
            placeholder="商品名または会社名で検索"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </FormGroup>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          <ul style={{ padding: 0, listStyleType: "none" }}>
            {filteredGoods.map(good => (
              <li
                key={good.goods_id}
                onClick={() => handleRowClick(good)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor:
                    selectedGood && selectedGood.goods_id === good.goods_id
                      ? "#d3d3d3"
                      : "transparent",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {good.goods_no} : {good.goods_name}
              </li>
            ))}
          </ul>
        </div>
        <FormGroup>
          <Label>数量</Label>
          <div className="quantity-inputs">
            <button
              type="button"
              className="reset-button"
              onClick={handleResetQuantities}
            >
              ×
            </button>
            {quantities.map((quantity, index) => (
              <div key={index} className="quantity-input">
                <button
                  type="button"
                  className="arrow up"
                  onClick={() => handleQuantityChange(index, 1)}
                >
                  <FaChevronCircleUp style={{ color: "blue" }} />
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="quantity-number"
                />
                <button
                  type="button"
                  className="arrow down"
                  onClick={() => handleQuantityChange(index, -1)}
                >
                  <FaChevronCircleDown style={{ color: "red" }} />
                </button>
              </div>
            ))}
            <span className="unit-label">個</span>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Button color="secondary" onClick={toggle} style={{ width: "45%" }}>
          キャンセル
        </Button>
        <Button
          color="primary"
          onClick={handleAddItem}
          style={{ width: "45%" }}
        >
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddItemVehicleStockModal
