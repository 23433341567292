// reducers.js

import {
  FETCH_USERS_DATA,
  FETCH_USERS_DATA_SUCCESS,
  FETCH_USERS_DATA_ERROR,
  FETCH_USERS_IPC_DATA,
  FETCH_USERS_IPC_DATA_SUCCESS,
  FETCH_USERS_IPC_DATA_ERROR,
  FETCH_USER_DETAIL,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./actionTypes"

const initialState = {
  userData: [],
  userIpcData: [],
  loading: false,
  error: null,
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USERS_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      }
    case FETCH_USERS_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_USERS_IPC_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USERS_IPC_DATA_SUCCESS:
      return {
        ...state,
        userIpcData: action.payload,
        loading: false,
      }
    case FETCH_USERS_IPC_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_USER_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      }
    case FETCH_USER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_USER: // ユーザー更新開始
      return {
        ...state,
        loading: true,
      }
    case UPDATE_USER_SUCCESS: // ユーザー更新成功
      return {
        ...state,
        userData: action.payload,
        loading: false,
      }
    case UPDATE_USER_ERROR: // ユーザー更新失敗
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default usersReducer
