import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = {
        ...state,
        loading: true,
      }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.data.message, // 更新成功メッセージ
      };
      break
    case PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profileReducer
