import * as actionTypes from "./actionTypes"

const initialState = {
  companiesData: [], // 変数名をcamerasDataに合わせて変更
  loading: false,
  error: null,
}

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_REQUEST:
    case actionTypes.REGISTER_COMPANY_REQUEST:
    case actionTypes.UPDATE_COMPANY_REQUEST:
    case actionTypes.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.FETCH_COMPANIES_SUCCESS:
    case actionTypes.REGISTER_COMPANY_SUCCESS:
    case actionTypes.UPDATE_COMPANY_SUCCESS:
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companiesData: action.payload, // ペイロードの形式を合わせる
        loading: false,
      }
    case actionTypes.FETCH_COMPANIES_FAILURE:
    case actionTypes.REGISTER_COMPANY_FAILURE:
    case actionTypes.UPDATE_COMPANY_FAILURE:
    case actionTypes.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.payload, // ペイロードの形式を合わせる
        loading: false,
      }
    default:
      return state
  }
}

export default companiesReducer
