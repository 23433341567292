import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import {
  updateVehicleStock,
  deleteVehicleStock,
} from "../../../store/vehicle/actions"
import "./UpdateItemVehicleStockModal.css" // カスタムCSSファイルのインポート

const UpdateItemVehicleStockModal = ({
  isOpen,
  toggle,
  item,
  vehicles,
  selectedDeliveryVehicleId,
}) => {
  const dispatch = useDispatch()

  const [updatedItem, setUpdatedItem] = useState({
    vehicle_stock_id: "",
    vehicle_id: "",
    stock_count: 0,
  })

  const [itemDetails, setItemDetails] = useState({
    goods_no: "",
    goods_name: "",
  })

  const [isDeleteChecked, setIsDeleteChecked] = useState(false) // 削除用チェックボックスの状態

  useEffect(() => {
    if (item) {
      setUpdatedItem({
        vehicle_stock_id: item.vehicle_stock_id || "",
        vehicle_id: item.vehicle_id || selectedDeliveryVehicleId || "",
        stock_count: item.stock_count || 0,
      })
      setItemDetails({
        goods_no: item.goods_no || "",
        goods_name: item.goods_name || "",
      })
    }
  }, [item, selectedDeliveryVehicleId])

  const handleChange = e => {
    const { name, value } = e.target
    setUpdatedItem({
      ...updatedItem,
      [name]: name === "stock_count" ? Number(value) : value,
    })
  }

  const handleUpdate = () => {
    if (isDeleteChecked) {
      // チェックボックスがオンの場合、削除処理
      dispatch(
        deleteVehicleStock(item.vehicle_stock_id, selectedDeliveryVehicleId)
      )
    } else {
      // 更新処理
      dispatch(updateVehicleStock(updatedItem))
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>配送車在庫商品情報を更新</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="vehicle_id">配送車ID</Label>
          <Input
            id="vehicle_id"
            name="vehicle_id"
            type="select"
            value={updatedItem.vehicle_id}
            disabled
          >
            <option value="">配送車を選択してください</option>
            {vehicles.map(vehicle => (
              <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                {vehicle.vehicle_name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="goods_no">商品コード</Label>
          <Input
            id="goods_no"
            name="goods_no"
            type="text"
            value={itemDetails.goods_no}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="goods_name">商品名</Label>
          <Input
            id="goods_name"
            name="goods_name"
            type="text"
            value={itemDetails.goods_name}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="stock_count">数量</Label>
          <Input
            id="stock_count"
            name="stock_count"
            type="number"
            value={updatedItem.stock_count}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup check className="delete-checkbox">
          <Label>
            <Input
              type="checkbox"
              checked={isDeleteChecked}
              onChange={e => setIsDeleteChecked(e.target.checked)}
              className="larger-checkbox"
            />
            <span className="checkbox-label">
              この商品を配送車在庫から削除する
            </span>
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Button color="secondary" onClick={toggle} style={{ width: "45%" }}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleUpdate} style={{ width: "45%" }}>
          更新
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateItemVehicleStockModal
