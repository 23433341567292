import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  salesData: [],
  error: null,
}

const salesAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SALES_ALL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SALES_ALL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        salesData: action.payload,
        error: null,
      }
    case actionTypes.FETCH_SALES_ALL_DATA_ERROR:
      return {
        ...state,
        loading: false,
        salesData: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export default salesAllReducer
