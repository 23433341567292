import React, { useState, useMemo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { addVehicleStock } from "../../../store/vehicle/actions"
import { fetchWarehouseStock } from "../../../store/warehouse/actions"

const AddItemVehicleStockModal = ({
  isOpen,
  toggle,
  goods,
  selectedDeliveryVehicleId,
}) => {
  const [selectedGood, setSelectedGood] = useState(null)
  const [stock_count, setStock_count] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: "goods_id",
    direction: "ascending",
  })
  const [selectedWarehouseId, setSelectedWarehouseId] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedWarehouseId) {
      dispatch(fetchWarehouseStock(selectedWarehouseId))
    }
  }, [selectedWarehouseId, dispatch])

  const warehouseStocks =
    useSelector(state => state.warehouseReducer.warehouseStocks) || []
  const warehouses = useSelector(state => state.warehouseReducer.warehouses)

  const handleAddItem = () => {
    const newItem = {
      delivery_vehicle_id: selectedDeliveryVehicleId,
      goods_id: selectedGood.goods_id,
      stock_count: stock_count ? stock_count : 0,
    }
    dispatch(addVehicleStock(newItem))

    toggle() // モーダルを閉じる
  }

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const sortedGoods = useMemo(() => {
    let sortableGoods = [...warehouseStocks]
    sortableGoods.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1
      }
      return 0
    })
    return sortableGoods
  }, [warehouseStocks, sortConfig])

  const filteredGoods = sortedGoods.filter(
    good =>
      good.goods_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      good.company_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>配送車在庫に新規商品を追加</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="warehouseSelect">倉庫選択</Label>
          <Input
            id="warehouseSelect"
            name="select"
            type="select"
            value={selectedWarehouseId}
            onChange={e => setSelectedWarehouseId(e.target.value)}
          >
            <option value="">倉庫を選択してください</option>
            {warehouses.map(warehouse => (
              <option
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id}
              >
                {warehouse.warehouse_code} - {warehouse.address}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="search">商品検索</Label>
          <Input
            id="search"
            type="text"
            placeholder="商品名または会社名で検索"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </FormGroup>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          <Table className="table">
            <thead>
              <tr>
                <th onClick={() => handleSort("goods_id")}>ID</th>
                <th onClick={() => handleSort("goods_name")}>商品名</th>
                <th onClick={() => handleSort("company_name")}>会社名</th>
                <th>選択</th>
              </tr>
            </thead>
            <tbody>
              {filteredGoods.map(good => (
                <tr key={good.goods_id}>
                  <td>{good.goods_id}</td>
                  <td>{good.goods_name}</td>
                  <td>{good.company_name}</td>
                  <td>
                    <Input
                      type="radio"
                      name="selectedGood"
                      value={good.goods_id}
                      onChange={() => setSelectedGood(good)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <FormGroup>
          <Label for="stock_count">数量</Label>
          <Input
            id="stock_count"
            type="number"
            value={stock_count}
            onChange={e => setStock_count(parseInt(e.target.value, 10) || 0)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleAddItem}>
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddItemVehicleStockModal
