import { call, put, takeEvery } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import { getShelfs, putShelfs } from "../../helpers/backend_helper"

// ここに実際のAPI呼び出しを記述します
const updateExpirationDateApi = shelfId => {
  // API呼び出しの実装
}

function* updateExpirationDateSaga(action) {
  try {
    const shelf = yield call(putShelfs, action.payload)
    yield put(actions.updateExpirationDateSuccess(shelf))
    yield put(actions.fetchShelfByIpcAndColumnSuccess(shelf))
  } catch (error) {
    yield put(actions.updateExpirationDateError(error.message))
  }
}

function* fetchShelfByIpcAndColumnSaga(action) {
  try {
    const shelf = yield call(
      getShelfs,
      action.payload.locId,
      action.payload.columnNo,
    )
    yield put(actions.fetchShelfByIpcAndColumnSuccess(shelf))
  } catch (error) {
    yield put(actions.fetchShelfByIpcAndColumnFailure(error.message))
  }
}

function* ShelfsSaga() {
  //yield takeEvery(actionTypes.FETCH_SHELFS_DATA_REQUEST, fetchShelfsDataSaga);
  yield takeEvery(
    actionTypes.UPDATE_EXPIRATION_DATE_REQUEST,
    updateExpirationDateSaga,
  )
  //yield takeEvery(actionTypes.FETCH_PRODUCTS_BY_IPC_REQUEST, fetchProductsByIpcSaga);
  yield takeEvery(
    actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_REQUEST,
    fetchShelfByIpcAndColumnSaga,
  )
}

export default ShelfsSaga
