import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchSoldOut } from "../../store/soldout/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api"
import { decode } from "@googlemaps/polyline-codec"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { createDeliveryPlanRequest } from "../../store/deliveryPlan/actions"

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY

const DeliveryPlanMap = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    startWarehouseId,
    endWarehouseId,
    selectedLocations,
    startDate,
    endDate,
    selectedUser,
    deliveryPlanData,
  } = location.state || {}
  const [decodedRoute, setDecodedRoute] = useState([])
  const [routeInfo, setRouteInfo] = useState(null)
  const [modalOpen, setModalOpen] = useState(false) // モーダルの表示状態を管理する状態
  const mapRef = useRef(null)

  const isLoading1 = useSelector(state => state.soldOutReducer.loading)

  // データが存在しない場合に空の配列を設定するように変更
  const route = useSelector(state => state.soldOutReducer.route) || {
    waypoints_info: [],
    waypoints_names: [],
  }
  const warehouses =
    useSelector(state => state.warehouseReducer.warehouses) || []
  const users =
    useSelector(state => state.usersReducer.userData?.data?.results) || []

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  })

  const selectedStartWarehouse = warehouses.find(
    warehouse => warehouse.warehouse_id === startWarehouseId
  )
  const selectedEndWarehouse = warehouses.find(
    warehouse => warehouse.warehouse_id === endWarehouseId
  )
  const selectedUserDetails = users.find(
    user => user.user_id === selectedUser?.value
  )

  useEffect(() => {
    if (route.route) {
      const decodedPath = decode(route.route) // デコードして経路を取得
      setDecodedRoute(decodedPath)
      setRouteInfo(route)
      if (mapRef.current && decodedPath.length > 0) {
        const bounds = new window.google.maps.LatLngBounds()
        decodedPath.forEach(([lat, lng]) => bounds.extend({ lat, lng }))
        mapRef.current.fitBounds(bounds)
      }
    }
  }, [route])

  useEffect(() => {
    if (startWarehouseId && endWarehouseId && selectedLocations.length > 0) {
      dispatch(
        fetchSoldOut({
          startWarehouseId: startWarehouseId,
          endWarehouseId: endWarehouseId,
          selectedLocations: selectedLocations,
        })
      )
    }
  }, [startWarehouseId, endWarehouseId, selectedLocations, dispatch])

  const handleConfirm = () => {
    const updatedDeliveryPlanData = {
      ...deliveryPlanData,
      route: {
        route: route.route,
        total_distance: route.total_distance,
        total_duration: route.total_duration,
        steps: route.steps,
        waypoints_info: route.waypoints_info,
        waypoints_names: route.waypoints_names,
      },
      locations: {
        total_distance: route.total_distance,
        total_duration: route.total_duration,
        waypoints: route.waypoints_names,
      },
    }

    dispatch(createDeliveryPlanRequest(updatedDeliveryPlanData))

    alert("配送計画が作成されました")

    navigate("/delivery-plan/create", {
      state: {
        startWarehouseId,
        endWarehouseId,
        selectedLocations,
        startDate,
        endDate,
        selectedUser,
      },
    })
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleModalConfirm = () => {
    toggleModal()
    handleConfirm()
  }

  if (loadError) {
    return <div>Google Maps APIのロード中にエラーが発生しました。</div>
  }

  if (!isLoaded) {
    return <div>Google Maps APIをロード中...</div>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画確認" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h2>ルート情報</h2>
                  <FormGroup>
                    <Label>担当者：</Label>
                    <Select
                      value={{
                        label: selectedUserDetails?.user_name,
                        value: selectedUserDetails?.user_id,
                      }}
                      isDisabled
                      components={makeAnimated()}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>稼働：</Label>
                    <Row>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          value={startDate}
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </Col>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          value={endDate}
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={6}>
                        <Select
                          value={{
                            label: selectedStartWarehouse?.warehouse_name,
                            value: selectedStartWarehouse?.warehouse_id,
                          }}
                          isDisabled
                          components={makeAnimated()}
                        />
                      </Col>
                      <Col xs={6}>
                        <Select
                          value={{
                            label: selectedEndWarehouse?.warehouse_name,
                            value: selectedEndWarehouse?.warehouse_id,
                          }}
                          isDisabled
                          components={makeAnimated()}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <strong>総距離: </strong>
                    {route.total_distance} km
                    <br />
                    <strong>総時間: </strong>
                    {route.total_duration} 分
                  </div>
                  <GoogleMap
                    mapContainerStyle={{ height: "400px", width: "100%" }}
                    onLoad={map => {
                      mapRef.current = map
                      if (decodedRoute.length > 0) {
                        const bounds = new window.google.maps.LatLngBounds()
                        decodedRoute.forEach(([lat, lng]) =>
                          bounds.extend({ lat, lng })
                        )
                        map.fitBounds(bounds)
                      }
                    }}
                  >
                    {decodedRoute.length > 0 && (
                      <Polyline
                        path={decodedRoute.map(([lat, lng]) => ({
                          lat,
                          lng,
                        }))}
                        options={{ strokeColor: "#FF0000" }}
                      />
                    )}
                    {route.waypoints_info &&
                      route.waypoints_info.map((point, index) => (
                        <Marker
                          key={index}
                          position={point.location}
                          label={String.fromCharCode(65 + index)} // A, B, Cなど
                        />
                      ))}
                  </GoogleMap>
                  <div>
                    {route.waypoints_names &&
                      route.waypoints_names.map((point, index) => {
                        const displayName =
                          point.name.split(":")[1] || point.name // ":"で分割して、後半部分を取得

                        return (
                          <div key={index}>
                            <strong>{String.fromCharCode(65 + index)}:</strong>{" "}
                            {displayName} :{point.address}
                          </div>
                        )
                      })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button color="success" onClick={toggleModal}>
                      配送計画策定
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() =>
                        navigate("/delivery-plan/create", {
                          state: {
                            startWarehouseId,
                            endWarehouseId,
                            selectedLocations,
                            startDate,
                            endDate,
                            selectedUser,
                          },
                        })
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      ロケーション再選択
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>配送計画策定確認</ModalHeader>
          <ModalBody>この内容で配送計画を作成しますか？</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleModalConfirm}>
              登録
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanMap
