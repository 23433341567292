import React, { useState, useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import styled from "styled-components"

// カスタムスタイルを適用するためのスタイルドコンポーネント
const StyledModalHeader = styled(ModalHeader)`
  background-color: orange;
  color: white;
`

const InstructionModal = ({ isOpen, toggle, handleHide }) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  useEffect(() => {
    const commonData = JSON.parse(localStorage.getItem("commonData"))
    if (commonData && commonData.showInstructionModal === false) {
      setDoNotShowAgain(true)
    }
  }, [])

  const handleCheckboxChange = () => {
    const newValue = !doNotShowAgain
    setDoNotShowAgain(newValue)
    const commonData = JSON.parse(localStorage.getItem("commonData")) || {}
    commonData.showInstructionModal = !newValue
    localStorage.setItem("commonData", JSON.stringify(commonData))
  }

  const handleClose = () => {
    if (doNotShowAgain) {
      handleHide()
    } else {
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <StyledModalHeader toggle={handleClose}>操作説明</StyledModalHeader>
      <ModalBody>
        <p>操作方法の説明:</p>
        <ul>
          <li>未選択状態では、商品一覧が表示されます。</li>
          <li>倉庫リストを選択すると、選択した倉庫の在庫が表示されます。</li>
          <li>
            配送車リストを選択すると、選択した配送車の在庫が表示されます。
          </li>
          <li>商品一覧画面では、サプライズ商品の設定が可能です。</li>
          <li>各商品の「修正」ボタンを押して、商品情報を更新できます。</li>
        </ul>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={doNotShowAgain}
              onChange={handleCheckboxChange}
            />{" "}
            今後表示しない
          </Label>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}

export default InstructionModal
