import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_GOODS_BRAND_GRAPH_DATA,
  FETCH_GOODS_BRAND_REG_DATA,
} from "./actionTypes"
import {
  fetchGoodsBrandRegDataSuccess,
  fetchGoodsBrandRegDataError,
  fetchGraphDataSuccess,
  fetchGraphDataError,
} from "./actions"

//Include Both Helper File with needed methods
import { getGoodsBrand, putGoodsBrand } from "../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const graphData = yield call(getGoodsBrand)
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* fetchGoodsBrandRegDataSaga(action) {
  try {
    const { targetGoods, brand, brandName, deleteFlg, createFlg } =
      action.payload
    const graphData = yield call(
      putGoodsBrand,
      targetGoods,
      brand,
      brandName,
      deleteFlg,
      createFlg
    )
    yield put(fetchGoodsBrandRegDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGoodsBrandRegDataError(error))
  }
}

function* BrandsSaga() {
  yield takeEvery(FETCH_GOODS_BRAND_GRAPH_DATA, fetchGraphDataSaga)
  yield takeEvery(FETCH_GOODS_BRAND_REG_DATA, fetchGoodsBrandRegDataSaga)
}

export default BrandsSaga
