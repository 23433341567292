import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

// ロケーショングループの取得
const fetchLocationGroups = async () => {
  try {
    const response = await get(url.GET_LOCATION_GROUPS)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの更新
const updateLocationGroup = async (groupId, groupData) => {
  try {
    const payload = {
      groupData: groupData
    };

    const response = await put(`${url.UPDATE_LOCATION_GROUP}/${groupId}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの登録
const registerLocationGroup = async (groupData) => {
  try {
    const response = await post(url.REGISTER_LOCATION_GROUP, groupData)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの削除
const deleteLocationGroup = async (groupId) => {
  try {
    const response = await del(`${url.DELETE_LOCATION_GROUP}/${groupId}`)
    return response
  } catch (error) {
    throw error
  }
}

export { fetchLocationGroups, updateLocationGroup, registerLocationGroup, deleteLocationGroup }
