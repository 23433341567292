import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  vehicles: [],
  error: null,
}

const vehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VEHICLES:
    case actionTypes.ADD_VEHICLE:
    case actionTypes.UPDATE_VEHICLE:
    case actionTypes.DELETE_VEHICLE:
    case actionTypes.FETCH_VEHICLE_STOCK: // 追加
    case actionTypes.ADD_VEHICLE_STOCK: // 追加
    case actionTypes.UPDATE_VEHICLE_STOCK: // 追加
    case actionTypes.DELETE_VEHICLE_STOCK: // 追加
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.FETCH_VEHICLES_SUCCESS:
    case actionTypes.ADD_VEHICLE_SUCCESS:
    case actionTypes.UPDATE_VEHICLE_SUCCESS:
    case actionTypes.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.FETCH_VEHICLES_ERROR:
    case actionTypes.ADD_VEHICLE_ERROR:
    case actionTypes.UPDATE_VEHICLE_ERROR:
    case actionTypes.DELETE_VEHICLE_ERROR:
    case actionTypes.FETCH_VEHICLE_STOCK_ERROR: // 追加
    case actionTypes.ADD_VEHICLE_STOCK_ERROR: // 追加
    case actionTypes.UPDATE_VEHICLE_STOCK_ERROR: // 追加
    case actionTypes.DELETE_VEHICLE_STOCK_ERROR: // 追加
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.FETCH_VEHICLE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        vehicleStock: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.ADD_VEHICLE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        vehicleStock: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.UPDATE_VEHICLE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        vehicleStock: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.DELETE_VEHICLE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        vehicleStock: action.payload,
        loading: false,
        error: null,
      }
    default:
      return state
  }
}

export default vehicleReducer
