import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchWarehouses,
  addWarehouse,
  updateWarehouse,
  deleteWarehouse,
  fetchWarehouseStock,
} from "../../store/warehouse/actions"
import { fetchCompanies } from "../../store/companies/actions"

const Warehouse = () => {
  const dispatch = useDispatch()

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)
  const [isStockModalOpen, setIsStockModalOpen] = useState(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    company_id: "",
    warehouse_code: "",
    warehouse_name: "",
    address: "",
    comment: "",
  })

  const [newWarehouse, setNewWarehouse] = useState({
    company_id: "",
    warehouse_code: "",
    warehouse_name: "",
    address: "",
    comment: "",
  })

  const warehouses = useSelector(
    state => state.warehouseReducer.warehouses || []
  )
  const isLoading = useSelector(state => state.warehouseReducer.loading)
  const companies = useSelector(
    state => state.companyReducer.companiesData || []
  )
  const warehouseStocks = useSelector(
    state => state.warehouseReducer.warehouseStocks || []
  )

  useEffect(() => {
    dispatch(fetchWarehouses())
    dispatch(fetchCompanies())
  }, [dispatch])

  const handleOpenDetailsModal = warehouse => {
    setSelectedWarehouse(warehouse)
    setIsDetailsModalOpen(true)
  }

  const handleOpenStockModal = warehouse => {
    setSelectedWarehouse(warehouse)
    dispatch(fetchWarehouseStock(warehouse.warehouse_id))
    setIsStockModalOpen(true)
  }

  const handleCloseStockModal = () => {
    setIsStockModalOpen(false)
  }

  const handleOpenNewModal = () => {
    setIsNewModalOpen(true)
  }

  const handleCloseNewModal = () => {
    setIsNewModalOpen(false)
    setNewWarehouse({
      company_id: "",
      warehouse_code: "",
      warehouse_name: "",
      address: "",
      comment: "",
    })
  }

  const handleAddNewWarehouse = async () => {
    // 倉庫新規登録
    dispatch(addWarehouse(newWarehouse))
    setIsNewModalOpen(false)
  }

  const handleUpdateWarehouse = async () => {
    // 倉庫新規更新
    dispatch(updateWarehouse(selectedWarehouse))
    setIsDetailsModalOpen(false)
  }

  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false)
  // 新規商品追加モーダルを開く・閉じる関数
  const toggleAddItemModal = () => setIsAddItemModalOpen(!isAddItemModalOpen)
  // 新規商品追加モーダルの状態
  const [newItem, setNewItem] = useState({ itemId: "", name: "", quantity: 0 })
  // 新規商品追加処理
  const handleAddNewItem = () => {
    // 新規商品追加のロジックをここに実装
    toggleAddItemModal()
  }

  const [isProcurementModalOpen, setIsProcurementModalOpen] = useState(false)
  // 仕入れモーダルを開く・閉じる関数
  const toggleProcurementModal = () =>
    setIsProcurementModalOpen(!isProcurementModalOpen)
  const [selectedItemForProcurement, setSelectedItemForProcurement] =
    useState(null)
  // 仕入れ情報保存処理
  const handleSaveProcurement = () => {
    // 仕入れ情報保存のロジックをここに実装
    toggleProcurementModal()
  }

  // 修正モーダルの開閉と選択した倉庫の設定
  const handleOpenEditModal = warehouse => {
    setNewWarehouse({
      company_id: warehouse.company_id,
      warehouse_code: warehouse.warehouse_code,
      warehouse_name: warehouse.warehouse_name,
      address: warehouse.address,
      comment: warehouse.comment,
    })
    setSelectedWarehouse(warehouse)
    setIsDetailsModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setIsDetailsModalOpen(false)
    setNewWarehouse({
      company_id: "",
      warehouse_code: "",
      warehouse_name: "",
      address: "",
      comment: "",
    })
  }

  const [searchQuery, setSearchQuery] = useState("")
  const [sortField, setSortField] = useState("")
  const [sortOrder, setSortOrder] = useState("asc")

  // フィルタリングとソート
  const filteredWarehouses = warehouses
    .filter(warehouse => {
      return (
        (warehouse.warehouse_name || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        (warehouse.warehouse_code || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        (warehouse.address || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    })
    .sort((a, b) => {
      if (sortField) {
        const fieldA = (a[sortField] || "").toLowerCase()
        const fieldB = (b[sortField] || "").toLowerCase()
        if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1
        if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1
      }
      return 0
    })

  // ソートフィールドと方向の設定
  const handleSort = field => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    } else {
      setSortField(field)
      setSortOrder("asc")
    }
  }

  // 削除確認とアクションディスパッチ
  const handleDeleteWarehouse = warehouseId => {
    if (window.confirm("本当にこの倉庫を削除しますか？")) {
      dispatch(deleteWarehouse(warehouseId))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="倉庫管理"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>倉庫リスト</CardTitle>
                  <Button
                    color="primary"
                    className="mb-3"
                    onClick={handleOpenNewModal}
                  >
                    新規登録
                  </Button>

                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ flexGrow: 1 }}>
                      <input
                        type="text"
                        placeholder="倉庫を検索..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("warehouse_code")}>
                            倉庫コード
                          </th>
                          <th onClick={() => handleSort("warehouse_name")}>
                            倉庫名
                          </th>
                          <th onClick={() => handleSort("address")}>住所</th>
                          <th>コメント</th>
                          <th>会社</th>
                          <th>アクション</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredWarehouses.map(warehouse => (
                          <tr
                            key={warehouse.warehouse_id}
                            onClick={() => handleOpenStockModal(warehouse)}
                          >
                            <td>{warehouse.warehouse_code}</td>
                            <td>{warehouse.warehouse_name}</td>
                            <td>{warehouse.address}</td>
                            <td>{warehouse.comment}</td>
                            <td>{warehouse.company_name}</td>
                            <td onClick={e => e.stopPropagation()}>
                              <Button
                                color="info"
                                onClick={() =>
                                  handleOpenDetailsModal(warehouse)
                                }
                              >
                                修正
                              </Button>{" "}
                              <Button
                                color="danger"
                                onClick={() =>
                                  handleDeleteWarehouse(warehouse.warehouse_id)
                                }
                              >
                                削除
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 新規登録モーダル */}
          <Modal isOpen={isNewModalOpen} toggle={handleCloseNewModal}>
            <ErrorMessage />
            <ModalHeader toggle={handleCloseNewModal}>新規倉庫登録</ModalHeader>
            <ModalBody>
              {/* 会社リストの表示 */}
              <FormGroup>
                <Label for="companySelect">会社選択</Label>
                <Input
                  type="select"
                  id="companySelect"
                  value={newWarehouse.company_id || ""} // company_idが未定義の場合には空文字を設定
                  onChange={e =>
                    setNewWarehouse({
                      ...newWarehouse,
                      company_id: e.target.value,
                    })
                  }
                >
                  <option value="">選択してください</option>
                  {companies.length > 0 ? (
                    companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))
                  ) : (
                    <option value="">利用可能な会社がありません</option>
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="warehouseCode">倉庫コード</Label>
                <Input
                  id="warehouseCode"
                  value={newWarehouse.warehouse_code}
                  onChange={e =>
                    setNewWarehouse({
                      ...newWarehouse,
                      warehouse_code: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="warehouseCode">倉庫名</Label>
                <Input
                  id="warehouseCode"
                  value={newWarehouse.warehouse_name}
                  onChange={e =>
                    setNewWarehouse({
                      ...newWarehouse,
                      warehouse_name: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">住所</Label>
                <Input
                  id="address"
                  value={newWarehouse.address}
                  onChange={e =>
                    setNewWarehouse({
                      ...newWarehouse,
                      address: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="comment">コメント</Label>
                <Input
                  id="comment"
                  value={newWarehouse.comment}
                  onChange={e =>
                    setNewWarehouse({
                      ...newWarehouse,
                      comment: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseNewModal}>
                キャンセル
              </Button>
              <Button color="primary" onClick={handleAddNewWarehouse}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
          {/* 新規商品追加モーダル */}
          <Modal isOpen={isAddItemModalOpen} toggle={toggleAddItemModal}>
            <ModalHeader toggle={toggleAddItemModal}>新規商品追加</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="itemId">アイテムID</Label>
                <Input
                  id="itemId"
                  value={newItem.itemId}
                  onChange={e =>
                    setNewItem({ ...newItem, itemId: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">名前</Label>
                <Input
                  id="name"
                  value={newItem.name}
                  onChange={e =>
                    setNewItem({ ...newItem, name: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">数量</Label>
                <Input
                  id="quantity"
                  type="number"
                  value={newItem.quantity}
                  onChange={e =>
                    setNewItem({
                      ...newItem,
                      quantity: parseInt(e.target.value, 10),
                    })
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleAddItemModal}>
                キャンセル
              </Button>
              <Button color="primary" onClick={handleAddNewItem}>
                追加
              </Button>
            </ModalFooter>
          </Modal>
          {/* 修正モーダル */}
          <Modal isOpen={isDetailsModalOpen} toggle={handleCloseEditModal}>
            <ModalHeader toggle={handleCloseEditModal}>
              倉庫情報修正
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="companySelect">会社選択</Label>
                <Input
                  type="select"
                  id="companySelect"
                  value={selectedWarehouse.company_id || ""}
                  onChange={e =>
                    setSelectedWarehouse({
                      ...selectedWarehouse,
                      company_id: e.target.value,
                    })
                  }
                >
                  <option value="">選択してください</option>
                  {companies.map(company => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="warehouseCode">倉庫コード</Label>
                <Input
                  id="warehouseCode"
                  value={selectedWarehouse.warehouse_code}
                  onChange={e =>
                    setSelectedWarehouse({
                      ...selectedWarehouse,
                      warehouse_code: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="warehouseName">倉庫名</Label>
                <Input
                  id="warehouseName"
                  value={selectedWarehouse.warehouse_name}
                  onChange={e =>
                    setSelectedWarehouse({
                      ...selectedWarehouse,
                      warehouse_name: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">住所</Label>
                <Input
                  id="address"
                  value={selectedWarehouse.address}
                  onChange={e =>
                    setSelectedWarehouse({
                      ...selectedWarehouse,
                      address: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="comment">コメント</Label>
                <Input
                  id="comment"
                  value={selectedWarehouse.comment}
                  onChange={e =>
                    setSelectedWarehouse({
                      ...selectedWarehouse,
                      comment: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseEditModal}>
                キャンセル
              </Button>
              <Button color="primary" onClick={handleUpdateWarehouse}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
          {/* 仕入れ情報モーダル */}
          <Modal
            isOpen={isProcurementModalOpen}
            toggle={toggleProcurementModal}
          >
            <ModalHeader toggle={toggleProcurementModal}>
              仕入れ情報
            </ModalHeader>
            <ModalBody>
              {/* 選択された商品の情報を表示 */}
              {selectedItemForProcurement && (
                <>
                  <p>商品名: {selectedItemForProcurement.name}</p>
                  <p>現在の数量: {selectedItemForProcurement.quantity}</p>
                  {/* 仕入れ先の選択 */}
                  <FormGroup>
                    <Label for="supplierSelect">仕入れ先</Label>
                    <Input
                      type="select"
                      id="supplierSelect"
                      value={procurementInfo.supplier}
                      onChange={handleSupplierChange}
                    >
                      {testSuppliers.map(supplier => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {/* 選択された仕入れ先のリードタイムと連絡先を表示 */}
                  <p>リードタイム: {procurementInfo.leadTime} 日</p>
                  <p>連絡先: {procurementInfo.contact}</p>
                  {/* 仕入れ数量の入力 */}
                  <FormGroup>
                    <Label for="purchaseQuantity">仕入れ数量</Label>
                    <Input
                      type="number"
                      id="purchaseQuantity"
                      value={procurementInfo.quantity}
                      onChange={e =>
                        setProcurementInfo({
                          ...procurementInfo,
                          quantity: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleProcurementModal}>
                キャンセル
              </Button>
              <Button color="primary" onClick={handleSaveProcurement}>
                実行
              </Button>
            </ModalFooter>
          </Modal>
          {/* 在庫モーダル */}
          <Modal isOpen={isStockModalOpen} toggle={handleCloseStockModal}>
            <ModalHeader toggle={handleCloseStockModal}>
              倉庫在庫情報
            </ModalHeader>
            <ModalBody>
              <ul>
                {warehouseStocks.map(stock => (
                  <li key={stock.warehouse_stock_id}>
                    {stock.goods_name} - 数量: {stock.stock_count}
                  </li>
                ))}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseStockModal}>
                閉じる
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Warehouse
