// sagas.js
import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_WAREHOUSES,
  ADD_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  FETCH_WAREHOUSE_STOCK,
  ADD_WAREHOUSE_STOCK,
  UPDATE_WAREHOUSE_STOCK,
  DELETE_WAREHOUSE_STOCK,
} from "./actionTypes"
import {
  fetchWarehousesSuccess,
  fetchWarehousesError,
  addWarehouseSuccess,
  addWarehouseError,
  updateWarehouseSuccess,
  updateWarehouseError,
  deleteWarehouseSuccess,
  deleteWarehouseError,
  fetchWarehouseStockSuccess,
  fetchWarehouseStockError,
  addWarehouseStockSuccess,
  addWarehouseStockError,
  updateWarehouseStockSuccess,
  updateWarehouseStockError,
  deleteWarehouseStockSuccess,
  deleteWarehouseStockError,
} from "./actions"

// API関数は適宜定義または既存のヘルパー関数を利用してください
import {
  getWarehouses,
  postWarehouse,
  putWarehouse,
  deleteWarehouse,
  getWarehouseStock,
  postWarehouseStock,
  putWarehouseStock,
  delWarehouseStock,
} from "../../helpers/backend_helper"

function* fetchWarehouses() {
  try {
    const warehouses = yield call(getWarehouses)
    yield put(fetchWarehousesSuccess(warehouses))
  } catch (error) {
    yield put(fetchWarehousesError(error))
  }
}

function* addWarehouse(action) {
  try {
    const newWarehouse = yield call(postWarehouse, action.payload)
    yield put(addWarehouseSuccess(newWarehouse))
  } catch (error) {
    yield put(addWarehouseError(error))
  }
}

function* updateWarehouse(action) {
  try {
    const updatedWarehouse = yield call(putWarehouse, action.payload)
    yield put(updateWarehouseSuccess(updatedWarehouse))
  } catch (error) {
    yield put(updateWarehouseError(error))
  }
}

function* delWarehouse(action) {
  try {
    const response = yield call(deleteWarehouse, action.payload) // Implement this API call
    yield put(deleteWarehouseSuccess(response))
  } catch (error) {
    yield put(deleteWarehouseError(error))
  }
}

// 倉庫在庫の取得
function* fetchWarehouseStock({ payload }) {
  try {
    const response = yield call(getWarehouseStock, payload)
    yield put(fetchWarehouseStockSuccess(response))
  } catch (error) {
    yield put(fetchWarehouseStockError(error))
  }
}

// 倉庫在庫の追加
function* addWarehouseStock({ payload }) {
  try {
    const response = yield call(postWarehouseStock, payload.warehouseId)
    yield put(addWarehouseStockSuccess(response))
  } catch (error) {
    yield put(addWarehouseStockError(error))
  }
}

// 倉庫在庫の更新
function* updateWarehouseStock({ payload }) {
  try {
    const response = yield call(putWarehouseStock, payload.warehouseId)
    yield put(updateWarehouseStockSuccess(response))
  } catch (error) {
    yield put(updateWarehouseStockError(error))
  }
}

// 倉庫在庫の削除
function* deleteWarehouseStockSaga({ payload }) {
  try {
    const response = yield call(
      delWarehouseStock,
      payload.warehouseStockId,
      payload.warehouseId
    )
    yield put(deleteWarehouseStockSuccess(response))
  } catch (error) {
    yield put(deleteWarehouseStockError(error))
  }
}

export function* warehouseSaga() {
  yield takeEvery(FETCH_WAREHOUSES, fetchWarehouses)
  yield takeEvery(ADD_WAREHOUSE, addWarehouse)
  yield takeEvery(UPDATE_WAREHOUSE, updateWarehouse)
  yield takeEvery(DELETE_WAREHOUSE, delWarehouse)
  yield takeEvery(FETCH_WAREHOUSE_STOCK, fetchWarehouseStock) // 追加
  yield takeEvery(ADD_WAREHOUSE_STOCK, addWarehouseStock) // 追加
  yield takeEvery(UPDATE_WAREHOUSE_STOCK, updateWarehouseStock) // 追加
  yield takeEvery(DELETE_WAREHOUSE_STOCK, deleteWarehouseStockSaga) // 追加
}

export default warehouseSaga
