export const FETCH_VEHICLES = "FETCH_VEHICLES"
export const FETCH_VEHICLES_SUCCESS = "FETCH_VEHICLES_SUCCESS"
export const FETCH_VEHICLES_ERROR = "FETCH_VEHICLES_ERROR"
export const ADD_VEHICLE = "ADD_VEHICLE"
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS"
export const ADD_VEHICLE_ERROR = "ADD_VEHICLE_ERROR"
export const UPDATE_VEHICLE = "UPDATE_VEHICLE"
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS"
export const UPDATE_VEHICLE_ERROR = "UPDATE_VEHICLE_ERROR"
export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_ERROR = "DELETE_VEHICLE_ERROR"

// 配送在庫のアクションタイプを追加
export const FETCH_VEHICLE_STOCK = "FETCH_VEHICLE_STOCK"
export const FETCH_VEHICLE_STOCK_SUCCESS = "FETCH_VEHICLE_STOCK_SUCCESS"
export const FETCH_VEHICLE_STOCK_ERROR = "FETCH_VEHICLE_STOCK_ERROR"
export const ADD_VEHICLE_STOCK = "ADD_VEHICLE_STOCK"
export const ADD_VEHICLE_STOCK_SUCCESS = "ADD_VEHICLE_STOCK_SUCCESS"
export const ADD_VEHICLE_STOCK_ERROR = "ADD_VEHICLE_STOCK_ERROR"
export const UPDATE_VEHICLE_STOCK = "UPDATE_VEHICLE_STOCK"
export const UPDATE_VEHICLE_STOCK_SUCCESS = "UPDATE_VEHICLE_STOCK_SUCCESS"
export const UPDATE_VEHICLE_STOCK_ERROR = "UPDATE_VEHICLE_STOCK_ERROR"
export const DELETE_VEHICLE_STOCK = "DELETE_VEHICLE_STOCK"
export const DELETE_VEHICLE_STOCK_SUCCESS = "DELETE_VEHICLE_STOCK_SUCCESS"
export const DELETE_VEHICLE_STOCK_ERROR = "DELETE_VEHICLE_STOCK_ERROR"
