export const FETCH_STOCKS_GRAPH_DATA = "FETCH_STOCKS_GRAPH_DATA"
export const FETCH_STOCKS_GRAPH_DATA_SUCCESS = "FETCH_STOCKS_GRAPH_DATA_SUCCESS"
export const FETCH_STOCKS_GRAPH_DATA_ERROR = "FETCH_STOCKS_GRAPH_DATA_ERROR"

export const FETCH_IPCS_DATA = "FETCH_IPCS_DATA"
export const FETCH_IPCS_DATA_SUCCESS = "FETCH_IPCS_DATA_SUCCESS"
export const FETCH_IPCS_DATA_ERROR = "FETCH_IPCS_DATA_ERROR"

export const UPDATE_STOCK_ITEM = "UPDATE_STOCK_ITEM"
export const UPDATE_STOCK_ITEM_SUCCESS = "UPDATE_STOCK_ITEM_SUCCESS"
export const UPDATE_STOCK_ITEM_ERROR = "UPDATE_STOCK_ITEM_ERROR"
