export const FETCH_GOODS = "FETCH_GOODS"
export const FETCH_GOODS_SUCCESS = "FETCH_GOODS_SUCCESS"
export const FETCH_GOODS_ERROR = "FETCH_GOODS_ERROR"
export const ADD_GOOD = "ADD_GOOD"
export const ADD_GOOD_SUCCESS = "ADD_GOOD_SUCCESS"
export const ADD_GOOD_ERROR = "ADD_GOOD_ERROR"
export const UPDATE_GOOD = "UPDATE_GOOD"
export const UPDATE_GOOD_SUCCESS = "UPDATE_GOOD_SUCCESS"
export const UPDATE_GOOD_ERROR = "UPDATE_GOOD_ERROR"
export const DELETE_GOOD = "DELETE_GOOD"
export const DELETE_GOOD_SUCCESS = "DELETE_GOOD_SUCCESS"
export const DELETE_GOOD_ERROR = "DELETE_GOOD_ERROR"
export const REGISTER_SURPRISE = "REGISTER_SURPRISE"
export const REGISTER_SURPRISE_SUCCESS = "REGISTER_SURPRISE_SUCCESS"
export const REGISTER_SURPRISE_ERROR = "REGISTER_SURPRISE_ERROR"
export const UNREGISTER_SURPRISE = "UNREGISTER_SURPRISE"
export const UNREGISTER_SURPRISE_SUCCESS = "UNREGISTER_SURPRISE_SUCCESS"
export const UNREGISTER_SURPRISE_ERROR = "UNREGISTER_SURPRISE_ERROR"
export const FETCH_GOODS_DETAIL = "FETCH_GOODS_DETAIL"
export const FETCH_GOODS_DETAIL_SUCCESS = "FETCH_GOODS_DETAIL_SUCCESS"
export const FETCH_GOODS_DETAIL_ERROR = "FETCH_GOODS_DETAIL_ERROR"
