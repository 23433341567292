import { get } from "../api_helper"
import * as url from "../url_helper"

// Get Announcements Data Method
const getAnnouncementData = async () => {
  try {
    const response = await get(url.GET_ANNOUNCEMENT) // Make sure to replace with your backend API endpoint
    return { data: response }
  } catch (error) {
    throw error
  }
}

export { getAnnouncementData }
