import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchCamerasData, updateCameras } from "../../store/cameras/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Cameras = () => {
  const [selectedVendingMachine, setSelectedVendingMachine] = useState(null)
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [note, setNote] = useState("")
  const [isDeleteChecked, setIsDeleteChecked] = useState(false)

  const [tempStartDate, setTempStartDate] = useState(null)
  const [tempEndDate, setTempEndDate] = useState(null)

  // 新規項目のステート
  const [deviceNo, setDeviceNo] = useState("")
  const [hostname, setHostname] = useState("")
  const [ipcSerialNo, setIpcSerialNo] = useState("")
  const [simNo, setSimNo] = useState("")
  const [iccId, setIccId] = useState("")
  const [allxonLicNo, setAllxonLicNo] = useState("")
  const [ipcOwner, setIpcOwner] = useState("")
  const [cameraOwner, setCameraOwner] = useState("")
  const [cameraOperator, setCameraOperator] = useState("")
  const [ipAddr, setIpAddr] = useState("")

  const [tempLoc, setTempLoc] = useState(null)
  const [tempNote, setTempNote] = useState("")
  const [statusFilter, setStatusFilter] = useState("稼働中及び未割当")

  const dispatch = useDispatch()

  const camerasData = useSelector(state => state.camerasReducer.camerasData)
  const isLoading = useSelector(state => state.camerasReducer.loading)

  const cameras =
    camerasData && camerasData.data ? camerasData.data.results : []
  const locList =
    camerasData && camerasData.data ? camerasData.data.results2 : []

  const [sortField, setSortField] = useState("receive_end")
  const [sortDirection, setSortDirection] = useState("desc")

  const [sortedCameras, setSortedCameras] = useState([])

  const [isFormValid, setIsFormValid] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    dispatch(fetchCamerasData())
  }, [dispatch])

  useEffect(() => {
    setSortedCameras(cameras)
  }, [cameras])

  useEffect(() => {
    if (tempStartDate && tempEndDate && tempLoc && tempLoc.value) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [tempStartDate, tempEndDate, tempLoc])

  const handleVendingMachineClick = vendingMachine => {
    let locLabel = null

    if (
      vendingMachine.loc_code &&
      vendingMachine.loc_name &&
      vendingMachine.loc_code !== "null" &&
      vendingMachine.loc_name !== "null"
    ) {
      locLabel = `${vendingMachine.loc_code} - ${vendingMachine.loc_name}`
    }

    setSelectedVendingMachine(vendingMachine)
    setTempStartDate(
      vendingMachine.start_at ? new Date(vendingMachine.start_at) : null
    )
    setTempEndDate(
      vendingMachine.end_at ? new Date(vendingMachine.end_at) : null
    )
    setTempLoc({ label: locLabel, value: vendingMachine.loc_id })
    setTempNote(vendingMachine.note || "")
    setDeviceNo(vendingMachine.device_no || "")
    setHostname(vendingMachine.hostname || "")
    setIpcSerialNo(vendingMachine.ipc_serial_no || "")
    setSimNo(vendingMachine.sim_no || "")
    setIccId(vendingMachine.icc_id || "")
    setAllxonLicNo(vendingMachine.allxon_lic_no || "")
    setIpcOwner(vendingMachine.ipc_owner || "")
    setCameraOwner(vendingMachine.camera_owner || "")
    setCameraOperator(vendingMachine.camera_operator || "")
    setIpAddr(vendingMachine.ip_addr || "")
    setModal(true)
  }

  const handleIpcChange = selectedOption => {
    setTempLoc(selectedOption)
  }

  const handleConfirmChanges = () => {
    const updatedData = {
      camera_id: selectedVendingMachine.camera_id,
      uuid: selectedVendingMachine.uuid,
      loc_id: tempLoc.value,
      start_at: tempStartDate,
      end_at: tempEndDate,
      note: tempNote,
      deleted: isDeleteChecked ? 1 : 0,
      device_no: deviceNo,
      hostname: hostname,
      ipc_serial_no: ipcSerialNo,
      sim_no: simNo,
      icc_id: iccId,
      allxon_lic_no: allxonLicNo,
      ipc_owner: ipcOwner,
      camera_owner: cameraOwner,
      camera_operator: cameraOperator,
      ip_addr: ipAddr,
    }

    dispatch(updateCameras(updatedData))
    toggle()
  }

  const toggle = () => setModal(!modal)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="Prime-Eye 管理"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>Prime-Eye 管理</CardTitle>
                  <LoadingOverlay isLoading={isLoading} />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flexGrow: 0.5, marginRight: "10px" }}>
                      <input
                        type="text"
                        placeholder="カメラを検索..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}>状態</th>
                        <th style={{ width: "20%" }}>UUID</th>
                        <th style={{ width: "10%" }}>受信開始</th>
                        <th style={{ width: "10%" }}>受信終了</th>
                        <th style={{ width: "20%" }}>ロケーション名</th>
                        <th style={{ width: "10%" }}>開始日時</th>
                        <th style={{ width: "10%" }}>終了日時</th>
                        <th style={{ width: "15%" }}>備考欄</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedCameras.map((machine, index) => (
                        <tr
                          key={`${machine.uuid}-${index}`}
                          onClick={() => handleVendingMachineClick(machine)}
                        >
                          <td>{machine.status}</td>
                          <td>{machine.uuid}</td>
                          <td>{machine.receive_start}</td>
                          <td>{machine.receive_end}</td>
                          <td>{machine.loc_name}</td>
                          <td>{machine.start_at}</td>
                          <td>{machine.end_at}</td>
                          <td>{machine.note}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Modal isOpen={modal} toggle={toggle}>
                    <div className="modal-body">
                      <FormGroup row>
                        <Col>
                          <Label for="startDate" style={{ fontSize: "0.8rem" }}>
                            開始日
                          </Label>
                          <DatePicker
                            id="startDate"
                            selected={tempStartDate}
                            onChange={setTempStartDate}
                            showTimeSelect
                            dateFormat="yyyy/MM/dd HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>
                        <Col>
                          <Label for="endDate" style={{ fontSize: "0.8rem" }}>
                            終了日
                          </Label>
                          <DatePicker
                            id="endDate"
                            selected={tempEndDate}
                            onChange={setTempEndDate}
                            showTimeSelect
                            dateFormat="yyyy/MM/dd HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>
                      </FormGroup>

                      {/* カメラ機器番号と自販機機番 */}
                      <FormGroup row>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>
                            カメラ機器番号
                          </Label>
                          <Input
                            type="text"
                            value={deviceNo}
                            onChange={e => setDeviceNo(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>
                            自販機機番
                          </Label>
                          <Input
                            type="text"
                            value={ipcSerialNo}
                            onChange={e => setIpcSerialNo(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      {/* SIM番号とICCID */}
                      <FormGroup row>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>SIM番号</Label>
                          <Input
                            type="text"
                            value={simNo}
                            onChange={e => setSimNo(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>ICCID</Label>
                          <Input
                            type="text"
                            value={iccId}
                            onChange={e => setIccId(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      {/* 自販機所有者とカメラ所有者 */}
                      <FormGroup row>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>
                            自販機所有者
                          </Label>
                          <Input
                            type="text"
                            value={ipcOwner}
                            onChange={e => setIpcOwner(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Label style={{ fontSize: "0.8rem" }}>
                            カメラ所有者
                          </Label>
                          <Input
                            type="text"
                            value={cameraOwner}
                            onChange={e => setCameraOwner(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      {/* その他の項目 */}
                      <FormGroup>
                        <Label style={{ fontSize: "0.8rem" }}>
                          管理(不具合対応)
                        </Label>
                        <Input
                          type="text"
                          value={cameraOperator}
                          onChange={e => setCameraOperator(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label style={{ fontSize: "0.8rem" }}>IPアドレス</Label>
                        <Input
                          type="text"
                          value={ipAddr}
                          onChange={e => setIpAddr(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label style={{ fontSize: "0.8rem" }}>備考</Label>
                        <textarea
                          className="form-control"
                          rows="4"
                          value={tempNote}
                          onChange={e => setTempNote(e.target.value)}
                          placeholder="備考を入力してください..."
                        ></textarea>
                      </FormGroup>
                      <FormGroup>
                        <Label check style={{ fontSize: "0.8rem" }}>
                          <Input
                            type="checkbox"
                            checked={isDeleteChecked}
                            onChange={e => setIsDeleteChecked(e.target.checked)}
                          />
                          削除する
                        </Label>
                      </FormGroup>
                    </div>
                    <div
                      className="modal-footer"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        type="button"
                        onClick={toggle}
                        color="secondary"
                        style={{ width: "45%" }}
                      >
                        キャンセル
                      </Button>
                      <Button
                        type="button"
                        onClick={handleConfirmChanges}
                        color="primary"
                        style={{ width: "45%" }}
                      >
                        更新
                      </Button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Cameras
