// backend_helper.js

import { put, post, del } from "../api_helper";
import * as url from "../url_helper";

const updateLocationApi = async (locationId, locationData) => {
  let endpoint;
  let response;

  endpoint = `${url.PUT_LOCATION}/${locationId}`;  // 更新用のエンドポイント
  response = await put(endpoint, locationData);  // PUTリクエストで更新処理を行う

  return { data: response }
};

const registerLocationApi = async (ipcNo, locationData) => {
  let endpoint;
  let response;

  endpoint = url.POST_LOCATION;  // 登録用のエンドポイント
  response = await post(endpoint, { ipcNo, ...locationData });

  return { data: response }
};

const deleteLocationApi = async (locationId, locationData) => {
  let endpoint;
  let response;

  endpoint = `${url.PUT_LOCATION}/${locationId}`;  // 更新用のエンドポイント
  response = await del(endpoint);  // PUTリクエストで更新処理を行う

  return { data: response }
};

export { updateLocationApi, registerLocationApi, deleteLocationApi };
