export const FETCH_USERS_DATA = "FETCH_USERS_DATA"
export const FETCH_USERS_DATA_SUCCESS = "FETCH_USERS_DATA_SUCCESS"
export const FETCH_USERS_DATA_ERROR = "FETCH_USERS_DATA_ERROR"
export const FETCH_USERS_IPC_DATA = "FETCH_USERS_IPC_DATA"
export const FETCH_USERS_IPC_DATA_SUCCESS = "FETCH_USERS_IPC_DATA_SUCCESS"
export const FETCH_USERS_IPC_DATA_ERROR = "FETCH_USERS_IPC_DATA_ERROR"
export const ADD_IPC_TO_USER = "ADD_IPC_TO_USER"
export const ADD_IPC_TO_USER_SUCCESS = "ADD_IPC_TO_USER_SUCCESS"
export const ADD_IPC_TO_USER_ERROR = "ADD_IPC_TO_USER_ERROR"
export const REMOVE_IPC_FROM_USER = "REMOVE_IPC_FROM_USER"
export const REMOVE_IPC_FROM_USER_SUCCESS = "REMOVE_IPC_FROM_USER_SUCCESS"
export const REMOVE_IPC_FROM_USER_ERROR = "REMOVE_IPC_FROM_USER_ERROR"
export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL"
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS"
export const FETCH_USER_DETAIL_FAILURE = "FETCH_USER_DETAIL_FAILURE"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"
