// reducers.js

import {
  FETCH_CAMERAS_DATA,
  FETCH_CAMERAS_DATA_SUCCESS,
  FETCH_CAMERAS_DATA_ERROR,
  UPDATE_CAMERAS,
  UPDATE_CAMERAS_SUCCESS,
  UPDATE_CAMERAS_ERROR,
} from "./actionTypes"

const initialState = {
  camerasData: [],
  loading: false,
  error: null,
}

const camerasReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMERAS_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CAMERAS_DATA_SUCCESS:
      return {
        ...state,
        camerasData: action.payload,
        loading: false,
      }
    case FETCH_CAMERAS_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_CAMERAS:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_CAMERAS_SUCCESS:
      return {
        ...state,
        camerasData: action.payload,
        loading: false,
      }
    case UPDATE_CAMERAS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default camerasReducer
