import { post } from "../api_helper"
import * as url from "../url_helper"

import Cookie from "js-cookie"

// Login Method
const postJwtLogin = async data => {
  try {
    const response = await post(url.POST_LOGIN, {
      username: data.username,
      password: data.password,
    })

    if (response && response.access_token && response.refresh_token) {
      // refreshTokenをクッキーに保存
      Cookie.set("refreshToken", response.refresh_token, { expires: 7 }) // 有効期限を7日に設定

      return {
        token: response.access_token,
        user: {
          level: response.level,
          username: response.username,
          email: response.email,
          company: response.company_level,
          company_id: response.company_id,
        },
      }
    } else {
      throw new Error("Authentication failed")
    }
  } catch (error) {
    throw error
  }
}

export { postJwtLogin }
