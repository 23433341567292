// reducers.js

import {
  FETCH_MARKETING_TIME_GRAPH_DATA,
  FETCH_MARKETING_TIME_GRAPH_DATA_SUCCESS,
  FETCH_MARKETING_TIME_GRAPH_DATA_ERROR,
} from "./actionTypes"

const initialState = {
  graphData: [],
  loading: false,
  error: null,
}

const marketingTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARKETING_TIME_GRAPH_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MARKETING_TIME_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
        loading: false,
      }
    case FETCH_MARKETING_TIME_GRAPH_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default marketingTimeReducer
