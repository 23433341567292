import { get } from "../api_helper"
import * as url from "../url_helper"

// Get Sales All Data Method
const getSalesAllData = async () => {
  try {
    const queryParams = new URLSearchParams()
    queryParams.append("is_loc", 0)

    const response = await get(`${url.GET_SALES_ALL}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

export { getSalesAllData }
