import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap"
import {
  fetchGoodsDetail,
  registerSurprise,
  unregisterSurprise,
  fetchGoods,
} from "../../../store/goods/actions"
import ErrorMessage from "../../Common/ErrorMessage"
import LoadingOverlay from "../../Common/LoadingOverlay"

const SurpriseModal = ({ isOpen, toggle, item, onRegisterSurprise }) => {
  const dispatch = useDispatch()

  const [selectedUnassignedItems, setSelectedUnassignedItems] = useState([])
  const [selectedAssignedItems, setSelectedAssignedItems] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  const goodsDetail = useSelector(state => state.GoodsReducer.goodsDetail) || {
    result1: [],
    result2: [],
  }
  const isLoading = useSelector(state => state.GoodsReducer.loading)
  const error = useSelector(state => state.GoodsReducer.error)

  const result1 = goodsDetail.result1 || []
  const result2 = goodsDetail.result2 || []

  useEffect(() => {
    if (item && item.goods_id) {
      dispatch(fetchGoodsDetail(item.goods_id))
    }
  }, [dispatch, item])

  useEffect(() => {
    dispatch(fetchGoods())
  }, [goodsDetail])

  const searchTerms = searchTerm.split(/[\s\u3000]+/)

  const filteredPotentialItems = result1.filter(item =>
    searchTerms.every(
      term =>
        item.goods_name.toLowerCase().includes(term.toLowerCase()) ||
        item.goods_no.toLowerCase().includes(term.toLowerCase())
    )
  )

  const registeredItems = result2

  const handleUnassignedItemClick = (goods, event) => {
    if (event.ctrlKey || event.metaKey) {
      if (selectedUnassignedItems.includes(goods)) {
        setSelectedUnassignedItems(
          selectedUnassignedItems.filter(item => item !== goods)
        )
      } else {
        setSelectedUnassignedItems([...selectedUnassignedItems, goods])
      }
    } else {
      setSelectedUnassignedItems([goods])
    }
  }

  const handleAssignedItemClick = (goods, event) => {
    if (event.ctrlKey || event.metaKey) {
      if (selectedAssignedItems.includes(goods)) {
        setSelectedAssignedItems(
          selectedAssignedItems.filter(item => item !== goods)
        )
      } else {
        setSelectedAssignedItems([...selectedAssignedItems, goods])
      }
    } else {
      setSelectedAssignedItems([goods])
    }
  }

  const handleRegister = () => {
    if (selectedUnassignedItems.length > 0 && item && item.goods_id) {
      selectedUnassignedItems.forEach(unassignedItem => {
        dispatch(registerSurprise(item.goods_id, unassignedItem.goods_id))
      })
      setSelectedUnassignedItems([])
    }
  }

  const handleUnregister = () => {
    if (selectedAssignedItems.length > 0 && item && item.goods_id) {
      selectedAssignedItems.forEach(assignedItem => {
        dispatch(unregisterSurprise(item.goods_id, assignedItem.goods_id))
      })
      setSelectedAssignedItems([])
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ErrorMessage />
      <LoadingOverlay isLoading={isLoading} />
      <ModalHeader toggle={toggle}>
        ガチャ商品登録
        <div style={{ marginTop: "0.5em" }}>
          {item && item.goods_name
            ? ` ${item.goods_no} : ${item.goods_name}`
            : ""}
        </div>
      </ModalHeader>
      <ModalBody style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <h5>商品一覧</h5>
          <div>
            <Input
              type="text"
              placeholder="商品番号または商品名で検索..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <ul style={{ padding: 0, listStyleType: "none" }}>
              {filteredPotentialItems.map(goods => (
                <li
                  key={goods.goods_id}
                  onClick={e => handleUnassignedItemClick(goods, e)}
                  style={{
                    backgroundColor: selectedUnassignedItems.includes(goods)
                      ? "lightgray"
                      : "transparent",
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {goods.goods_no} : {goods.goods_name || "商品名無し"}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
          }}
        >
          <button
            onClick={handleRegister}
            disabled={selectedUnassignedItems.length === 0}
            style={{ marginBottom: "10px", width: "50px" }}
          >
            &gt;
          </button>
          <button
            onClick={handleUnregister}
            disabled={selectedAssignedItems.length === 0}
            style={{ marginBottom: "10px", width: "50px" }}
          >
            &lt;
          </button>
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h5>登録された商品</h5>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <ul style={{ padding: 0, listStyleType: "none" }}>
              {registeredItems.map(goods => (
                <li
                  key={goods.goods_id}
                  onClick={e => handleAssignedItemClick(goods, e)}
                  style={{
                    backgroundColor: selectedAssignedItems.includes(goods)
                      ? "lightgray"
                      : "transparent",
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <strong>{goods.goods_no}</strong> ：{" "}
                  {goods.goods_name || "商品名無し"}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SurpriseModal
