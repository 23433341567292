import React, { useState, useEffect, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchLocationGroups,
  updateLocationGroup,
} from "../../store/locationGroup/actions"
import $ from "jquery"
import "select2/dist/css/select2.min.css"
import "select2"

const LocationsList = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.locationGroupsReducer.loading)
  const locationGroupsData =
    useSelector(state => state.locationGroupsReducer.locationGroups) || []
  const allGroups =
    useSelector(state => state.locationGroupsReducer.allGroups) || []

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  })

  // ソートと検索を組み合わせたロケーションデータの計算
  const sortedAndFilteredLocations = useMemo(() => {
    return locationGroupsData
      .filter(loc => {
        // ステータスに対する検索処理
        const statusMatch =
          (searchTerm.toLowerCase() === "稼働" && loc.val_flg) ||
          (searchTerm.toLowerCase() === "非稼働" && !loc.val_flg)
        // ロケーションコードとロケーション名に対する検索処理
        const textMatch =
          loc.loc_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
          loc.loc_name.toLowerCase().includes(searchTerm.toLowerCase())
        // グループ名に対する検索処理
        const groupMatch = loc.groups.some(group =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        return statusMatch || textMatch || groupMatch
      })
      .sort((a, b) => {
        // ソートロジックは変更なし
        if (sortConfig.key === null) return 0
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
  }, [locationGroupsData, searchTerm, sortConfig])

  // ソート要求関数
  const requestSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    } else {
      direction = "ascending"
    }
    setSortConfig({ key, direction })
  }

  // 検索バーのハンドラ
  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    dispatch(fetchLocationGroups())
  }, [dispatch])

  useEffect(() => {
    // locationGroupsData がロケーションごとのグループ情報を含むと仮定
    locationGroupsData.forEach(location => {
      // 各ロケーションに紐づく select 要素を特定
      const locationSelect = $(`select[data-location-id="${location.loc_id}"]`)

      // select2の初期化
      locationSelect.select2({
        tags: true,
        data: allGroups.map(group => ({ id: group.id, text: group.name })),
        maximumSelectionLength: 10,
        tokenSeparators: [",", " "],
        placeholder: "グループを選択してください",
      })

      // サーバーから受け取ったそのロケーションに紐づくグループのIDを初期選択状態として設定
      const selectedGroupIds = location.groups.map(group => group.id.toString())
      locationSelect.val(selectedGroupIds).trigger("change")
    })
  }, [locationGroupsData, allGroups])

  useEffect(() => {
    const selectElements = $(".select2").select2({
      tags: true,
      data: allGroups.map(group => ({ id: group.id, text: group.name })),
      maximumSelectionLength: 10,
      tokenSeparators: [",", " "],
      placeholder: "グループを選択してください",
    })

    selectElements.on("change", function (e) {
      // 選択された項目のID（値）を取得
      const selectedIds = $(this).val()
      const locationId = $(this).data("location-id")

      // 選択された項目の名前（テキスト）を格納する配列
      let selectedTexts = []

      // 選択された各IDに対応するテキストを取得
      if (selectedIds) {
        selectedIds.forEach(id => {
          // select2のdata属性から対応するテキストを検索
          const selectedOptionText = $(this)
            .find(`option[value="${id}"]`)
            .text()
          selectedTexts.push(selectedOptionText)
        })
      }

      // 選択された項目のIDと名前をコンソールに出力（デバッグ用）
      console.log("Selected IDs:", selectedIds)
      console.log("Selected Texts:", selectedTexts)
      console.log("変更されたロケーションの情報:", locationId)

      // ここで選択されたグループに基づいてアクションをディスパッチ
      dispatch(updateLocationGroup(locationId, selectedTexts))
    })

    // クリーンアップ関数
    return () => {
      selectElements.off("change") // 'change'イベントリスナーを削除
    }
  }, [allGroups])

  const filteredLocations =
    searchTerm.length > 0
      ? locationGroupsData.filter(
          loc =>
            loc.loc_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            loc.loc_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : locationGroupsData

  const getSortDirectionIndicator = columnName => {
    if (!sortConfig || sortConfig.key !== columnName) {
      return "" // ソートが適用されていない場合は何も表示しない
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="メイン"
            title="管理"
            breadcrumbItem="ロケーション一覧"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>ロケーション一覧</CardTitle>
                  <input
                    type="text"
                    placeholder="検索..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                  <table className="table">
                    <thead>
                      <tr>
                        <th onClick={() => requestSort("val_flg")}>
                          ステータス {getSortDirectionIndicator("val_flg")}
                        </th>
                        <th onClick={() => requestSort("loc_code")}>
                          ロケーションコード{" "}
                          {getSortDirectionIndicator("loc_code")}
                        </th>
                        <th onClick={() => requestSort("loc_name")}>
                          ロケーション名 {getSortDirectionIndicator("loc_name")}
                        </th>
                        <th onClick={() => requestSort("val_date")}>
                          稼働期間 {getSortDirectionIndicator("val_date")}
                        </th>
                        <th>グループ</th>
                        <th onClick={() => requestSort("updated_at")}>
                          更新日 {getSortDirectionIndicator("updated_at")}
                        </th>
                        <th onClick={() => requestSort("company_name")}>
                          企業 {getSortDirectionIndicator("company_name")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(sortedAndFilteredLocations) &&
                        sortedAndFilteredLocations.map(loc => (
                          <tr key={loc.loc_id}>
                            <td>{loc.val_flg}</td>
                            <td>{loc.loc_code}</td>
                            <td>{loc.loc_name}</td>
                            <td>{loc.val_date}</td>
                            <td>
                              <select
                                className="form-control select2"
                                multiple="multiple"
                                style={{ width: "100%" }}
                                data-location-id={loc.loc_id}
                              ></select>
                            </td>
                            <td>{loc.updated_at}</td>
                            <td>{loc.company_name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LocationsList
