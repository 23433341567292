import {
  DELIVERY_PLAN_REQUEST,
  DELIVERY_PLAN_SUCCESS,
  DELIVERY_PLAN_ERROR,
  FETCH_ITEMS_FOR_LOADING_REQUEST,
  FETCH_ITEMS_FOR_LOADING_SUCCESS,
  FETCH_ITEMS_FOR_LOADING_ERROR,
  FETCH_DELIVERY_PLANS_REQUEST,
  FETCH_DELIVERY_PLANS_SUCCESS,
  FETCH_DELIVERY_PLANS_ERROR,
  UPDATE_DELIVERY_PLAN_REQUEST,
  UPDATE_DELIVERY_PLAN_SUCCESS,
  UPDATE_DELIVERY_PLAN_ERROR,
  FETCH_DELIVERY_PLAN_DETAIL_REQUEST,
  FETCH_DELIVERY_PLAN_DETAIL_SUCCESS,
  FETCH_DELIVERY_PLAN_DETAIL_ERROR,
} from "./actionTypes"

export const createDeliveryPlanRequest = planData => ({
  type: DELIVERY_PLAN_REQUEST,
  payload: planData,
})

export const createDeliveryPlanSuccess = data => ({
  type: DELIVERY_PLAN_SUCCESS,
  payload: data,
})

export const createDeliveryPlanError = error => ({
  type: DELIVERY_PLAN_ERROR,
  error,
})

export const fetchItemsForLoading = payload => ({
  type: FETCH_ITEMS_FOR_LOADING_REQUEST,
  payload,
})

export const fetchItemsForLoadingSuccess = data => ({
  type: FETCH_ITEMS_FOR_LOADING_SUCCESS,
  payload: data,
})

export const fetchItemsForLoadingError = error => ({
  type: FETCH_ITEMS_FOR_LOADING_ERROR,
  error,
})

export const fetchDeliveryPlansRequest = type => ({
  type: FETCH_DELIVERY_PLANS_REQUEST,
  payload: type,
})

export const fetchDeliveryPlansSuccess = data => ({
  type: FETCH_DELIVERY_PLANS_SUCCESS,
  payload: data,
})

export const fetchDeliveryPlansError = error => ({
  type: FETCH_DELIVERY_PLANS_ERROR,
  error,
})

export const updateDeliveryPlanRequest = delivery_plan => ({
  type: UPDATE_DELIVERY_PLAN_REQUEST,
  payload: { delivery_plan },
})

export const updateDeliveryPlanSuccess = data => ({
  type: UPDATE_DELIVERY_PLAN_SUCCESS,
  payload: data,
})

export const updateDeliveryPlanError = error => ({
  type: UPDATE_DELIVERY_PLAN_ERROR,
  error,
})

export const fetchDeliveryPlanDetailRequest = deliveryPlanId => ({
  type: FETCH_DELIVERY_PLAN_DETAIL_REQUEST,
  payload: deliveryPlanId,
})

export const fetchDeliveryPlanDetailSuccess = data => ({
  type: FETCH_DELIVERY_PLAN_DETAIL_SUCCESS,
  payload: data,
})

export const fetchDeliveryPlanDetailError = error => ({
  type: FETCH_DELIVERY_PLAN_DETAIL_ERROR,
  error,
})
