// actions.js
import * as actionTypes from "./actionTypes"

export const fetchLocationGroups = () => ({
  type: actionTypes.FETCH_LOCATION_GROUPS,
})

export const fetchLocationGroupsSuccess = groups => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_SUCCESS,
  payload: groups,
})

export const fetchLocationGroupsError = error => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_ERROR,
  error,
})

export const updateLocationGroup = (groupId, groupData) => ({
  type: actionTypes.UPDATE_LOCATION_GROUP,
  payload: { groupId, groupData },
})

export const updateLocationGroupSuccess = group => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_SUCCESS,
  payload: group,
})

export const updateLocationGroupError = error => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_ERROR,
  error,
})

export const deleteLocationGroup = groupId => ({
  type: actionTypes.DELETE_LOCATION_GROUP,
  payload: groupId,
})

export const deleteLocationGroupSuccess = groupId => ({
  type: actionTypes.DELETE_LOCATION_GROUP_SUCCESS,
  payload: groupId,
})

export const deleteLocationGroupError = error => ({
  type: actionTypes.DELETE_LOCATION_GROUP_ERROR,
  error,
})
