export const FETCH_WAREHOUSES = "FETCH_WAREHOUSES"
export const FETCH_WAREHOUSES_SUCCESS = "FETCH_WAREHOUSES_SUCCESS"
export const FETCH_WAREHOUSES_ERROR = "FETCH_WAREHOUSES_ERROR"
export const ADD_WAREHOUSE = "ADD_WAREHOUSE"
export const ADD_WAREHOUSE_SUCCESS = "ADD_WAREHOUSE_SUCCESS"
export const ADD_WAREHOUSE_ERROR = "ADD_WAREHOUSE_ERROR"
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE"
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS"
export const UPDATE_WAREHOUSE_ERROR = "UPDATE_WAREHOUSE_ERROR"
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE"
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS"
export const DELETE_WAREHOUSE_ERROR = "DELETE_WAREHOUSE_ERROR"

// 倉庫在庫のアクションタイプを追加
export const FETCH_WAREHOUSE_STOCK = "FETCH_WAREHOUSE_STOCK"
export const FETCH_WAREHOUSE_STOCK_SUCCESS = "FETCH_WAREHOUSE_STOCK_SUCCESS"
export const FETCH_WAREHOUSE_STOCK_ERROR = "FETCH_WAREHOUSE_STOCK_ERROR"
export const ADD_WAREHOUSE_STOCK = "ADD_WAREHOUSE_STOCK"
export const ADD_WAREHOUSE_STOCK_SUCCESS = "ADD_WAREHOUSE_STOCK_SUCCESS"
export const ADD_WAREHOUSE_STOCK_ERROR = "ADD_WAREHOUSE_STOCK_ERROR"
export const UPDATE_WAREHOUSE_STOCK = "UPDATE_WAREHOUSE_STOCK"
export const UPDATE_WAREHOUSE_STOCK_SUCCESS = "UPDATE_WAREHOUSE_STOCK_SUCCESS"
export const UPDATE_WAREHOUSE_STOCK_ERROR = "UPDATE_WAREHOUSE_STOCK_ERROR"
export const DELETE_WAREHOUSE_STOCK = "DELETE_WAREHOUSE_STOCK"
export const DELETE_WAREHOUSE_STOCK_SUCCESS = "DELETE_WAREHOUSE_STOCK_SUCCESS"
export const DELETE_WAREHOUSE_STOCK_ERROR = "DELETE_WAREHOUSE_STOCK_ERROR"
