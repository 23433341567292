import {
  FETCH_GOODS,
  FETCH_GOODS_SUCCESS,
  FETCH_GOODS_ERROR,
  ADD_GOOD,
  ADD_GOOD_SUCCESS,
  ADD_GOOD_ERROR,
  UPDATE_GOOD,
  UPDATE_GOOD_SUCCESS,
  UPDATE_GOOD_ERROR,
  DELETE_GOOD,
  DELETE_GOOD_SUCCESS,
  DELETE_GOOD_ERROR,
  REGISTER_SURPRISE,
  REGISTER_SURPRISE_SUCCESS,
  REGISTER_SURPRISE_ERROR,
  UNREGISTER_SURPRISE,
  UNREGISTER_SURPRISE_SUCCESS,
  UNREGISTER_SURPRISE_ERROR,
  FETCH_GOODS_DETAIL,
  FETCH_GOODS_DETAIL_SUCCESS,
  FETCH_GOODS_DETAIL_ERROR,
} from "./actionTypes"

const initialState = {
  goods: [],
  goodsDetail: { result1: [], result2: [] }, // 初期状態にgoodsDetailを追加
  loading: false,
  error: null,
}

const GoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOODS:
    case ADD_GOOD:
    case UPDATE_GOOD:
    case DELETE_GOOD:
    case REGISTER_SURPRISE:
    case UNREGISTER_SURPRISE:
      return {
        ...state,
        loading: true,
        error: null, // エラー状態をリセット
      }
    case FETCH_GOODS_SUCCESS:
      return {
        ...state,
        goods: action.payload, // 商品リストを更新
        loading: false,
      }
    case ADD_GOOD_SUCCESS:
      return {
        ...state,
        goods: action.payload, // 商品リストを更新
        loading: false,
      }
    case UPDATE_GOOD_SUCCESS:
      return {
        ...state,
        goods: action.payload, // 商品リストを更新
        loading: false,
      }
    case DELETE_GOOD_SUCCESS:
      return {
        ...state,
        goods: action.payload, // 商品リストを更新
        loading: false,
      }
    case FETCH_GOODS_ERROR:
    case ADD_GOOD_ERROR:
    case UPDATE_GOOD_ERROR:
    case DELETE_GOOD_ERROR:
    case REGISTER_SURPRISE_ERROR:
    case UNREGISTER_SURPRISE_ERROR:
      return {
        ...state,
        error: action.payload, // エラー情報を設定
        loading: false,
      }
    case FETCH_GOODS_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_GOODS_DETAIL_SUCCESS:
    case REGISTER_SURPRISE_SUCCESS:
    case UNREGISTER_SURPRISE_SUCCESS:
      return {
        ...state,
        goodsDetail: action.payload.data,
        loading: false,
      }
    case FETCH_GOODS_DETAIL_ERROR:
      return {
        ...state,
        goodsDetail: null,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default GoodsReducer
