// actions.js
import {
  FETCH_MARKETING_COMMON_GOODS_BRAND_DATA,
  FETCH_MARKETING_COMMON_GOODS_BRAND_DATA_SUCCESS,
  FETCH_MARKETING_COMMON_GOODS_BRAND_DATA_ERROR,
  FETCH_MARKETING_COMMON_IPCS_DATA,
  FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS,
  FETCH_MARKETING_COMMON_IPCS_DATA_ERROR,
  FETCH_IPCS_DATA_DETAIL,
  FETCH_IPCS_DATA_DETAIL_SUCCESS,
  FETCH_IPCS_DATA_DETAIL_ERROR,
  UPDATE_IPCS_DATA,
  UPDATE_IPCS_DATA_SUCCESS,
  UPDATE_IPCS_DATA_ERROR,
} from "./actionTypes"

// Fetch goods data action
export const fetchGoodsBrandData = () => {
  return {
    type: FETCH_MARKETING_COMMON_GOODS_BRAND_DATA,
  }
}

// Fetch goods data success action
export const fetchGoodsBrandDataSuccess = payload => {
  return {
    type: FETCH_MARKETING_COMMON_GOODS_BRAND_DATA_SUCCESS,
    payload,
  }
}

// Fetch goods data error action
export const fetchGoodsBrandDataError = error => {
  return {
    type: FETCH_MARKETING_COMMON_GOODS_BRAND_DATA_ERROR,
    error,
  }
}

// Fetch goods data action
export const fetchIpcsData = (onlyCamera, onlyCashless, onlyIpc, pastLoc) => {
  return {
    type: FETCH_MARKETING_COMMON_IPCS_DATA,
    payload: {
      onlyCamera: onlyCamera,
      onlyCashless: onlyCashless,
      onlyIpc: onlyIpc,
      pastLoc: pastLoc,
    },
  }
}

// Fetch goods data success action
export const fetchIpcsDataSuccess = payload => {
  return {
    type: FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS,
    payload,
  }
}

// Fetch goods data error action
export const fetchIpcsDataError = error => {
  return {
    type: FETCH_MARKETING_COMMON_IPCS_DATA_ERROR,
    error,
  }
}

export const fetchIpcsDataDetail = ipc_no => {
  return {
    type: FETCH_IPCS_DATA_DETAIL,
    ipc_no,
  }
}

// Fetch goods data success action
export const fetchIpcsDataDetailSuccess = payload => {
  return {
    type: FETCH_IPCS_DATA_DETAIL_SUCCESS,
    payload,
  }
}

// Fetch goods data error action
export const fetchIpcsDataDetailError = error => {
  return {
    type: FETCH_IPCS_DATA_DETAIL_ERROR,
    error,
  }
}

// IPCデータを更新するアクション
export const updateIpcsData = ipcData => {
  return {
    type: UPDATE_IPCS_DATA,
    payload: ipcData,
  }
}

// IPCデータ更新成功アクション
export const updateIpcsDataSuccess = payload => {
  return {
    type: UPDATE_IPCS_DATA_SUCCESS,
    payload,
  }
}

// IPCデータ更新エラーアクション
export const updateIpcsDataError = error => {
  return {
    type: UPDATE_IPCS_DATA_ERROR,
    error,
  }
}
