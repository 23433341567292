import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_SALES_LOC_DATA_REQUEST } from "./actionTypes"
import { fetchSalesLocDataSuccess, fetchSalesLocDataError } from "./actions"
import { getSalesLocData } from "../../helpers/backend_helper"

function* fetchSalesLocDataSaga(action) {
  try {
    const { locId } = action.payload

    const salesLocData = yield call(getSalesLocData, locId)
    const results = salesLocData.data.results
    yield put(fetchSalesLocDataSuccess(results))
  } catch (error) {
    yield put(fetchSalesLocDataError(error))
  }
}

function* SalesLocSaga() {
  yield takeEvery(FETCH_SALES_LOC_DATA_REQUEST, fetchSalesLocDataSaga)
}

export default SalesLocSaga
