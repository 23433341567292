import React from "react"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"

class BarChartSep extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.prepareData(this.props.data),
    }
  }

  componentDidUpdate(prevProps) {
    // propsのdataが変更された際に、stateを更新
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.prepareData(this.props.data),
      })
    }
  }

  prepareData(rawData) {
    const labels = [
      ...new Set(
        rawData.map(item =>
          item.date
            .replace(/0(\d)/g, "$1")
            .replace("月", "/")
            .replace("日", " ")
        )
      ),
    ]
    const products = [
      ...new Set(
        rawData
          .filter(item => item.product_name !== null)
          .map(item => item.product_name)
      ),
    ]

    const top5Colors = [
      "rgba(75, 192, 192, 0.7)", // シアン
      "rgba(255, 206, 86, 0.7)", // 黄色
      "rgba(153, 102, 255, 0.7)", // 紫色
      "rgba(255, 99, 32, 0.7)", // オレンジ
      "rgba(75, 255, 75, 0.7)", // 緑色
      "rgba(255, 159, 64, 0.7)", // ピーチ
      "rgba(54, 162, 235, 0.7)", // 青色
      "rgba(255, 99, 132, 0.7)", // ローズピンク
      "rgba(173, 216, 230, 0.7)", // ライトブルー
      "rgba(255, 159, 243, 0.7)", // ライトピンク
      "rgba(220, 220, 220, 0.7)", // 薄い灰色 その他
    ]

    const datasets = products.map((product, index) => ({
      label: product,
      data: labels.map(label => {
        const item = rawData.find(
          d =>
            d.date
              .replace(/0(\d)/g, "$1")
              .replace("月", "/")
              .replace("日", " ") === label && d.product_name === product
        )
        return item ? item.total_sales : 0
      }),
      backgroundColor: top5Colors[index % top5Colors.length], // 商品ごとの色を設定
      stack: "Stack 0",
    }))

    return { labels, datasets }
  }

  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return <div>データ無し</div>
    }

    const options = {
      plugins: {
        datalabels: {
          display: false,
          color: "#000",
          formatter: function (value, context) {
            return new Intl.NumberFormat().format(value)
          },
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return new Intl.NumberFormat().format(value)
          },
        },
        x: {
          display: true, // This hides the labels.
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const value = tooltipItem.yLabel
            return new Intl.NumberFormat().format(value)
          },
        },
      },
      legend: {
        display: true, // Display the legend
        position: "left", // Left align the legend
      },
    }

    const chartStyle = {
      height: "400px",
    }

    return (
      <div style={chartStyle}>
        <Bar options={options} data={this.state.data} />
      </div>
    )
  }
}

export default BarChartSep
