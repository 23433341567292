import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_SOLD_OUT_REQUEST } from "./actionTypes"
import { fetchSoldOutSuccess, fetchSoldOutError } from "./actions"
// API呼び出し関数は適切にインポートするか定義してください
import { getSoldOutData } from "../../helpers/backend_helper"

// groupIdを引数としてAPI呼び出しに使用
function* fetchSoldOutSaga(action) {
  try {
    const data = yield call(getSoldOutData, action.payload) // API関数にgroupIdを渡す
    yield put(fetchSoldOutSuccess(data.data))
  } catch (error) {
    yield put(fetchSoldOutError(error.message))
  }
}

function* soldOutSaga() {
  yield takeEvery(FETCH_SOLD_OUT_REQUEST, fetchSoldOutSaga)
}

export default soldOutSaga
