// sagas.js
import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_VEHICLES,
  ADD_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  FETCH_VEHICLE_STOCK,
  ADD_VEHICLE_STOCK,
  UPDATE_VEHICLE_STOCK,
  DELETE_VEHICLE_STOCK,
} from "./actionTypes"
import {
  fetchVehiclesSuccess,
  fetchVehiclesError,
  addVehicleSuccess,
  addVehicleError,
  updateVehicleSuccess,
  updateVehicleError,
  deleteVehicleSuccess,
  deleteVehicleError,
  fetchVehicleStockSuccess,
  fetchVehicleStockError,
  addVehicleStockSuccess,
  addVehicleStockError,
  updateVehicleStockSuccess,
  updateVehicleStockError,
  deleteVehicleStockSuccess,
  deleteVehicleStockError,
} from "./actions"

// API関数は適宜定義または既存のヘルパー関数を利用してください
import {
  getVehicles,
  postVehicle,
  putVehicle,
  deleteVehicle,
  getVehicleStock,
  postVehicleStock,
  putVehicleStock,
  delVehicleStock,
} from "../../helpers/backend_helper"

function* fetchVehiclesSaga() {
  try {
    const vehicles = yield call(getVehicles)
    yield put(fetchVehiclesSuccess(vehicles))
  } catch (error) {
    yield put(fetchVehiclesError(error))
  }
}

function* addVehicleSaga(action) {
  try {
    const newVehicle = yield call(postVehicle, action.payload)
    yield put(addVehicleSuccess(newVehicle))
  } catch (error) {
    yield put(addVehicleError(error))
  }
}

function* updateVehicleSaga(action) {
  try {
    const updatedVehicle = yield call(putVehicle, action.payload)
    yield put(updateVehicleSuccess(updatedVehicle))
  } catch (error) {
    yield put(updateVehicleError(error))
  }
}

function* deleteVehicleSaga(action) {
  try {
    const response = yield call(deleteVehicle, action.payload) // Implement this API call
    yield put(deleteVehicleSuccess(response))
  } catch (error) {
    yield put(deleteVehicleError(error))
  }
}

// 倉庫在庫の取得
function* fetchVehicleStockSaga({ payload }) {
  try {
    const response = yield call(getVehicleStock, payload)
    yield put(fetchVehicleStockSuccess(response))
  } catch (error) {
    yield put(fetchVehicleStockError(error))
  }
}

// 倉庫在庫の追加
function* addVehicleStockSaga({ payload }) {
  try {
    const response = yield call(postVehicleStock, payload.vehicleData)
    yield put(addVehicleStockSuccess(response))
  } catch (error) {
    yield put(addVehicleStockError(error))
  }
}

// 倉庫在庫の更新
function* updateVehicleStockSaga({ payload }) {
  try {
    const response = yield call(putVehicleStock, payload.vehicleId)
    yield put(updateVehicleStockSuccess(response))
  } catch (error) {
    yield put(updateVehicleStockError(error))
  }
}

// 倉庫在庫の削除
function* deleteVehicleStockSaga({ payload }) {
  try {
    const response = yield call(delVehicleStock, payload)
    yield put(deleteVehicleStockSuccess(response))
  } catch (error) {
    yield put(deleteVehicleStockError(error))
  }
}

export function* vehicleSaga() {
  yield takeEvery(FETCH_VEHICLES, fetchVehiclesSaga)
  yield takeEvery(ADD_VEHICLE, addVehicleSaga)
  yield takeEvery(UPDATE_VEHICLE, updateVehicleSaga)
  yield takeEvery(DELETE_VEHICLE, deleteVehicleSaga)
  yield takeEvery(FETCH_VEHICLE_STOCK, fetchVehicleStockSaga) // 追加
  yield takeEvery(ADD_VEHICLE_STOCK, addVehicleStockSaga) // 追加
  yield takeEvery(UPDATE_VEHICLE_STOCK, updateVehicleStockSaga) // 追加
  yield takeEvery(DELETE_VEHICLE_STOCK, deleteVehicleStockSaga) // 追加
}

export default vehicleSaga
