// StyledComponents.js
import styled from "styled-components"
import { Button, Input } from "reactstrap" // ここでは、reactstrapからButtonとInputをimportしていますが、
// 別のライブラリや自分で定義したコンポーネントを使っても構いません。

export const StyledButton1 = styled(Button)`
  margin-right: 5px;
  width: 20%;
`

export const StyledButton2 = styled(Button)`
  marginbottom: "10px";
  width: 100%;
`

export const StyledButton3 = styled(Button)`
  marginbottom: "10px";
  width: 40%;
  flex-shrink: 0; // この要素が縮小しないようにします。
`

export const StyledButton4 = styled(Button)`
  marginbottom: "10px";
  width: 50%;
  flex-shrink: 0; // この要素が縮小しないようにします。
`

export const StyledInput1 = styled(Input)`
  margin-right: 5px;
  width: 60%;
  text-align: center;
  font-weight: bold; // 文字を太文字にします
  font-size: 1.2em; // 文字サイズを少し大きくします
`

export const StyledButtonRow = styled.div`
  display: flex;
  flexdirection: "row";
  justifycontent: "space-between";
  width: 100%;
`

export const StyledButtonRow1 = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`

export const StyledActionBtn = styled(Button)`
  margin-right: 5px;
  width: 45%; // 100%のスペースを均等に2つのボタンで分けるために45%と設定しています。
`

export const StyledDiv1 = styled.div`
  width: 60%; // 幅を300ピクセルに固定します
  flex-shrink: 0; // この要素が縮小しないようにします
  font-weight: bold; // 文字を太文字にします
  font-size: 1.2em; // 文字サイズを少し大きくします
`
