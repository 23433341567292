import * as types from "./actionTypes"

// ロケーションデータ更新リクエストのアクション
export function updateLocationData(locationId, locationData) {
  return { 
    type: types.UPDATE_LOCATION_DATA, 
    payload: { locationId, locationData } 
  };
}

// ロケーションデータ更新成功時のアクション
export function updateLocationDataSuccess(data) {
  return { type: types.UPDATE_LOCATION_DATA_SUCCESS, payload: data };
}

// ロケーションデータ更新失敗（エラー）時のアクション
export function updateLocationDataError(error) {
  return { type: types.UPDATE_LOCATION_DATA_ERROR, error };
}

// ロケーションデータ登録リクエストのアクション
export function registerLocationData(ipcNo, locationData) {
  return { 
    type: types.REGISTER_LOCATION_DATA, 
    payload: { ipcNo, locationData } 
  };
}

// ロケーションデータ登録成功時のアクション
export function registerLocationDataSuccess(data) {
  return { type: types.REGISTER_LOCATION_DATA_SUCCESS, payload: data };
}

// ロケーションデータ登録失敗（エラー）時のアクション
export function registerLocationDataError(error) {
  return { type: types.REGISTER_LOCATION_DATA_ERROR, error };
}

// ロケーションデータ削除リクエストのアクション
export function deleteLocationData(locationId, locationData) {
  return { 
    type: types.DELETE_LOCATION_DATA, 
    payload: { locationId, locationData } 
  };
}

// ロケーションデータ削除成功時のアクション
export function deleteLocationDataSuccess(data) {
  return { type: types.DELETE_LOCATION_DATA_SUCCESS, payload: data };
}

// ロケーションデータ削除失敗（エラー）時のアクション
export function deleteLocationDataError(error) {
  return { type: types.DELETE_LOCATION_DATA_ERROR, error };
}