import { call, put, takeEvery } from "redux-saga/effects"
import { GET_ANNOUNCEMENT_REQUEST } from "./actionTypes"
import { getAnnouncementSuccess, getAnnouncementError } from "./actions"
import { getAnnouncementData } from "../../helpers/backend_helper"

function* fetchAnnouncementSaga(action) {
  try {
    const response = yield call(getAnnouncementData)
    yield put(getAnnouncementSuccess(response.data))
  } catch (error) {
    yield put(getAnnouncementError(error))
  }
}

function* announcementSaga() {
  yield takeEvery(GET_ANNOUNCEMENT_REQUEST, fetchAnnouncementSaga)
}

export default announcementSaga
