export const FETCH_SHELFS_DATA_REQUEST = "FETCH_SHELFS_DATA_REQUEST"
export const FETCH_SHELFS_DATA_SUCCESS = "FETCH_SHELFS_DATA_SUCCESS"
export const FETCH_SHELFS_DATA_ERROR = "FETCH_SHELFS_DATA_ERROR"
export const UPDATE_EXPIRATION_DATE_REQUEST = "UPDATE_EXPIRATION_DATE_REQUEST"
export const UPDATE_EXPIRATION_DATE_SUCCESS = "UPDATE_EXPIRATION_DATE_SUCCESS"
export const UPDATE_EXPIRATION_DATE_ERROR = "UPDATE_EXPIRATION_DATE_ERROR"
export const FETCH_PRODUCTS_BY_IPC_REQUEST = "FETCH_PRODUCTS_BY_IPC_REQUEST"
export const FETCH_PRODUCTS_BY_IPC_SUCCESS = "FETCH_PRODUCTS_BY_IPC_SUCCESS"
export const FETCH_PRODUCTS_BY_IPC_FAILURE = "FETCH_PRODUCTS_BY_IPC_FAILURE"
export const FETCH_SHELF_BY_IPC_AND_COLUMN_REQUEST =
  "FETCH_SHELF_BY_IPC_AND_COLUMN_REQUEST"
export const FETCH_SHELF_BY_IPC_AND_COLUMN_SUCCESS =
  "FETCH_SHELF_BY_IPC_AND_COLUMN_SUCCESS"
export const FETCH_SHELF_BY_IPC_AND_COLUMN_FAILURE =
  "FETCH_SHELF_BY_IPC_AND_COLUMN_FAILURE"
