import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_MARKETING_VOLUMEGRAPH_DATA } from "./actionTypes"
import { fetchGraphDataSuccess, fetchGraphDataError } from "./actions"

//Include Both Helper File with needed methods
import { getMarketingVolume } from "../../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const { goodsBrand, locId, timePeriod, currentDate, previousDate } =
      action.payload
    const graphData = yield call(
      getMarketingVolume,
      goodsBrand,
      locId,
      timePeriod,
      currentDate,
      previousDate,
    )
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* MarketingVolumeSaga() {
  yield takeEvery(FETCH_MARKETING_VOLUMEGRAPH_DATA, fetchGraphDataSaga)
}

export default MarketingVolumeSaga
