import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { withTranslation } from "react-i18next"
import { getAnnouncementRequest } from "../../store/announce/actions"

const Dashboard = props => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.announcementReducer?.data)
  const announcements = data?.messages || []
  const low_stock_items = data?.low_stock_items || []
  const shelfsExpiryResults = data?.shelfs_expiry_results || []
  const shelfsEndResults = data?.shelfs_end_results || []

  const INITIAL_ITEMS_COUNT = 5
  const [itemsCount, setItemsCount] = useState(INITIAL_ITEMS_COUNT)
  const [expiryItemsCount, setExpiryItemsCount] = useState(INITIAL_ITEMS_COUNT)
  const [endItemsCount, setEndItemsCount] = useState(INITIAL_ITEMS_COUNT)

  const handleLoadMore = () => setItemsCount(itemsCount + 5)
  const handleLoadMoreExpiry = () => setExpiryItemsCount(expiryItemsCount + 5)
  const handleLoadMoreEnd = () => setEndItemsCount(endItemsCount + 5)

  useEffect(() => {
    dispatch(getAnnouncementRequest())
  }, [dispatch])

  // 賞味期限と滞留期限のリンクを生成する関数
  const createShelfLink = (loc_id, column_no) => {
    return `/shelfs?loc_id=${loc_id}&column_no=${column_no}`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={8}>
              <h6 className="page-title">ダッシュボード</h6>
            </Col>
          </Row>

          {/* J-Prime からのお知らせ */}
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">J-Prime からのお知らせ</h4>
              <div className="mt-4">
                {announcements.map((announcement, index) => (
                  <div key={index} className="announcement">
                    {announcement.is_new === 1 && (
                      <span className="badge bg-dark me-2">New</span>
                    )}
                    <span
                      style={
                        announcement.urgent === 1
                          ? { color: "red", fontWeight: "bold" }
                          : null
                      }
                    >
                      {announcement.message}
                    </span>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>

          {/* 在庫不足アイテム */}
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">在庫不足アイテム</h4>
              <ol className="activity-feed">
                {low_stock_items.slice(0, itemsCount).map((item, index) => (
                  <li className="feed-item" key={index}>
                    <div className="feed-item-list">
                      <span className="date">{item.date}</span>
                      <span className="activity-text">
                        自販機「
                        <Link to={`/stocks?loc_id=${item.loc_id}`}>
                          {item.loc_name}
                        </Link>
                        」の商品「{item.goods_name}」(コラム{item.column_no}
                        )が売り切れました。
                      </span>
                    </div>
                  </li>
                ))}
              </ol>
              {itemsCount < low_stock_items.length && (
                <Button onClick={handleLoadMore} className="btn btn-primary">
                  更に読み込む
                </Button>
              )}
            </CardBody>
          </Card>

          {/* 賞味期限情報 */}
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">賞味期限情報</h4>
              <ol className="activity-feed">
                {shelfsExpiryResults
                  .slice(0, expiryItemsCount)
                  .map((item, index) => (
                    <li className="feed-item" key={index}>
                      <div className="feed-item-list">
                        <span className="date">{item.expiry_date}</span>
                        <span className="activity-text">
                          自販機「
                          <Link
                            to={createShelfLink(item.loc_id, item.column_no)}
                          >
                            {item.loc_name}
                          </Link>
                          」 の商品「{item.goods_name}
                          」の賞味期限が近づいています。
                        </span>
                      </div>
                    </li>
                  ))}
              </ol>
              {expiryItemsCount < shelfsExpiryResults.length && (
                <Button
                  onClick={handleLoadMoreExpiry}
                  className="btn btn-primary"
                >
                  更に読み込む (賞味期限)
                </Button>
              )}
            </CardBody>
          </Card>

          {/* 滞留期限情報 */}
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">滞留期限情報</h4>
              <ol className="activity-feed">
                {shelfsEndResults.slice(0, endItemsCount).map((item, index) => (
                  <li className="feed-item" key={index}>
                    <div className="feed-item-list">
                      <span className="date">{item.end_date}</span>
                      <span className="activity-text">
                        自販機「
                        <Link to={createShelfLink(item.loc_id, item.column_no)}>
                          {item.loc_name}
                        </Link>
                        」 の商品「{item.goods_name}
                        」の滞留期限が近づいています。
                      </span>
                    </div>
                  </li>
                ))}
              </ol>
              {endItemsCount < shelfsEndResults.length && (
                <Button onClick={handleLoadMoreEnd} className="btn btn-primary">
                  更に読み込む (滞留期限)
                </Button>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
