import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, animateScroll as scroll } from "react-scroll"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

import { useLocation } from "react-router-dom"
import { Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchSalesLocData } from "../../store/salesLoc/actions"
import BarChartSep from "../AllCharts/chartist/barchartSep"
import Linechart from "../AllCharts/chartist/dountchart"
import DountChart from "../AllCharts/chartist/dountchartSep"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import CommonComponent from "../CommonComponent"

BarChartSep.defaultProps = {
  labels: [],
  data: [],
}

DountChart.defaultProps = {
  labels: [],
  data: [],
}

const SalesLoc = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const loc_id_from_url = Number(params.get("loc_id"))

  const storedLoc = Number(localStorage.getItem("selectedLoc"))
  const initialIpcsValue = loc_id_from_url || storedLoc || ""
  const [locId, setLoc] = useState(initialIpcsValue)

  const salesData = useSelector(state => state.salesLocReducer.salesData)
  const isLoading = useSelector(state => state.salesLocReducer.loading)
  const [vendor_id, setVendor] = useState(null)
  const [currentDate, setCurrentDate] = useState(new Date())

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSalesLocData(locId))
  }, [dispatch, locId])

  const styles = {
    odd: { backgroundColor: "#f2f2f2" },
    even: { backgroundColor: "#ddd" },
  }

  const [displayItems, setDisplayItems] = useState({})

  useEffect(() => {
    if (salesData) {
      let initialDisplayItems = {}
      salesData.forEach(monthData => {
        initialDisplayItems[monthData.month] = 10
      })
      setDisplayItems(initialDisplayItems)
    }
  }, [salesData])

  const handleShowMore = month => {
    setDisplayItems(prevState => ({
      ...prevState,
      [month]: prevState[month] + 10,
    }))
  }

  const convertToCSV = data => {
    // CSVのヘッダー
    const headers = ["月", "ランキング", "商品名", "価格", "数量", "商品別売上"]

    // データ行
    const rows = data.flatMap(item => {
      // 商品を売上でソートし、順位を付ける
      const sortedGoods = item.goodsSales.sort((a, b) => b.sales - a.sales)
      return sortedGoods.map((goods, index) =>
        [
          item.month,
          index + 1, // 順位
          `"${goods.name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          goods.price,
          goods.quantity,
          goods.sales,
        ].join(",")
      )
    })

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const downloadCSV = (data, convertFunction, filename) => {
    const csvString = convertFunction(data)
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // 商品別ランキングのダウンロード
  const downloadProductRankingCSV = () => {
    downloadCSV(
      salesData,
      convertToCSV,
      `product_ranking_${locId ?? "all"}.csv`
    )
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="ホーム"
            breadcrumbItem="売上分析（ロケーション毎）"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <LoadingOverlay isLoading={isLoading} />
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    locId={locId}
                    setLoc={setLoc}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            {salesData &&
              salesData.map((monthData, index) => (
                <Button
                  key={index}
                  tag={Link}
                  to={monthData.month}
                  smooth={true}
                  duration={500}
                  className="mr-2 mb-2 my-custom-button"
                >
                  {monthData.month}
                </Button>
              ))}
            <Button
              color="info"
              onClick={downloadProductRankingCSV}
              className="mr-2 mb-2 my-custom-button"
            >
              商品別ランキングCSVダウンロード
            </Button>
          </div>
          {salesData &&
            salesData.map((monthData, index) => (
              <Row key={index} id={monthData.month}>
                <Col xs={12}>
                  <div className="lineWithText">{monthData.month}</div>
                  <Card className={tableStyle} style={{ maxWidth: "1200px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 売上</h5>
                      </CardTitle>
                      <BarChartSep
                        data={monthData.sales}
                        label={monthData.labels}
                      />
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "1200px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 月別商品売上</h5>
                      </CardTitle>
                      <DountChart
                        labels={monthData.monthlyProducts.map(
                          item => item.name
                        )}
                        data={monthData.monthlyProducts.map(item => item.sales)}
                      />
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 支払い方法別売上</h5>
                      </CardTitle>

                      {/* 売上個数のグラフ */}
                      <CardTitle>
                        <h6>売上個数</h6>
                      </CardTitle>
                      <Linechart
                        labels={monthData.payTypes.map(pt => pt.type)}
                        data={monthData.payTypes.map(pt => pt.count)}
                      />

                      {/* 売上金額のグラフ */}
                      <CardTitle>
                        <h6>売上金額</h6>
                      </CardTitle>
                      <Linechart
                        labels={monthData.payAmountTypes.map(pt => pt.type)}
                        data={monthData.payAmountTypes.map(pt => pt.amount)}
                      />
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 商品別売上ランキング</h5>
                      </CardTitle>
                      <Table>
                        <thead>
                          <tr>
                            <th className="px-2 text-end">順位</th>
                            <th className="product-name">商品名</th>
                            <th className="sales-price">単価</th>
                            <th className="sales-count">個数</th>
                            <th className="sales-amount">売上</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthData.goodsSales
                            .slice(0, displayItems[monthData.month])
                            .map((item, index) => (
                              <tr key={index}>
                                <td className="text-end">{index + 1}</td>
                                <td className="product-name-column">
                                  {item.name}
                                </td>
                                <td class="sales-price">
                                  {item.price.toLocaleString()}
                                </td>
                                <td class="sales-count">{item.quantity}</td>
                                <td class="sales-amount">
                                  {item.sales.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={3}>Total</td>
                            <td className="sales-count">
                              {monthData.goodsSales.reduce(
                                (sum, item) => sum + item.quantity,
                                0
                              )}
                            </td>
                            <td className="sales-amount">
                              {monthData.goodsSales
                                .reduce((sum, item) => sum + item.sales, 0)
                                .toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {displayItems[monthData.month] <
                        monthData.goodsSales.length && (
                        <Button onClick={() => handleShowMore(monthData.month)}>
                          続きを表示
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
        </Container>
        <div
          style={{
            position: "fixed",
            bottom: "1em",
            right: "1em",
            opacity: "0.7",
          }}
        >
          <Button onClick={() => scroll.scrollToTop({ duration: 100 })}>
            TOPへ戻る
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SalesLoc
