import { call, put, takeEvery } from "redux-saga/effects"
import { UPDATE_LOCATION_DATA, REGISTER_LOCATION_DATA, DELETE_LOCATION_DATA } from "./actionTypes"
import { 
  updateLocationDataSuccess, 
  updateLocationDataError,
  registerLocationDataSuccess, 
  registerLocationDataError,
  deleteLocationDataSuccess,
  deleteLocationDataError
} from "./actions"
import { updateLocationApi, registerLocationApi, deleteLocationApi } from "../../helpers/backend_helper"


// ロケーションデータを更新するSaga
function* updateLocationSaga(action) {
  try {
    // ロケーションIDとロケーションデータをペイロードから抽出
    const { locationId, locationData } = action.payload;

    // API関数を呼び出し、ロケーションデータを更新
    const response = yield call(updateLocationApi, locationId, locationData);

    const ipcDetail = response.data.results

    yield put(updateLocationDataSuccess(ipcDetail))
  } catch (error) {
    // エラーが発生した場合、エラーアクションをディスパッチ
    yield put(updateLocationDataError(error));
  }
}

// ロケーションデータを登録するSaga
function* registerLocationSaga(action) {
  try {
    const { ipcNo, locationData } = action.payload;
    const response = yield call(registerLocationApi, ipcNo, locationData);

    const ipcDetail = response.data.results

    yield put(registerLocationDataSuccess(ipcDetail));
  } catch (error) {
    yield put(registerLocationDataError(error));
  }
}

// ロケーションデータを更新するSaga
function* deleteLocationSaga(action) {
  try {
    // ロケーションIDとロケーションデータをペイロードから抽出
    const { locationId, locationData } = action.payload;

    // API関数を呼び出し、ロケーションデータを更新
    const response = yield call(deleteLocationApi, locationId, locationData);

    const ipcDetail = response.data.results

    yield put(deleteLocationDataSuccess(ipcDetail))
  } catch (error) {
    // エラーが発生した場合、エラーアクションをディスパッチ
    yield put(deleteLocationDataError(error));
  }
}

// ロケーション処理に関するSagaを監視
function* locationSaga() {
  yield takeEvery(UPDATE_LOCATION_DATA, updateLocationSaga)
  yield takeEvery(REGISTER_LOCATION_DATA, registerLocationSaga) // 登録用Sagaを追加
  yield takeEvery(DELETE_LOCATION_DATA, deleteLocationSaga)
}

export default locationSaga
