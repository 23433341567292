import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { useDispatch, useSelector } from "react-redux"
import { fetchUsersData } from "../../store/users/actions"
import { fetchWarehouses } from "../../store/warehouse/actions"
import { fetchSoldOut } from "../../store/soldout/actions"
import "./App.css"

import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const DeliveryPlanCreate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const isLoading1 = useSelector(state => state.usersReducer.loading)
  const isLoading2 = useSelector(state => state.warehouseReducer.loading)
  const isLoading3 = useSelector(state => state.soldOutReducer.loading)

  const getDefaultDates = () => {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(now.getDate() + 1)
    tomorrow.setHours(13, 0, 0, 0) // Set to 13:00
    // Adjust for timezone difference (Japan is UTC+9)
    const offset = tomorrow.getTimezoneOffset() * 60000
    const defaultStartDate = new Date(tomorrow.getTime() - offset)
      .toISOString()
      .slice(0, 16)
    const fiveHoursLater = new Date(tomorrow)
    fiveHoursLater.setHours(tomorrow.getHours() + 5)
    const defaultEndDate = new Date(fiveHoursLater.getTime() - offset)
      .toISOString()
      .slice(0, 16)
    return {
      defaultStartDate,
      defaultEndDate,
    }
  }

  const { defaultStartDate, defaultEndDate } = getDefaultDates()

  const [startDate, setStartDate] = useState(
    location.state?.startDate || defaultStartDate
  )
  const [endDate, setEndDate] = useState(
    location.state?.endDate || defaultEndDate
  )
  const [selectedStartWarehouse, setSelectedStartWarehouse] = useState(
    location.state?.startWarehouseId
      ? { value: { warehouse_id: location.state.startWarehouseId } }
      : null
  )
  const [selectedEndWarehouse, setSelectedEndWarehouse] = useState(
    location.state?.endWarehouseId
      ? { value: { warehouse_id: location.state.endWarehouseId } }
      : null
  )
  const [selectedUser, setSelectedUser] = useState(
    location.state?.selectedUser || null
  )
  const [displayLocations, setDisplayLocations] = useState(false)
  const [selectedLocations, setSelectedLocations] = useState(
    location.state?.selectedLocations || []
  )
  const [visibleStocks, setVisibleStocks] = useState({})
  const [modal, setModal] = useState(false)

  const userData = useSelector(state => state.usersReducer.userData)
  const users = userData && userData.data ? userData.data.results : []

  const warehouses =
    useSelector(state => state.warehouseReducer.warehouses) || []
  const soldOutData =
    useSelector(state => state.soldOutReducer.soldOutData) || []
  const route = useSelector(state => state.soldOutReducer.route) || ""

  useEffect(() => {
    dispatch(fetchUsersData())
    dispatch(fetchWarehouses())
  }, [dispatch])

  const handleWarehouseChange = (selectedOption, type) => {
    if (type === "start") {
      setSelectedStartWarehouse(selectedOption)
    } else if (type === "end") {
      setSelectedEndWarehouse(selectedOption)
    }
  }

  const handleDisplayLocations = () => {
    if (selectedUser) {
      dispatch(
        fetchSoldOut({
          warehouseId: selectedStartWarehouse.value.warehouse_id,
          userId: selectedUser.value,
        })
      )
      setDisplayLocations(true)
    }
  }

  const handleCreateRoute = () => {
    const deliveryPlanData = {
      start_date: startDate,
      end_date: endDate,
      user_id: selectedUser.value,
      start_warehouse_id: selectedStartWarehouse.value.warehouse_id,
      end_warehouse_id: selectedEndWarehouse.value.warehouse_id,
      locations: selectedLocations.map((locId, index) => {
        const locName =
          soldOutData.find(loc => loc.loc_id === locId)?.loc_name || ""
        return {
          loc_id: locId,
          sequence: index + 1,
          loc_name: locName,
        }
      }),
      status: 1, // initial status
    }

    navigate("/delivery-plan/map", {
      state: {
        startWarehouseId: selectedStartWarehouse.value.warehouse_id,
        endWarehouseId: selectedEndWarehouse.value.warehouse_id,
        selectedLocations,
        startDate,
        endDate,
        selectedUser,
        deliveryPlanData,
      },
    })
  }

  const warehouseOptions = warehouses.map(warehouse => ({
    value: warehouse,
    label: warehouse.warehouse_name,
  }))

  const userOptions = users.map(user => ({
    value: user.user_id,
    label: user.user_name,
  }))

  const toggleSelection = locId => {
    setSelectedLocations(prevSelected =>
      prevSelected.includes(locId)
        ? prevSelected.filter(id => id !== locId)
        : [...prevSelected, locId]
    )
  }

  const toggleVisibleStocks = locId => {
    setVisibleStocks(prev => ({
      ...prev,
      [`${locId}`]: !prev[`${locId}`],
    }))
  }

  const isButtonDisabled = () => {
    if (
      !selectedStartWarehouse ||
      !selectedEndWarehouse ||
      !selectedUser ||
      !startDate ||
      !endDate
    ) {
      return true
    }

    const start = new Date(startDate)
    const end = new Date(endDate)

    if (end < start) {
      return true
    }

    return false
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2 || isLoading3} />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h2>補充計画の作成</h2>
                  <FormGroup>
                    <Label for="user">担当者：</Label>
                    <Select
                      id="user"
                      components={makeAnimated()}
                      options={userOptions}
                      onChange={setSelectedUser}
                      value={selectedUser}
                      placeholder="担当者を選択..."
                    />
                  </FormGroup>
                  <Label for="user">稼働：</Label>
                  <FormGroup>
                    <Row>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          name="startDate"
                          id="startDate"
                          value={startDate}
                          onChange={e => setStartDate(e.target.value)}
                          required
                          min={new Date().toISOString().slice(0, 16)}
                        />
                      </Col>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          name="endDate"
                          id="endDate"
                          value={endDate}
                          onChange={e => setEndDate(e.target.value)}
                          required
                          min={startDate}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={6}>
                        <Select
                          id="warehouseStart"
                          components={makeAnimated()}
                          options={warehouseOptions}
                          onChange={selectedOption =>
                            handleWarehouseChange(selectedOption, "start")
                          }
                          value={selectedStartWarehouse}
                          placeholder="倉庫を選択..."
                        />
                      </Col>
                      <Col xs={6}>
                        <Select
                          id="warehouseEnd"
                          components={makeAnimated()}
                          options={warehouseOptions}
                          onChange={selectedOption =>
                            handleWarehouseChange(selectedOption, "end")
                          }
                          value={selectedEndWarehouse}
                          placeholder="倉庫を選択..."
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Button
                    type="button"
                    color="primary"
                    block
                    onClick={handleDisplayLocations}
                    disabled={isButtonDisabled()}
                  >
                    巡回ロケ候補を表示
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {displayLocations && (
                    <>
                      <h3 className="mt-4">
                        巡回ロケ候補{" "}
                        <span>
                          （倉庫住所: {selectedStartWarehouse?.value?.address}）
                        </span>
                      </h3>
                      {soldOutData.length > 0 &&
                        soldOutData.map((location, index) => (
                          <Col
                            key={location.loc_id}
                            md={6}
                            lg={8}
                            className="mx-auto"
                          >
                            <Card
                              className="mb-3"
                              style={{
                                border: "1px solid black",
                                backgroundColor: selectedLocations.includes(
                                  location.loc_id
                                )
                                  ? "#ff69b4"
                                  : "",
                              }}
                              onClick={() => toggleSelection(location.loc_id)}
                            >
                              <CardBody>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div>No {index + 1}</div>
                                  <div>
                                    <strong>ロケ住所:</strong>{" "}
                                    {location.loc_address || "住所不明"}
                                  </div>
                                  <div>
                                    <strong>距離:</strong>{" "}
                                    {location.stocks[0]?.distance?.distance ||
                                      "N/A"}
                                  </div>
                                </div>
                                <CardTitle
                                  tag="h5"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: selectedLocations.includes(
                                      location.loc_id
                                    )
                                      ? "white"
                                      : "black",
                                    padding: "5px",
                                    border: "1px solid black",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    toggleSelection(location.loc_id)
                                  }}
                                >
                                  {location.loc_name}
                                </CardTitle>
                                <div
                                  className="stock-header"
                                  onClick={e => {
                                    e.stopPropagation()
                                    toggleVisibleStocks(location.loc_id)
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <span style={{ flex: 4 }}>商品名</span>
                                  <span style={{ flex: 1, textAlign: "right" }}>
                                    在庫数
                                  </span>
                                  <span style={{ flex: 1, textAlign: "right" }}>
                                    不足数
                                  </span>
                                </div>
                                {location.stocks.map((stock, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    <span style={{ flex: 4 }}>
                                      {stock.goods_name}
                                    </span>
                                    <span
                                      style={{
                                        flex: 1,
                                        color:
                                          stock.current_stock === 0
                                            ? "red"
                                            : "black",
                                        textAlign: "right",
                                      }}
                                    >
                                      {stock.current_stock}
                                    </span>
                                    <span
                                      style={{ flex: 1, textAlign: "right" }}
                                    >
                                      {stock.shortage}
                                    </span>
                                  </div>
                                ))}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      <Button
                        type="button"
                        color="success"
                        block
                        onClick={handleCreateRoute}
                        disabled={selectedLocations.length === 0}
                      >
                        巡回ルートを作成
                      </Button>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanCreate
