import React, { Component } from "react"
import { Doughnut } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

Chart.register(...registerables)
Chart.register(ChartDataLabels)

class DountChart extends Component {
  render() {
    const { labels, data, onClick, showPercentage } = this.props

    const dountChartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#E7E9ED",
            "#9966FF",
            "#FF9F40",
          ],
        },
      ],
    }

    const dountChartOptions = {
      plugins: {
        datalabels: {
          color: "#fff",
          formatter: function (value, context) {
            // showPercentageがtrueの場合のみパーセンテージ表示
            return showPercentage ? value.toFixed(1) + "%" : value
          },
        },
      },
      cutoutPercentage: 50,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    }

    const chartStyle = {
      height: "300px",
    }

    return (
      <React.Fragment>
        {data && data.length ? (
          <div style={chartStyle}>
            <Doughnut
              data={dountChartData}
              options={dountChartOptions}
              onClick={onClick}
            />
          </div>
        ) : (
          <div style={chartStyle}>
            <p>対象データなし</p>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default DountChart
