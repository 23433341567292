// actions.js
import {
  FETCH_STOCKS_GRAPH_DATA,
  FETCH_STOCKS_GRAPH_DATA_SUCCESS,
  FETCH_STOCKS_GRAPH_DATA_ERROR,
  UPDATE_STOCK_ITEM,
  UPDATE_STOCK_ITEM_SUCCESS,
  UPDATE_STOCK_ITEM_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchGraphData = (
  goodsBrand,
  locId,
  timePeriod,
  currentDate,
  previousDate,
) => {
  return {
    type: FETCH_STOCKS_GRAPH_DATA,
    payload: { goodsBrand, locId, timePeriod, currentDate, previousDate },
  }
}

// Fetch graph data success action
export const fetchGraphDataSuccess = payload => {
  return {
    type: FETCH_STOCKS_GRAPH_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGraphDataError = error => {
  return {
    type: FETCH_STOCKS_GRAPH_DATA_ERROR,
    error,
  }
}

export const updateStockItem = (item, locId) => {
  return {
    type: UPDATE_STOCK_ITEM,
    payload: { item, locId },
  }
}

export const updateStockItemSuccess = payload => {
  return {
    type: UPDATE_STOCK_ITEM_SUCCESS,
    payload,
  }
}

export const updateStockItemError = error => ({
  type: UPDATE_STOCK_ITEM_ERROR,
  error,
})
