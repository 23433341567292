import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  customersData: [],
  error: null,
  pageNumber: 1, // 現在のページ番号
  pageSize: 10, // 1ページあたりのアイテム数
  totalItems: 0, // 総アイテム数
}

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMERS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        pageNumber: action.payload.pageNumber || state.pageNumber, // ページ番号を更新
        pageSize: action.payload.pageSize || state.pageSize, // ページサイズを更新
      }
    case actionTypes.FETCH_CUSTOMERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        customersData: action.payload.customers, // customersのデータのみを更新
        totalItems: action.payload.totalItems, // 総アイテム数を更新
        error: null,
      }
    case actionTypes.FETCH_CUSTOMERS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        customersData: [],
        error: action.payload,
      }
    // 顧客データの更新要求
    case actionTypes.UPDATE_CUSTOMER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    // 顧客データの更新成功
    case actionTypes.UPDATE_CUSTOMER_DATA_SUCCESS:
      const { index, customer } = action.payload
      return {
        ...state,
        loading: false,
        customersData: [
          ...state.customersData.slice(0, index),
          customer,
          ...state.customersData.slice(index + 1),
        ],
        error: null,
      }
    // 顧客データの更新エラー
    case actionTypes.UPDATE_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case actionTypes.FETCH_CUSTOMERS_DATA_REQUEST_2:
      return {
        ...state,
        loading: true,
        pageNumber: action.payload.pageNumber || state.pageNumber, // ページ番号を更新
        pageSize: action.payload.pageSize || state.pageSize, // ページサイズを更新
      }
    case actionTypes.FETCH_CUSTOMERS_DATA_SUCCESS_2:
      return {
        ...state,
        loading: false,
        customersData: action.payload.customers, // customersのデータのみを更新
        totalItems: action.payload.totalItems, // 総アイテム数を更新
        error: null,
      }
    case actionTypes.FETCH_CUSTOMERS_DATA_ERROR_2:
      return {
        ...state,
        loading: false,
        customersData: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export default customersReducer
