import { get, put } from "../api_helper" // `put` methodをインポートする場合
import * as url from "../url_helper"

const getCustomersData = async ({
  locId,
  genderFilter,
  ageRangeFilter,
  startDate,
  endDate,
  freeWordSearch,
  verifyFilter,
  pageNumber,
  pageSize,
}) => {
  try {
    const queryParams = new URLSearchParams()

    if (locId) {
      queryParams.append("locId", locId)
    }
    if (genderFilter) {
      queryParams.append("gender", genderFilter)
    }
    if (ageRangeFilter) {
      queryParams.append("ageRange", ageRangeFilter)
    }
    if (startDate) {
      queryParams.append("startDate", startDate)
    }
    if (endDate) {
      queryParams.append("endDate", endDate)
    }
    if (freeWordSearch) {
      queryParams.append("freeWordSearch", freeWordSearch)
    }
    if (verifyFilter) {
      queryParams.append("verify", verifyFilter)
    }
    if (pageNumber != null) {
      queryParams.append("pageNumber", pageNumber)
    }
    if (pageSize != null) {
      queryParams.append("pageSize", pageSize)
    }

    const response = await get(
      `${url.GET_CUSTOMERS_DATA}?${queryParams.toString()}`,
    )

    return { data: response }
  } catch (error) {
    throw error
  }
}

const getCustomersData2 = async ({
  locId,
  pageNumber,
  pageSize,
  startDate,
  endDate,
}) => {
  try {
    const queryParams = new URLSearchParams()

    if (locId) {
      queryParams.append("locId", locId)
    }
    if (pageNumber != null) {
      queryParams.append("pageNumber", pageNumber)
    }
    if (pageSize != null) {
      queryParams.append("pageSize", pageSize)
    }
    if (startDate) {
      queryParams.append("startDate", startDate)
    }
    if (endDate) {
      queryParams.append("endDate", endDate)
    }

    queryParams.append("index2", 1)

    const response = await get(
      `${url.GET_CUSTOMERS_DATA}?${queryParams.toString()}`,
    )

    return { data: response }
  } catch (error) {
    throw error
  }
}

// Update Customer Data Method
const updateCustomerData = async params => {
  try {
    const dataToSend = {
      customer_id: params.customer_id,
      seg1: params.seg1,
      seg2: params.seg2,
      is_verified: params.is_verified,
      genderFilter: params.genderFilter,
      ageRangeFilter: params.ageRangeFilter,
      startDate: params.startDate,
      endDate: params.endDate,
      freeWordSearch: params.freeWordSearch,
      locId: params.locId,
    }

    const response = await put(`${url.PUT_CUSTOMER_DATA}`, dataToSend)

    return { data: response }
  } catch (error) {
    throw error
  }
}

export { getCustomersData, getCustomersData2, updateCustomerData }
