import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  shelfsData: [],
  error: null,
}

const shelfsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHELFS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SHELFS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        shelfsData: action.payload,
        error: null,
      }
    case actionTypes.FETCH_SHELFS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        shelfsData: [],
        error: action.payload,
      }
    case actionTypes.UPDATE_EXPIRATION_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_EXPIRATION_DATE_SUCCESS:
      // ここで賞味期限が更新された棚のデータを状態に統合
      return {
        ...state,
        loading: false,
        shelfsData: state.shelfsData.map(shelf =>
          shelf.id === action.payload.id ? action.payload : shelf
        ),
        error: null,
      }
    case actionTypes.UPDATE_EXPIRATION_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case actionTypes.FETCH_PRODUCTS_BY_IPC_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      }
    case actionTypes.FETCH_PRODUCTS_BY_IPC_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_SUCCESS:
      return {
        ...state,
        shelf: action.payload,
        loading: false,
      }
    case actionTypes.FETCH_SHELF_BY_IPC_AND_COLUMN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default shelfsReducer
