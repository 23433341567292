import * as actionTypes from "./actionTypes"

export const fetchSalesAllData = () => {
  return {
    type: actionTypes.FETCH_SALES_ALL_DATA_REQUEST,
  }
}

export const fetchSalesAllDataSuccess = data => {
  return {
    type: actionTypes.FETCH_SALES_ALL_DATA_SUCCESS,
    payload: data,
  }
}

export const fetchSalesAllDataError = error => {
  return {
    type: actionTypes.FETCH_SALES_ALL_DATA_ERROR,
    error,
  }
}
