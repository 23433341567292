import * as types from "./actionTypes"

const initialState = {
  data: null,
  isLoading: false,
  error: null,
}

function announcementReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ANNOUNCEMENT_REQUEST:
      return { ...state, isLoading: true, error: null }
    case types.GET_ANNOUNCEMENT_SUCCESS:
      return { ...state, isLoading: false, data: action.payload }
    case types.GET_ANNOUNCEMENT_ERROR:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

export default announcementReducer
