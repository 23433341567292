import React, { Component } from "react"
import { Bar } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

Chart.register(...registerables)
Chart.register(ChartDataLabels)

class BarChart extends Component {
  handleClick = (event, elements) => {
    if (elements.length === 0) {
      // クリックされた要素がなければ何もしない
      return
    }
    // 最初の要素のインデックスを取得
    const firstElementIndex = elements[0].index

    // 親コンポーネントの関数を呼び出す
    // `this.props.onBarClick` は親コンポーネントから渡される関数を指している
    if (this.props.onBarClick) {
      this.props.onBarClick(firstElementIndex)
    }
  }

  render() {
    if (this.props.data.length === 1) {
      return <div>データ無し</div>
    }

    const { labels, data, last_data, label, last_label } = this.props

    // 定義した曜日に対する色
    const colorMap = {
      "(土)": "rgba(255, 99, 132, 0.2)",
      "(日)": "rgba(255, 99, 132, 0.2)",
      "(祝日)": "rgba(255, 99, 132, 0.2)", // 祝日用の色を追加
      default: "rgba(75, 192, 192, 0.2)",
    }

    // ラベルの曜日に基づいて色を設定
    const backgroundColors = labels.map(label => {
      let dayInfo = label.slice(-3) // ラベルから曜日を抽出
      if (label.includes("(祝日)")) {
        // ラベルに'(祝日)'が含まれているかチェック
        dayInfo = "(祝日)" // 祝日の場合はdayInfoを祝日に設定
      }
      const bgcolor = colorMap[dayInfo] || colorMap["default"] // 曜日に対応する色を返す
      return bgcolor
    })

    const datasets = [
      {
        label: label || "データ",
        data: data,
        type: "bar",
        backgroundColor: backgroundColors,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ]
    if (last_data && last_data.length > 0) {
      datasets.push({
        label: last_label || "データ",
        data: last_data,
        type: "line",
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      })
    }

    const simpleLabels = labels.map(label =>
      label
        .replace(/^0(\d)/g, "$1")
        .replace(/-0(\d)/, "-$1")
        .replace("月", "/")
        .replace(/(\d)日/, "$1 ")
    )

    const barChartData = {
      labels: simpleLabels,
      datasets: datasets,
    }

    const options = {
      plugins: {
        datalabels: {
          display: false,
          color: "#000",
          formatter: function (value, context) {
            return new Intl.NumberFormat().format(value)
          },
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          beginAtZero: true,
          afterDataLimits: axis => {
            const max = axis.max
            axis.max = 5 * (Math.floor(max / 5) + 1) // ここで計算式を適用
          },
          ticks: {
            stepSize: 1, // Y軸の間隔を1に設定
            callback: function (value, index, ticks) {
              return Number.isInteger(value) ? value : null
            },
          },
        },
        x: {
          display: true, // This hides the labels.
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const value = tooltipItem.yLabel
            return new Intl.NumberFormat().format(value)
          },
        },
      },
      onClick: this.handleClick,
    }

    const chartStyle = {
      height: "300px",
    }

    return (
      <React.Fragment>
        <div style={chartStyle}>
          <Bar data={barChartData} options={options} />
        </div>
      </React.Fragment>
    )
  }
}

export default BarChart
