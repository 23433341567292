import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postJwtLogin } from "../../../helpers/backend_helper"

import { axiosApi } from "../../../helpers/api_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        username: user.userid,
        password: user.password,
      })
      const jsonObject = {
        name: response.user.username,
        level: response.user.level,
        email: response.user.email,
        company: response.user.company,
      }

      const jsonString = JSON.stringify(jsonObject)

      localStorage.setItem("authUser", jsonString)

      yield put(loginSuccess(response.user, response.token))
    }
    history("/dashboard")
  } catch (error) {
    console.log("loginUser error", error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("selectedLoc")

    // axiosApi の Authorization ヘッダを削除
    delete axiosApi.defaults.headers.common["Authorization"]

    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
