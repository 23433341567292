// sagas.js
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as api from "../../helpers/backend_helper"; // API関数をインポート

function* fetchLocationGroupsSaga() {
  try {
    const groups = yield call(api.fetchLocationGroups);
    yield put(actions.fetchLocationGroupsSuccess(groups));
  } catch (error) {
    yield put(actions.fetchLocationGroupsError(error));
  }
}

function* updateLocationGroupSaga(action) {
  try {
    const { groupId, groupData } = action.payload;
    const updatedGroup = yield call(api.updateLocationGroup, groupId, groupData);
    yield put(actions.updateLocationGroupSuccess(updatedGroup));
    // 必要に応じて他のアクションを実行
  } catch (error) {
    yield put(actions.updateLocationGroupError(error));
  }
}

function* deleteLocationGroupSaga(action) {
  try {
    const groupId = action.payload;
    yield call(api.deleteLocationGroup, groupId);
    yield put(actions.deleteLocationGroupSuccess(groupId));
    // 必要に応じて他のアクションを実行
  } catch (error) {
    yield put(actions.deleteLocationGroupError(error));
  }
}

export default function* locationGroupsSaga() {
  yield takeEvery(actionTypes.FETCH_LOCATION_GROUPS, fetchLocationGroupsSaga);
  yield takeEvery(actionTypes.UPDATE_LOCATION_GROUP, updateLocationGroupSaga);
  yield takeEvery(actionTypes.DELETE_LOCATION_GROUP, deleteLocationGroupSaga);
}
