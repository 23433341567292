// api.js
import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const getVehicles = async () => {
  const response = await get(url.GET_VEHICLES)
  return response.results // 応答からデータ部分のみを返す
}

const postVehicle = async vehicleData => {
  const response = await post(url.POST_VEHICLE, vehicleData)
  return response.results // 新しく作成された倉庫のデータを返す
}

const putVehicle = async vehicleData => {
  const vehicleId = vehicleData.vehicle_id // 更新する倉庫のIDを取得
  const response = await put(`${url.PUT_VEHICLE}/${vehicleId}`, vehicleData)
  return response.results // 更新された倉庫のデータを返す
}

const deleteVehicle = async vehicleData => {
  const vehicleId = vehicleData
  const response = await del(`${url.DELETE_VEHICLE}/${vehicleId}`, vehicleData)
  return response.results // 更新された倉庫のデータを返す
}

// 倉庫在庫の取得
const getVehicleStock = async vehicleId => {
  const response = await get(`${url.GET_VEHICLES}/${vehicleId}`)
  return response.results // 応答から在庫データ部分のみを返す
}

// 倉庫在庫の追加
const postVehicleStock = async vehicleData => {
  const response = await post(
    `${url.POST_VEHICLE}/${vehicleData.delivery_vehicle_id}/${vehicleData.goods_id}/${vehicleData.stock_count}`
  )
  return response.results // 新しく追加された在庫データを返す
}

// 倉庫在庫の更新
const putVehicleStock = async vehicleId => {
  const response = await put(
    `${url.PUT_VEHICLE}/${vehicleId.vehicle_id}/${vehicleId.vehicle_stock_id}`,
    vehicleId
  )
  return response.results // 更新された在庫データを返す
}

// 倉庫在庫の削除
const delVehicleStock = async vehiclekData => {
  const response = await del(
    `${url.DELETE_VEHICLE}/${vehiclekData.vehicleId}/${vehiclekData.vehicleStockId}`
  )
  return response.results // 削除された在庫データを返す
}

export {
  getVehicles,
  postVehicle,
  putVehicle,
  deleteVehicle,
  getVehicleStock,
  postVehicleStock,
  putVehicleStock,
  delVehicleStock,
}
