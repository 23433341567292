import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-4.jpg"
// actions
import {
  editProfile,
  resetProfileFlag,
  changePassword,
} from "../../store/auth/profile/actions"

import LoadingOverlay from "../Common/LoadingOverlay"
import ErrorMessage from "../Common/ErrorMessage"

const UserProfile = props => {
  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const isLoading = useSelector(state => state.profileReducer.loading)
  const message = useSelector(state => state.profileReducer.message)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid || 1)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.name)
        setemail(obj.email)
        setidx(obj.uid || 1)
      }
      setTimeout(() => {
        props.resetProfileFlag()
      }, 3000)
    }
  }, [props.success])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || "",
      idx: idx || "",
      newPassword: "",
      confirmPassword: "",
      email: email || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("ユーザー名を入力してください"),
      email: Yup.string().email("Invalid email address"),
      newPassword: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "パスワードが同じではありません"
      ),
    }),
  })

  const handleUpdate = async () => {
    // Formikのバリデーションを手動で実行
    const errors = await validation.validateForm(validation.values)

    // エラーオブジェクトが空かどうかをチェック
    if (Object.keys(errors).length === 0) {
      // エラーがなければ、dispatchを呼び出す
      dispatch(editProfile(validation.values))
    } else {
      // エラーがあれば、Formikの状態を更新してエラーを表示
      validation.setErrors(errors)
      validation.setTouched({
        username: true,
        email: true,
        newPassword: true,
        confirmPassword: true,
      })
    }
  }

  useEffect(() => {
    if (message) {
      alert(message) // ここではシンプルなalertダイアログを使用
    }
  }, [message])

  document.title = "プロファイル"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="main" breadcrumbItem="Profile" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">ID : #{idx}</p>
                      </div>
                    </div>
                  </div>
                  {/* LINEの友達追加ボタンを追加 */}
                  <div className="mt-3">
                    <a
                      href="https://lin.ee/jX074kO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                        alt="友だち追加"
                        height="36"
                        style={{ border: 0 }}
                      />
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit(e)
                }}
              >
                <div className="form-group">
                  <Label className="form-label">ユーザー名</Label>
                  <Input
                    name="username"
                    className="form-control"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group">
                  <Label className="form-label">メールアドレス</Label>
                  <Input
                    name="email"
                    className="form-control"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">新パスワード</Label>
                  <Input
                    name="newPassword"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPassword || ""}
                    invalid={
                      validation.touched.newPassword &&
                      validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPassword &&
                  validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">新パスワード確認用</Label>
                  <Input
                    name="confirmPassword"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.confirmPassword || ""}
                    invalid={
                      validation.touched.confirmPassword &&
                      validation.errors.confirmPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.confirmPassword &&
                  validation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary ml-2 flex-shrink-0"
                    onClick={handleUpdate}
                  >
                    更新
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  changePassword: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  // state.Profileがundefinedの場合は、デフォルトの値を使用
  const { error, success } = state.Profile || { error: null, success: null }
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, changePassword, resetProfileFlag })(
    UserProfile
  )
)
