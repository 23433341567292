import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { SYSTEM_NAME, SYSTEM_VERSION } from "../../config"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

// Redux
import withRouter from "components/Common/withRouter"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// actions
import { apiError, loginUser } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png"

import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Login = props => {
  const dispatch = useDispatch()

  const [userLogin, setUserLogin] = useState([])
  const [showPassword, setShowPassword] = useState(false) // New state for password visibility

  const isLoading = useSelector(state => state.Login.loading)

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        userid: user.userid,
        password: user.password,
      })
    }
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userid: userLogin.userid || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      userid: Yup.string().required("ユーザ名を入力してください"),
      password: Yup.string().required("パスワードを入力してください"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  document.title = "ログイン | J-Prime Cloud"
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <ErrorMessage />
      <LoadingOverlay isLoading={isLoading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">{SYSTEM_NAME}</h5>
                    <p className="text-white-50">Version {SYSTEM_VERSION}</p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          ログインID
                        </Label>
                        <Input
                          name="userid"
                          className="form-control"
                          placeholder="Username"
                          type="text"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userid || ""}
                          invalid={
                            validation.touched.userid &&
                            validation.errors.userid
                              ? true
                              : false
                          }
                        />
                        {validation.touched.userid &&
                        validation.errors.userid ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userid}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          パスワード
                        </Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <i
                                className={`fa ${
                                  showPassword ? "fa-eye" : "fa-eye-slash"
                                }`}
                              />
                            </button>
                          </div>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              記憶する
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            ログイン
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} JiHAN Prime</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
