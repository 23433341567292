export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST"
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS"
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE"

export const REGISTER_COMPANY_REQUEST = "REGISTER_COMPANY_REQUEST"
export const REGISTER_COMPANY_SUCCESS = "REGISTER_COMPANY_SUCCESS"
export const REGISTER_COMPANY_FAILURE = "REGISTER_COMPANY_FAILURE"

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE"

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE"
