import * as types from "./actionTypes"

const initialState = {
  data: null,
  isLoading: false,
  error: null,
}

function locationReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LOCATION_DATA:
      return { ...state, loading: true, error: null };
    case types.UPDATE_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        ipcDetail: action.payload,
        loading: false,
      };
    case types.UPDATE_LOCATION_DATA_ERROR:
      // ロケーションデータの更新に失敗した場合、エラー情報で状態を更新
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.REGISTER_LOCATION_DATA:
      // ロケーションデータの登録リクエスト時の状態更新
      return { ...state, loading: true, error: null };
    case types.REGISTER_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        ipcDetail: action.payload,
        loading: false,
      };
    case types.REGISTER_LOCATION_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.DELETE_LOCATION_DATA:
      // ロケーションデータの削除リクエスト時の状態更新
      return { ...state, loading: true, error: null };
    case types.DELETE_LOCATION_DATA_SUCCESS:
      // ロケーションデータ削除成功時の状態更新
      return {
        ...state,
        ipcDetail: action.payload,
        loading: false,
      };
    case types.DELETE_LOCATION_DATA_ERROR:
      // ロケーションデータ削除エラー時の状態更新
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    // 他の既存のアクションタイプの処理...
    default:
      return state;
  }
}

export default locationReducer;
