import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { use } from "i18next"
import { updateDeliveryPlanRequest } from "../../store/deliveryPlan/actions"

const DeliveryPlanConfirm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { deliveryPlan } = location.state || {}

  const isLoading1 = useSelector(state => state.deliveryPlanReducer.loading)

  const handleConfirm = () => {
    const updatedPlan = {
      ...deliveryPlan,
      status: 2,
      actual_start_date: new Date().toISOString(),
    }

    dispatch(
      updateDeliveryPlanRequest({
        delivery_plan: updatedPlan,
      })
    )

    navigate("/delivery-plan/vehicle-select", {
      state: {
        deliveryPlan: deliveryPlan,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#dcdcdc" }}>
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画確認" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Card className="border p-3">
                <CardBody className="text-center">
                  <CardTitle tag="h4" className="text-center">
                    配送計画詳細
                  </CardTitle>
                  <hr />
                  <div
                    className="border p-3 mb-3"
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          margin: 0,
                        }}
                      >
                        計画No: {deliveryPlan.id}
                      </p>
                      <p style={{ margin: 0 }}>
                        作成時間: {deliveryPlan.created_at}
                      </p>
                    </div>
                  </div>
                  <div className="text-left">
                    <p>担当者: {deliveryPlan.user_name}</p>
                    <CardTitle tag="h5">ルート情報</CardTitle>
                    <p>総距離: {deliveryPlan.locations.total_distance} km</p>
                    <p>総時間: {deliveryPlan.locations.total_duration} 分</p>

                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {deliveryPlan.locations.waypoints.map(
                        (waypoint, index) => (
                          <li key={index}>
                            {String.fromCharCode(65 + index)}: {waypoint.name}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="text-center mt-4">
                    <p>補充計画を実行しますか？</p>
                    <div>
                      <Button
                        color="primary"
                        onClick={handleConfirm}
                        className="mr-2"
                        style={{ width: "100px" }}
                      >
                        はい
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => navigate(-1)}
                        style={{ width: "100px" }}
                      >
                        いいえ
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanConfirm
