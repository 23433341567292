import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_CAMERAS_DATA,
  UPDATE_CAMERAS,
  REMOVE_IPC_FROM_CAMERA,
} from "./actionTypes"
import {
  fetchCamerasDataSuccess,
  fetchCamerasDataError,
  updateCamerasSuccess,
  updateCamerasError,
  removeIpcFromCameraSuccess,
  removeIpcFromCameraError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCameras,
  putCameras,
  deleteCameras,
} from "../../helpers/backend_helper"

function* fetchCamerasDataSaga(action) {
  try {
    const graphData = yield call(getCameras)
    yield put(fetchCamerasDataSuccess(graphData))
  } catch (error) {
    yield put(fetchCamerasDataError(error))
  }
}

function* updateCamerasSaga(action) {
  try {
    const {
      cameraId,
      uuid,
      locId,
      startAt,
      endAt,
      note,
      deleted,
      deviceNo,
      hostname,
      ipcSerialNo,
      simNo,
      iccId,
      allxonLicNo,
      ipcOwner,
      cameraOwner,
      cameraOperator,
      ipAddr,
    } = action.payload

    const camerasData = yield call(
      putCameras,
      cameraId,
      uuid,
      locId,
      startAt,
      endAt,
      note,
      deleted,
      deviceNo,
      hostname,
      ipcSerialNo,
      simNo,
      iccId,
      allxonLicNo,
      ipcOwner,
      cameraOwner,
      cameraOperator,
      ipAddr
    )

    yield put(updateCamerasSuccess(camerasData))
    yield put(fetchCamerasDataSuccess(camerasData))
  } catch (error) {
    yield put(updateCamerasError(error))
  }
}

function* removeIpcFromCameraSaga(action) {
  try {
    const { userId, locId } = action.payload
    const camerasData = yield call(deleteCameras, userId, locId)
    yield put(removeIpcFromCameraSuccess(camerasData))

    yield put(fetchCamerasDataSuccess(camerasData))
  } catch (error) {
    yield put(removeIpcFromCameraError(error))
  }
}

function* CamerasSaga() {
  yield takeEvery(FETCH_CAMERAS_DATA, fetchCamerasDataSaga)
  yield takeEvery(UPDATE_CAMERAS, updateCamerasSaga)
  yield takeEvery(REMOVE_IPC_FROM_CAMERA, removeIpcFromCameraSaga)
}

export default CamerasSaga
