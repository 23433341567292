import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_SALES_GRAPH_DATA, UPDATE_STOCK_ITEM } from "./actionTypes"
import {
  fetchGraphDataSuccess,
  fetchGraphDataError,
  updateStockItemSuccess,
  updateStockItemError,
} from "./actions"

//Include Both Helper File with needed methods
import { getSales, getIpcs } from "../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const {
      goodsBrand,
      locId,
      timePeriod,
      currentDate,
      previousDate,
      pageNumber,
      itemsCountPerPage,
      startDate,
      endDate,
    } = action.payload
    const graphData = yield call(
      getSales,
      goodsBrand,
      locId,
      timePeriod,
      currentDate,
      previousDate,
      pageNumber,
      itemsCountPerPage,
      startDate,
      endDate,
    )
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* SalesSaga() {
  yield takeEvery(FETCH_SALES_GRAPH_DATA, fetchGraphDataSaga)
}

export default SalesSaga
