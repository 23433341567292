// api_helpers/fetchShelfApi.js

import { get, put } from "../api_helper"
import * as url from "../url_helper"

// 棚情報取得用API
const getShelfs = async (loc_id, column_no) => {
  try {
    const queryParams = new URLSearchParams()
    queryParams.append("loc_id", loc_id)
    queryParams.append("column_no", column_no)

    const response = await get(`${url.GET_SHELFS}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

const putShelfs = async shelfUpdates => {
  try {
    const endpoint = url.PUT_SHELFS

    // 辞書形式のペイロードを生成
    const updates = {}
    shelfUpdates.forEach((update, index) => {
      updates[index] = {
        shelf_id: update.shelf_id,
        end_date: update.end_date,
        expiry_date: update.expiry_date,
        ipc_no: update.ipc_no,
        loc_id: update.loc_id,
        column_no: update.column_no,
      }
    })

    const response = await put(endpoint, { updates })

    return { data: response }
  } catch (error) {
    console.error("棚データの更新に失敗しました: ", error)
    throw error
  }
}

export { getShelfs, putShelfs }
