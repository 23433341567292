import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { clearError } from "../../store/actions"

function ErrorMessage({ error, clearError }) {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setShowError(!!error)
  }, [error])

  const closeError = () => {
    setShowError(false)
    clearError() // エラーをクリアするアクションを呼び出す
  }

  if (!showError) {
    return null
  }

  const errorStyle = {
    color: "red",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "999",
    backgroundColor: "#fff",
    padding: "20px",
    border: "2px solid #ccc",
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.75)",
    cursor: "pointer", // 追加：エラーメッセージ全体をクリック可能に
  }

  if (!error) {
    return null
  }

  return (
    <div className="error-message" style={errorStyle} onClick={closeError}>
      {error.response && error.response.data.message
        ? error.response.data.message
        : "サーバーからの応答がありません"}
    </div>
  )
}

const mapStateToProps = state => ({
  error: state.common.error, // 共通のエラー状態を参照
})

const mapDispatchToProps = {
  clearError, // clearErrorアクションを接続
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage)
