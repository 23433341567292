import * as actionTypes from "./actionTypes"

export const fetchCustomersDataRequest = filters => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_REQUEST,
    payload: {
      genderFilter: filters.genderFilter,
      ageRangeFilter: filters.ageRangeFilter,
      startDate: filters.startDate,
      endDate: filters.endDate,
      freeWordSearch: filters.freeWordSearch,
      verifyFilter: filters.verifyFilter,
      locId: filters.locId,
      pageNumber: filters.currentPage, // 追加
      pageSize: filters.itemsPerPage, // 追加
    },
  }
}

export const fetchCustomersDataSuccess = data => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_SUCCESS,
    payload: data,
  }
}

export const fetchCustomersDataError = error => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_ERROR,
    error,
  }
}

// 新しいアクション: 顧客データの更新
export const updateCustomerDataRequest = (
  index,
  customer,
  genderFilter,
  ageRangeFilter,
  startDate,
  endDate,
  freeWordSearch,
  locId
) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_DATA_REQUEST,
    payload: {
      index,
      customer,
      genderFilter,
      ageRangeFilter,
      startDate,
      endDate,
      freeWordSearch,
      locId,
    },
  }
}

export const updateCustomerDataSuccess = (index, customer) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_DATA_SUCCESS,
    payload: { index, customer },
  }
}

export const updateCustomerDataError = error => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_DATA_ERROR,
    error,
  }
}

export const fetchCustomersDataRequest2 = filters => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_REQUEST_2,
    payload: {
      locId: filters.locId,
      pageNumber: filters.currentPage,
      pageSize: filters.itemsPerPage,
      startDate: filters.startDate,
      endDate: filters.endDate,
    },
  }
}

export const fetchCustomersDataSuccess2 = data => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_SUCCESS_2,
    payload: data,
  }
}

export const fetchCustomersDataError2 = error => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_DATA_ERROR_2,
    error,
  }
}
