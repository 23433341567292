import * as types from "./actionTypes"

export function getAnnouncementRequest() {
  return { type: types.GET_ANNOUNCEMENT_REQUEST }
}

export function getAnnouncementSuccess(data) {
  return { type: types.GET_ANNOUNCEMENT_SUCCESS, payload: data }
}

export function getAnnouncementError(error) {
  return { type: types.GET_ANNOUNCEMENT_ERROR, payload: error }
}
