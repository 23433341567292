export * from "./layout/actions"

// Authentication module
export * from "./auth/login/actions"

export const PUT_ERROR = "PUT_ERROR"

export const CLEAR_ERROR = "CLEAR_ERROR"

export const putError = error => ({
  type: PUT_ERROR,
  payload: error,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})
