import React from "react"

const LoadingOverlay = ({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        <div style={{ color: "white", fontSize: "2em" }}>Loading...</div>
      </div>
    )
  )
}

export default LoadingOverlay
