import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  warehouses: [],
  error: null,
}

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WAREHOUSES:
    case actionTypes.ADD_WAREHOUSE:
    case actionTypes.UPDATE_WAREHOUSE:
    case actionTypes.DELETE_WAREHOUSE:
    case actionTypes.FETCH_WAREHOUSE_STOCK: // 追加
    case actionTypes.ADD_WAREHOUSE_STOCK: // 追加
    case actionTypes.UPDATE_WAREHOUSE_STOCK: // 追加
    case actionTypes.DELETE_WAREHOUSE_STOCK: // 追加
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        warehouses: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.FETCH_WAREHOUSES_ERROR:
    case actionTypes.ADD_WAREHOUSE_ERROR:
    case actionTypes.UPDATE_WAREHOUSE_ERROR:
    case actionTypes.DELETE_WAREHOUSE_ERROR:
    case actionTypes.FETCH_WAREHOUSE_STOCK_ERROR: // 追加
    case actionTypes.ADD_WAREHOUSE_STOCK_ERROR: // 追加
    case actionTypes.UPDATE_WAREHOUSE_STOCK_ERROR: // 追加
    case actionTypes.DELETE_WAREHOUSE_STOCK_ERROR: // 追加
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.ADD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouses: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.UPDATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouses: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouses: state.warehouses.filter(
          warehouse => warehouse.id !== action.payload
        ),
        loading: false,
        error: null,
      }
    case actionTypes.FETCH_WAREHOUSE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        warehouseStocks: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.ADD_WAREHOUSE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        warehouseStocks: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.UPDATE_WAREHOUSE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        warehouseStocks: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.DELETE_WAREHOUSE_STOCK_SUCCESS: // 追加
      return {
        ...state,
        warehouseStocks: action.payload,
        loading: false,
        error: null,
      }
    default:
      return state
  }
}

export default warehouseReducer
