export const FETCH_CUSTOMERS_DATA_REQUEST = "FETCH_CUSTOMERS_DATA_REQUEST"
export const FETCH_CUSTOMERS_DATA_SUCCESS = "FETCH_CUSTOMERS_DATA_SUCCESS"
export const FETCH_CUSTOMERS_DATA_ERROR = "PUT_ERROR"

export const FETCH_CUSTOMERS_DATA_REQUEST_2 = "FETCH_CUSTOMERS_DATA_REQUEST_2"
export const FETCH_CUSTOMERS_DATA_SUCCESS_2 = "FETCH_CUSTOMERS_DATA_SUCCESS_2"
export const FETCH_CUSTOMERS_DATA_ERROR_2 = "PUT_ERROR"

// 新しいアクションタイプ: 顧客データの更新
export const UPDATE_CUSTOMER_DATA_REQUEST = "UPDATE_CUSTOMER_DATA_REQUEST"
export const UPDATE_CUSTOMER_DATA_SUCCESS = "UPDATE_CUSTOMER_DATA_SUCCESS"
export const UPDATE_CUSTOMER_DATA_ERROR = "PUT_ERROR"
