import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { useDispatch, useSelector } from "react-redux"

const DeliveryPlanComplete = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { deliveryPlan } = location.state || {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画" />
          <ErrorMessage />
          <LoadingOverlay isLoading={false} />
          <Row>
            <Col xs={12}>
              <Card className="border">
                <CardBody
                  style={{ textAlign: "center", backgroundColor: "#fff" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                      flexDirection: "row",
                    }}
                  >
                    <h2 style={{ margin: 0 }}>計画NO: {deliveryPlan.id}</h2>
                    <p style={{ margin: 0 }}>
                      作成時刻: {deliveryPlan.created_at}
                    </p>
                  </div>
                  <div style={{ marginBottom: "0px", backgroundColor: "#fff" }}>
                    <p style={{ margin: "0px" }}>
                      開始時刻: {deliveryPlan.start_date}
                    </p>
                    <p style={{ margin: "0px" }}>
                      終了時刻: {deliveryPlan.end_date}
                    </p>
                    <p style={{ margin: "0px" }}>
                      実施者: {deliveryPlan.user_name}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>補充が完了しました</p>
            <p>お疲れ様でした。</p>
            <Button color="primary" onClick={() => navigate("/")}>
              TOPへ戻る
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanComplete
