import {
  FETCH_SOLD_OUT_REQUEST,
  FETCH_SOLD_OUT_SUCCESS,
  FETCH_SOLD_OUT_ERROR,
} from "./actionTypes"

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const soldOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOLD_OUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_SOLD_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        route: action.payload.route,
        soldOutData: action.payload.soldOutData,
      }
    case FETCH_SOLD_OUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default soldOutReducer
