// goodsApi.js
import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const getGoods = async () => {
  const response = await get(url.GET_GOODS)
  return { data: response }
}

const postGoods = async goodsData => {
  const response = await post(url.POST_GOOD, goodsData)
  return { data: response }
}

const putGoods = async goodsData => {
  const goodsId = goodsData.goods_id // 更新する商品のIDを取得
  const response = await put(`${url.PUT_GOOD}/${goodsId}`, goodsData)
  return { data: response }
}

const deleteGoods = async goodsData => {
  const goodsId = goodsData.id
  const response = await del(`${url.DELETE_GOOD}/${goodsId}`)
  return { data: response }
}

const registerSurpriseApi = async surpriseData => {
  const response = await put(
    `${url.PUT_GOOD}/${surpriseData.goodsId}/${surpriseData.surpriseItemId}`
  )
  return { data: response }
}

const unregisterSurpriseApi = async surpriseData => {
  const response = await del(
    `${url.DELETE_GOOD}/${surpriseData.goodsId}/${surpriseData.surpriseItemId}`
  )
  return { data: response }
}

const fetchGoodsDetailApi = async goodsId => {
  const response = await get(`${url.GET_GOODS_DETAIL}/${goodsId}`)
  return { data: response }
}

export {
  getGoods,
  postGoods,
  putGoods,
  deleteGoods,
  registerSurpriseApi,
  unregisterSurpriseApi,
  fetchGoodsDetailApi,
}
