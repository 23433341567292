export const FETCH_GOODS_BRAND_GRAPH_DATA = "FETCH_GOODS_BRAND_GRAPH_DATA"
export const FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS =
  "FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS"
export const FETCH_GOODS_BRAND_GRAPH_DATA_ERROR =
  "FETCH_GOODS_BRAND_GRAPH_DATA_ERROR"
export const FETCH_GOODS_BRAND_REG_DATA = "FETCH_GOODS_BRAND_REG_DATA"
export const FETCH_GOODS_BRAND_REG_DATA_SUCCESS =
  "FETCH_GOODS_BRAND_REG_DATA_SUCCESS"
export const FETCH_GOODS_BRAND_REG_DATA_ERROR =
  "FETCH_GOODS_BRAND_REG_DATA_ERROR"
export const UPDATE_GOODS = "UPDATE_GOODS"
