import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_SALES_ALL_DATA_REQUEST } from "./actionTypes"
import { fetchSalesAllDataSuccess, fetchSalesAllDataError } from "./actions"
import { getSalesAllData } from "../../helpers/backend_helper"

function* fetchSalesAllDataSaga(action) {
  try {
    const salesAllData = yield call(getSalesAllData)
    const results = salesAllData.data.results
    yield put(fetchSalesAllDataSuccess(results))
  } catch (error) {
    yield put(fetchSalesAllDataError(error))
  }
}

function* SalesAllSaga() {
  yield takeEvery(FETCH_SALES_ALL_DATA_REQUEST, fetchSalesAllDataSaga)
}

export default SalesAllSaga
