import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

const handleAddNewItem = () => {
  if (newItem.isSurprise && newItem.additionalItemIds.length > 0) {
    // サプライズ商品の追加処理
    // 各商品IDに対して処理を実行するロジックをここに実装
  }
  // 商品追加のロジックをここに実装
  toggleAddItemModal()
}

const ItemStatusModal = ({
  isOpen,
  toggle,
  itemStatuses,
  updateItemStatus,
}) => {
  // ステータス選択肢を返す関数
  const getStatusOptions = itemClass => {
    switch (itemClass) {
      case 1: // 仕入れ
        return ["仕入れ中", "仕入れ済", "仕入れキャンセル"]
      case 2: // 戻し
        return ["戻し中", "戻し済み", "戻しキャンセル"]
      case 3: // 補充
        return ["補充中", "補充済み", "補充キャンセル"]
      default:
        return []
    }
  }

  // ステータス変更時の処理
  const handleChangeStatus = (itemId, newStatus) => {
    updateItemStatus(itemId, newStatus) // 親コンポーネントの関数を呼び出し
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>商品ステータス情報</ModalHeader>
      <ModalBody>
        {itemStatuses && itemStatuses.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>商品ID</th>
                <th>ステータス</th>
                <th>数量</th>
                <th>日付</th>
                <th>詳細</th>
              </tr>
            </thead>
            <tbody>
              {itemStatuses.map((status, index) => (
                <tr key={index}>
                  <td>{status.itemName}</td>
                  <td>
                    <Input
                      type="select"
                      value={status.status}
                      onChange={e =>
                        handleChangeStatus(status.itemId, e.target.value)
                      }
                    >
                      {getStatusOptions(status.class).map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </td>
                  <td>{status.stock_count}</td>
                  <td>{status.date}</td>
                  <td>{status.details}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>商品情報がありません。</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ItemStatusModal
