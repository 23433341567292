import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import FlowsSaga from "./flows/saga"
import MarketingCommonSaga from "./marketing/common/saga"
import MarketingVolumeSaga from "./marketing/volume/saga"
import MarketingCustomerSaga from "./marketing/customer/saga"
import MarketingTimeSaga from "./marketing/time/saga"
import BrandsSaga from "./brands/saga"
import UsersSaga from "./users/saga"
import CamerasSaga from "./cameras/saga"
import StocksSaga from "./stocks/saga"
import SalesDailySaga from "./salesDaily/saga"
import SalesSaga from "./sales/saga"
import SalesAllSaga from "./salesAll/saga"
import SalesLocSaga from "./salesLoc/saga"
import CustomersSaga from "./customers/saga"
import AnnouncementSaga from "./announce/saga"
import companySaga from "./companies/saga"
import ShelfsSaga from "./shelfs/saga"
import LocationSaga from "./location/saga"
import locationGroupsSaga from "./locationGroup/saga"
import soldOutSaga from "./soldout/saga"
import ProfileSaga from "./auth/profile/saga"
import warehouseSaga from "./warehouse/saga"
import GoodsSaga from "./goods/saga"
import vehicleSaga from "./vehicle/saga"
import deliveryPlanSaga from "./deliveryPlan/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(FlowsSaga),
    fork(MarketingCommonSaga),
    fork(MarketingVolumeSaga),
    fork(MarketingCustomerSaga),
    fork(MarketingTimeSaga),
    fork(BrandsSaga),
    fork(UsersSaga),
    fork(CamerasSaga),
    fork(StocksSaga),
    fork(SalesDailySaga),
    fork(SalesSaga),
    fork(SalesAllSaga),
    fork(SalesLocSaga),
    fork(CustomersSaga),
    fork(AnnouncementSaga),
    fork(companySaga),
    fork(ShelfsSaga),
    fork(LocationSaga),
    fork(locationGroupsSaga),
    fork(soldOutSaga),
    fork(ProfileSaga),
    fork(warehouseSaga),
    fork(GoodsSaga),
    fork(vehicleSaga),
    fork(deliveryPlanSaga),
  ])
}
