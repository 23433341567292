import {
  FETCH_CAMERAS_DATA,
  FETCH_CAMERAS_DATA_SUCCESS,
  FETCH_CAMERAS_DATA_ERROR,
  UPDATE_CAMERAS,
  UPDATE_CAMERAS_SUCCESS,
  UPDATE_CAMERAS_ERROR,
  REMOVE_IPC_FROM_CAMERA,
  REMOVE_IPC_FROM_CAMERA_SUCCESS,
  REMOVE_IPC_FROM_CAMERA_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchCamerasData = () => {
  return {
    type: FETCH_CAMERAS_DATA,
    payload: {},
  }
}

// Fetch graph data success action
export const fetchCamerasDataSuccess = payload => {
  return {
    type: FETCH_CAMERAS_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchCamerasDataError = error => {
  return {
    type: FETCH_CAMERAS_DATA_ERROR,
    error,
  }
}

export const updateCameras = updatedData => {
  return {
    type: UPDATE_CAMERAS,
    payload: {
      cameraId: updatedData.camera_id,
      uuid: updatedData.uuid,
      locId: updatedData.loc_id,
      startAt: updatedData.start_at,
      endAt: updatedData.end_at,
      note: updatedData.note,
      deleted: updatedData.deleted,
      deviceNo: updatedData.device_no,
      hostname: updatedData.hostname,
      ipcSerialNo: updatedData.ipc_serial_no,
      simNo: updatedData.sim_no,
      iccId: updatedData.icc_id,
      allxonLicNo: updatedData.allxon_lic_no,
      ipcOwner: updatedData.ipc_owner,
      cameraOwner: updatedData.camera_owner,
      cameraOperator: updatedData.camera_operator,
      ipAddr: updatedData.ip_addr,
    },
  }
}

// Fetch graph data success action
export const updateCamerasSuccess = payload => {
  return {
    type: UPDATE_CAMERAS_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const updateCamerasError = error => {
  return {
    type: UPDATE_CAMERAS_ERROR,
    error,
  }
}

export const removeIpcFromCamera = (userId, ipcNo) => {
  return {
    type: REMOVE_IPC_FROM_CAMERA,
    payload: {
      userId,
      ipcNo,
    },
  }
}

// Fetch graph data success action
export const removeIpcFromCameraSuccess = payload => {
  return {
    type: REMOVE_IPC_FROM_CAMERA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const removeIpcFromCameraError = error => {
  return {
    type: REMOVE_IPC_FROM_CAMERA_ERROR,
    error,
  }
}
