// actions.js
import {
  FETCH_WAREHOUSES,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSES_ERROR,
  ADD_WAREHOUSE,
  ADD_WAREHOUSE_SUCCESS,
  ADD_WAREHOUSE_ERROR,
  UPDATE_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_ERROR,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_ERROR,
  FETCH_WAREHOUSE_STOCK,
  FETCH_WAREHOUSE_STOCK_SUCCESS,
  FETCH_WAREHOUSE_STOCK_ERROR,
  ADD_WAREHOUSE_STOCK,
  ADD_WAREHOUSE_STOCK_SUCCESS,
  ADD_WAREHOUSE_STOCK_ERROR,
  UPDATE_WAREHOUSE_STOCK,
  UPDATE_WAREHOUSE_STOCK_SUCCESS,
  UPDATE_WAREHOUSE_STOCK_ERROR,
  DELETE_WAREHOUSE_STOCK,
  DELETE_WAREHOUSE_STOCK_SUCCESS,
  DELETE_WAREHOUSE_STOCK_ERROR,
} from "./actionTypes"

export const fetchWarehouses = () => ({
  type: FETCH_WAREHOUSES,
})

export const fetchWarehousesSuccess = warehouses => ({
  type: FETCH_WAREHOUSES_SUCCESS,
  payload: warehouses,
})

export const fetchWarehousesError = error => ({
  type: FETCH_WAREHOUSES_ERROR,
  error,
})

export const addWarehouse = warehouse => ({
  type: ADD_WAREHOUSE,
  payload: warehouse,
})

export const addWarehouseSuccess = warehouse => ({
  type: ADD_WAREHOUSE_SUCCESS,
  payload: warehouse,
})

export const addWarehouseError = error => ({
  type: ADD_WAREHOUSE_ERROR,
  error,
})

export const updateWarehouse = warehouse => ({
  type: UPDATE_WAREHOUSE,
  payload: warehouse,
})

export const updateWarehouseSuccess = warehouse => ({
  type: UPDATE_WAREHOUSE_SUCCESS,
  payload: warehouse,
})

export const updateWarehouseError = error => ({
  type: UPDATE_WAREHOUSE_ERROR,
  error,
})

export const deleteWarehouse = warehouseId => ({
  type: DELETE_WAREHOUSE,
  payload: warehouseId,
})

export const deleteWarehouseSuccess = warehouseId => ({
  type: DELETE_WAREHOUSE_SUCCESS,
  payload: warehouseId,
})

export const deleteWarehouseError = error => ({
  type: DELETE_WAREHOUSE_ERROR,
  error,
})

// 倉庫在庫の取得
export const fetchWarehouseStock = warehouseId => ({
  type: FETCH_WAREHOUSE_STOCK,
  payload: warehouseId,
})

export const fetchWarehouseStockSuccess = stock => ({
  type: FETCH_WAREHOUSE_STOCK_SUCCESS,
  payload: stock,
})

export const fetchWarehouseStockError = error => ({
  type: FETCH_WAREHOUSE_STOCK_ERROR,
  error,
})

// 倉庫在庫の追加
export const addWarehouseStock = (warehouseId, goodsIds) => ({
  type: ADD_WAREHOUSE_STOCK,
  payload: { warehouseId, goodsIds },
})

export const addWarehouseStockSuccess = stock => ({
  type: ADD_WAREHOUSE_STOCK_SUCCESS,
  payload: stock,
})

export const addWarehouseStockError = error => ({
  type: ADD_WAREHOUSE_STOCK_ERROR,
  error,
})

// 倉庫在庫の更新
export const updateWarehouseStock = (warehouseId, goodsIds) => ({
  type: UPDATE_WAREHOUSE_STOCK,
  payload: { warehouseId, goodsIds },
})

export const updateWarehouseStockSuccess = stock => ({
  type: UPDATE_WAREHOUSE_STOCK_SUCCESS,
  payload: stock,
})

export const updateWarehouseStockError = error => ({
  type: UPDATE_WAREHOUSE_STOCK_ERROR,
  error,
})

// 倉庫在庫の削除
export const deleteWarehouseStock = (warehouseStockId, warehouseId) => ({
  type: DELETE_WAREHOUSE_STOCK,
  payload: { warehouseStockId, warehouseId },
})

export const deleteWarehouseStockSuccess = stock => ({
  type: DELETE_WAREHOUSE_STOCK_SUCCESS,
  payload: stock,
})

export const deleteWarehouseStockError = error => ({
  type: DELETE_WAREHOUSE_STOCK_ERROR,
  error,
})
