import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { fetchCustomersDataRequest2 } from "../../store/customers/actions"
import CommonComponent from "../CommonComponent"
import Pagination from "react-js-pagination"
import { format, subDays } from "date-fns"
import { ja } from "date-fns/locale"

const Customers = () => {
  const customersData = useSelector(
    state => state.customersReducer.customersData
  )
  const isLoading = useSelector(state => state.customersReducer.loading)
  const dispatch = useDispatch()
  const [vendor_id, setVendor] = useState(null)
  const [locId, setLoc] = useState("")
  const [currentDate, setCurrentDate] = useState(new Date())

  // ページング機能
  const totalItems = useSelector(state => state.customersReducer.totalItems) // サーバーから取得
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const [ipcFilter, setIpcFilter] = useState("") // IPC番号のフィルター

  // 開始日と終了日のステートを日本語形式でセットする
  const [startDate, setStartDate] = useState("") // 開始日
  const [endDate, setEndDate] = useState("") // 終了日

  useEffect(() => {
    if (locId !== "") {
      dispatch(
        fetchCustomersDataRequest2({
          locId,
          currentPage,
          itemsPerPage,
          startDate,
          endDate,
        })
      )
    }
  }, [locId, currentPage, itemsPerPage, startDate, endDate])

  const displayItems = customersData // キー名が一致するか確認してください

  const getPageNumbers = () => {
    let pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pages.push(i)
      } else if (i === currentPage - 3 || i === currentPage + 3) {
        pages.push("...")
      }
    }
    return pages
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="売上別顧客属性" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-light mb-4">
                <LoadingOverlay isLoading={isLoading} />
                <CardBody>
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    locId={locId}
                    setLoc={setLoc}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    onlyCamera={true}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                  />
                </CardBody>
                <FormGroup row>
                  <Col md={6}>
                    <label for="startDate">開始日：</label>
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col md={6}>
                    <label for="endDate">終了日：</label>
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={selectedPage => setCurrentPage(selectedPage)}
            itemClass="page-item"
            linkClass="page-link"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>売上ID</th>
                        <th>IPC番号</th>
                        <th>IPC名</th>
                        <th>売上日時</th>
                        <th>商品名</th>
                        <th>価格</th>
                        <th>属性</th>
                        <th>年代</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayItems &&
                        displayItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.sales_id}</td>
                            <td>{item.ipc_number}</td>
                            <td>{item.ipc_name}</td>
                            <td>{item.sales_date}</td>
                            <td>{item.goods_name}</td>
                            <td>{item.price}</td>
                            <td>{item.attribute}</td>
                            <td>{item.age_range}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
                <div className="pagination">
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    前のページ
                  </button>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    次のページ
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Customers
