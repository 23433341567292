import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchDeliveryPlansRequest } from "../../store/deliveryPlan/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const DeliveryPlanSelect = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLoadingPlans = useSelector(state => state.deliveryPlanReducer.loading)
  const deliveryPlans =
    useSelector(state => state.deliveryPlanReducer.deliveryPlans) || []

  useEffect(() => {
    dispatch(fetchDeliveryPlansRequest({ type: 2 }))
  }, [dispatch])

  const handleSelectPlan = data => {
    let plan = data
    let path
    switch (plan.status) {
      case 1:
        path = "/delivery-plan/confirm"
        navigate(path, {
          state: {
            startLocationName: data.start_warehouse_name,
            deliveryPlan: data,
            history: null,
          },
        })
        break
      case 2:
        path = "/delivery-plan/vehicle-select"
        navigate(path, {
          state: {
            startLocationName: data.start_warehouse_name,
            deliveryPlan: data,
            history: null,
          },
        })
        break
      case 3:
      case 4:
        path = "/delivery-plan/execute"
        navigate(path, {
          state: {
            deliveryPlan: data,
          },
        })
        break
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画選択" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoadingPlans} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h2>配送計画一覧</h2>
                  {deliveryPlans.length > 0 ? (
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>計画No</th>
                          <th>担当者</th>
                          <th>ステータス</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryPlans.map((plan, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{plan.id}</td>
                            <td>{plan.user_name}</td>
                            <td>{plan.status_name}</td>
                            <td>
                              <Button
                                color="primary"
                                onClick={() => handleSelectPlan(plan)}
                              >
                                遷移
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>割り当てられた配信計画がありません。</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanSelect
