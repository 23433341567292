// api.js
import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const getWarehouses = async () => {
  const response = await get(url.GET_WAREHOUSES)
  return response.results // 応答からデータ部分のみを返す
}

const postWarehouse = async warehouseData => {
  const response = await post(url.POST_WAREHOUSE, warehouseData)
  return response.results // 新しく作成された倉庫のデータを返す
}

const putWarehouse = async warehouseData => {
  const warehouseId = warehouseData.warehouse_id // 更新する倉庫のIDを取得
  const response = await put(
    `${url.PUT_WAREHOUSE}/${warehouseId}`,
    warehouseData
  )
  return response.results // 更新された倉庫のデータを返す
}

const deleteWarehouse = async warehouseData => {
  const warehouseId = warehouseData
  const response = await del(
    `${url.DELETE_WAREHOUSE}/${warehouseId}`,
    warehouseData
  )
  return response.results // 更新された倉庫のデータを返す
}

// 倉庫在庫の取得
const getWarehouseStock = async warehouseId => {
  const response = await get(`${url.GET_WAREHOUSES}/${warehouseId}`)
  return response.results // 応答から在庫データ部分のみを返す
}

// 倉庫在庫の追加
const postWarehouseStock = async warehouseData => {
  const response = await post(
    `${url.POST_WAREHOUSE}/${warehouseData.warehouse_id}/${warehouseData.goods_id}/${warehouseData.stock_count}`,
    warehouseData
  )
  return response.results // 新しく追加された在庫データを返す
}

// 倉庫在庫の更新
const putWarehouseStock = async warehouseData => {
  const response = await put(
    `${url.PUT_WAREHOUSE}/${warehouseData.warehouse_id}/${warehouseData.goods_id}/${warehouseData.stock_count}`,
    warehouseData
  )
  return response.results // 更新された在庫データを返す
}

// 倉庫在庫の削除
const delWarehouseStock = async (warehouseStockId, warehouseId) => {
  const response = await del(
    `${url.DELETE_WAREHOUSE}/${warehouseStockId}/${warehouseId}`
  )
  return response.results // 削除された在庫データを返す
}

export {
  getWarehouses,
  postWarehouse,
  putWarehouse,
  deleteWarehouse,
  getWarehouseStock,
  postWarehouseStock,
  putWarehouseStock,
  delWarehouseStock,
}
