import { call, put, takeEvery } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"

// 必要なバックエンドヘルパー関数をインポート
import {
  getCompanies,
  postCompany,
  putCompany,
  deleteCompany,
} from "../../helpers/backend_helper"

function* fetchCompaniesSaga() {
  try {
    const companies = yield call(getCompanies)
    yield put(actions.fetchCompaniesSuccess(companies))
  } catch (error) {
    yield put(actions.fetchCompaniesFailure(error.toString()))
  }
}

function* registerCompanySaga(action) {
  try {
    const { newCompany } = action
    const companies = yield call(postCompany, newCompany)
    yield put(actions.registerCompanySuccess(companies))
    // 必要に応じて他のアクションをディスパッチ
  } catch (error) {
    yield put(actions.registerCompanyFailure(error.toString()))
  }
}

function* updateCompanySaga(action) {
  try {
    const { companyId, companyData } = action
    // putCompanyの結果をupdatedCompanyDataに代入
    const companies = yield call(putCompany, companyId, companyData)
    yield put(actions.updateCompanySuccess(companies))
    // 必要に応じて他のアクションをディスパッチ
  } catch (error) {
    yield put(actions.updateCompanyFailure(error.toString()))
  }
}

function* deleteCompanySaga(action) {
  try {
    const { companyId } = action
    const companies = yield call(deleteCompany, companyId)
    yield put(actions.deleteCompanySuccess(companies))
    // 必要に応じて他のアクションをディスパッチ
  } catch (error) {
    yield put(actions.deleteCompanyFailure(error.toString()))
  }
}

function* companySaga() {
  yield takeEvery(actionTypes.FETCH_COMPANIES_REQUEST, fetchCompaniesSaga)
  yield takeEvery(actionTypes.REGISTER_COMPANY_REQUEST, registerCompanySaga)
  yield takeEvery(actionTypes.UPDATE_COMPANY_REQUEST, updateCompanySaga)
  yield takeEvery(actionTypes.DELETE_COMPANY_REQUEST, deleteCompanySaga)
}

export default companySaga
