// companyAPI.js

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const getCompanies = async () => {
  const response = await get(url.GET_COMPANIES)
  return { data: response }
}

const postCompany = async companyData => {
  const response = await put(url.PUT_COMPANY, companyData)
  return { data: response }
}

const putCompany = async (companyId, companyData) => {
  const response = await put(`${url.PUT_COMPANY}/${companyId}`, companyData)
  return { data: response }
}

const deleteCompany = async companyId => {
  const response = await del(`${url.DELETE_COMPANY}/${companyId}`)
  return { data: response }
}

export { getCompanies, postCompany, putCompany, deleteCompany }
