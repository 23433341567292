import React, { useEffect, useState, useRef, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Input,
  Table,
} from "reactstrap"
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api"
import { decode } from "@googlemaps/polyline-codec"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { FaBurn } from "react-icons/fa"

import { fetchSoldOut } from "../../store/soldout/actions"
import {
  fetchItemsForLoading,
  fetchDeliveryPlanDetailRequest,
  updateDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions"
import { fetchGraphData } from "../../store/stocks/actions"

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY

const DeliveryPlanExecute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { history, deliveryPlan, vehicleId } = location.state || {}

  const startWarehouseId =
    history?.startWarehouseId || deliveryPlan?.start_warehouse_id
  const endWarehouseId =
    history?.endWarehouseId || deliveryPlan?.end_warehouse_id
  const selectedLocations = useMemo(
    () =>
      history?.selectedLocations ||
      deliveryPlan?.locations?.waypoints?.map(wp => wp.id) ||
      [],
    [history, deliveryPlan]
  )

  const {
    id: planId,
    user_name: userName,
    created_at: createdAt,
  } = deliveryPlan || {}

  const deliveryPlanDetail =
    useSelector(state => state.deliveryPlanReducer.deliveryPlanDetail) || {}
  const items = useSelector(state => state.deliveryPlanReducer.items) || []
  const isLoading1 = useSelector(state => state.deliveryPlanReducer.loading)
  const soldOutData =
    useSelector(state => state.soldOutReducer.soldOutData) || []
  const isLoading2 = useSelector(state => state.soldOutReducer.loading)

  const graphData = useSelector(state => state.stocksReducer.graphData)
  const isLoading3 = useSelector(state => state.stocksReducer.loading)
  const results = graphData && graphData.data ? graphData.data.results : []

  const [totalDistance, setTotalDistance] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [startDate, setStartDate] = useState("")

  const [decodedRoute, setDecodedRoute] = useState([])
  const [routeInfo, setRouteInfo] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [elapsedTime, setElapsedTime] = useState("")
  const [segmentDistance, setSegmentDistance] = useState(null)
  const [segmentDuration, setSegmentDuration] = useState(null)
  const [markers, setMarkers] = useState([])
  const [buttonText, setButtonText] = useState("最初のロケーションに行く")
  const [showMap, setShowMap] = useState(true)
  const [locationInfo, setLocationInfo] = useState(null)
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0)
  const [mapCenter, setMapCenter] = useState({ lat: 35.6895, lng: 139.6917 })
  const [mapZoom, setMapZoom] = useState(10)
  const [showTotalDistanceDuration, setShowTotalDistanceDuration] =
    useState(true)
  const [showItems, setShowItems] = useState(false)
  const [showGraphData, setShowGraphData] = useState(false)
  const [includeCloseToSoldOut, setIncludeCloseToSoldOut] = useState(false)
  const [exRoute, setExRoute] = useState({})
  const [showReturnItems, setShowReturnItems] = useState(false)
  const [returnItems, setReturnItems] = useState([])
  const [confirmationStatus, setConfirmationStatus] = useState([])
  const [exclusionStatus, setExclusionStatus] = useState([]) // exclusionStatusの追加
  const [aggregatedItems, setAggregatedItems] = useState([])

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  })

  useEffect(() => {
    if (deliveryPlan) {
      dispatch(fetchDeliveryPlanDetailRequest(deliveryPlan.id))
    }
  }, [dispatch, deliveryPlan])

  useEffect(() => {
    const fetchRouteDetails = async () => {
      if (
        deliveryPlanDetail.route &&
        buttonText === "最初のロケーションに行く"
      ) {
        if (deliveryPlanDetail && deliveryPlanDetail.locations.waypoints) {
          const waypoints = deliveryPlanDetail.locations.waypoints
          console.log(waypoints)

          for (let i = 1; i < waypoints.length - 1; i++) {
            const waypoint = waypoints[i]
            switch (waypoint.status) {
              case "移動中":
                setShowGraphData(false)

                const origin =
                  deliveryPlanDetail.route.waypoints_info[currentLocationIndex]
                    .location
                const destination =
                  deliveryPlanDetail.route.waypoints_info[
                    currentLocationIndex + 1
                  ].location

                const result = await calculateRoute(origin, destination)

                if (result) {
                  const decodedPath = decode(result.routes[0].overview_polyline)
                  setDecodedRoute(decodedPath)
                  setSegmentDistance(result.routes[0].legs[0].distance.text)
                  setSegmentDuration(result.routes[0].legs[0].duration.text)
                  setMarkers([origin, destination])

                  // Adjust map bounds to fit route
                  const bounds = new window.google.maps.LatLngBounds()
                  bounds.extend(origin)
                  bounds.extend(destination)
                  mapRef.current.fitBounds(bounds)

                  setCurrentLocationIndex(currentLocationIndex + 1)
                  setButtonText("ロケーションに到着")
                  setShowTotalDistanceDuration(false) // ここで非表示にする
                  setShowMap(true) // ここでマップを再表示する
                  setExRoute(deliveryPlanDetail.route)
                }
                break
              case "到着":
                setCurrentLocationIndex(i)
                setStartDate(deliveryPlanDetail.actual_start_date)
                setShowMap(false)
                setLocationInfo({
                  name: waypoint.name,
                  address: waypoint.address,
                })
                setShowItems(false)
                setShowGraphData(false)
                setButtonText("補充を開始")
                setExRoute(deliveryPlanDetail.route)
                break
              case "補充開始":
                console.log(`補充開始のロケーション: ${waypoint.name}`)
                setStartDate(deliveryPlanDetail.actual_start_date)
                setShowMap(false)

                dispatch(fetchSoldOut({ locId: waypoint.id }))
                dispatch(
                  fetchItemsForLoading({
                    deliveryPlanId: deliveryPlanDetail.id,
                    vehicleId: deliveryPlanDetail.vehicle_id,
                  })
                )

                const filteredItems = deliveryPlanDetail.items.filter(
                  item => item.loc_id === waypoint.id
                )
                const aggregated = filteredItems.reduce((acc, item) => {
                  const existingItem = acc.find(i => i.name === item.name)
                  if (existingItem) {
                    existingItem.needed_quantity += item.needed_quantity
                    existingItem.current_quantity += item.current_quantity
                  } else {
                    acc.push({ ...item })
                  }
                  return acc
                }, [])

                setAggregatedItems(aggregated)
                setReturnItems(aggregated)
                setShowItems(true)
                setButtonText("最新の補充を確認")
                setExRoute(deliveryPlanDetail.route)
                setCurrentLocationIndex(i)
                setConfirmationStatus(aggregated.map(() => false)) // 初期化
                setExclusionStatus(aggregated.map(() => false)) // 初期化
                break
              case "補充終了":
                console.log(`補充終了のロケーション: ${waypoint.name}`)
                // 次のロケーションのstatusを確認
                if (i + 1 < waypoints.length - 1) {
                  // 次のロケーションが存在する場合
                  const nextWaypoint = waypoints[i + 1]
                  console.log(
                    `次のロケーションのstatus: ${nextWaypoint.status}`
                  )
                  // ここで次のロケーションのstatusに基づいて処理を行う
                }
                setExRoute(deliveryPlanDetail.route)
                setCurrentLocationIndex(i)
                break
              default:
                setTotalDistance(deliveryPlanDetail.locations.total_distance)
                setTotalDuration(deliveryPlanDetail.locations.total_duration)
                setStartDate(new Date())
                const decodedPath = decode(deliveryPlanDetail.route.route)
                setDecodedRoute(decodedPath)
                setRouteInfo(deliveryPlanDetail.route.route)
                setMarkers(
                  deliveryPlanDetail.route.waypoints_info.map(
                    point => point.location
                  )
                )
                setExRoute(deliveryPlanDetail.route)

                if (mapRef.current && decodedPath.length > 0) {
                  const bounds = new window.google.maps.LatLngBounds()
                  decodedPath.forEach(([lat, lng]) =>
                    bounds.extend({ lat, lng })
                  )
                  mapRef.current.fitBounds(bounds)
                }
                break
            }
          }
        }
      }
    }
    fetchRouteDetails()
  }, [deliveryPlanDetail, currentLocationIndex, items])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date()
      setCurrentTime(new Date(now.getTime() + 9 * 60 * 60 * 1000))

      const start = new Date(startDate)
      const elapsed = Math.floor((now - start) / 1000)
      const hours = Math.floor(elapsed / 3600)
      const minutes = Math.floor((elapsed % 3600) / 60)
      const seconds = elapsed % 60
      setElapsedTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      )
    }, 1000)

    return () => clearInterval(intervalId)
  }, [startDate])

  const updateItemQuantities = (aggregatedItems, deliveryPlanDetail) => {
    const updatedItems = deliveryPlanDetail.items.map(item => {
      const matchedAggregatedItem = aggregatedItems.find(
        aggItem => aggItem.name === item.name
      )
      if (matchedAggregatedItem) {
        return {
          ...item,
          needed_quantity:
            item.needed_quantity - matchedAggregatedItem.needed_quantity,
        }
      }
      return item
    })
    return updatedItems
  }

  const handleNavigation = () => {
    if (
      exRoute.waypoints_info &&
      exRoute.waypoints_info.length > currentLocationIndex + 1
    ) {
      const origin = exRoute.waypoints_info[currentLocationIndex].location
      const destination =
        exRoute.waypoints_info[currentLocationIndex + 1].location

      const navigationUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`
      window.open(navigationUrl, "_blank")
    }
  }

  const handleNextLocation = async step => {
    // 確認済みの状態をリセット (最新の補充を確認ボタンではリセットしない)
    if (buttonText !== "最新の補充を確認") {
      setConfirmationStatus(confirmationStatus.map(() => false))
      setExclusionStatus(exclusionStatus.map(() => false))
    }

    // 最初のロケーションに行く処理
    if (
      buttonText === "最初のロケーションに行く" &&
      exRoute.waypoints_info &&
      exRoute.waypoints_info.length > currentLocationIndex + 1
    ) {
      setShowGraphData(false)

      const origin = exRoute.waypoints_info[currentLocationIndex].location
      const destination =
        exRoute.waypoints_info[currentLocationIndex + 1].location

      const result = await calculateRoute(origin, destination)

      if (result) {
        const decodedPath = decode(result.routes[0].overview_polyline)
        setDecodedRoute(decodedPath)
        setSegmentDistance(result.routes[0].legs[0].distance.text)
        setSegmentDuration(result.routes[0].legs[0].duration.text)
        setMarkers([origin, destination])

        // Adjust map bounds to fit route
        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(origin)
        bounds.extend(destination)
        mapRef.current.fitBounds(bounds)

        setCurrentLocationIndex(currentLocationIndex + 1)
        setButtonText("ロケーションに到着")
        setShowTotalDistanceDuration(false) // ここで非表示にする
        setShowMap(true) // ここでマップを再表示する
      }

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        status: 3,
        locations: {
          ...deliveryPlanDetail.locations,
          waypoints: deliveryPlanDetail.locations.waypoints.map(
            (waypoint, index) => {
              if (index === currentLocationIndex + 1) {
                return {
                  ...waypoint,
                  status: "移動中",
                  start_moving_time: new Date().toISOString(),
                }
              }
              return waypoint
            }
          ),
        },
      }

      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: updatedDeliveryPlan,
        })
      )
    }
    // 次のロケーションに行く処理
    else if (
      step === 1 &&
      exRoute.waypoints_info &&
      exRoute.waypoints_info.length > currentLocationIndex + 1
    ) {
      setShowGraphData(false)

      const origin = exRoute.waypoints_info[currentLocationIndex].location
      const destination =
        exRoute.waypoints_info[currentLocationIndex + 1].location

      const result = await calculateRoute(origin, destination)

      if (result) {
        const decodedPath = decode(result.routes[0].overview_polyline)
        setDecodedRoute(decodedPath)
        setSegmentDistance(result.routes[0].legs[0].distance.text)
        setSegmentDuration(result.routes[0].legs[0].duration.text)
        setMarkers([origin, destination])

        // Adjust map bounds to fit route
        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(origin)
        bounds.extend(destination)
        mapRef.current.fitBounds(bounds)

        const updatedItems = updateItemQuantities(
          aggregatedItems,
          deliveryPlanDetail
        )

        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          items: updatedItems,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex) {
                  return {
                    ...waypoint,
                    status: "補充終了",
                    end_refilling_time: new Date().toISOString(),
                  }
                }
                if (index === currentLocationIndex + 1) {
                  return {
                    ...waypoint,
                    status: "移動中",
                    start_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )

        setCurrentLocationIndex(currentLocationIndex + 1)
        setButtonText("ロケーションに到着")
        setShowTotalDistanceDuration(false) // ここで非表示にする
        setShowMap(true) // ここでマップを再表示する
      }
    }
    // ロケーションに到着した場合の処理
    else if (buttonText === "ロケーションに到着") {
      if (currentLocationIndex === exRoute.waypoints_info.length - 1) {
        // 最後のロケーションが倉庫の場合の処理
        setShowMap(false)
        setShowReturnItems(true)
        // 商品名が同じなら合算する処理を追加
        const aggregatedReturnItems = deliveryPlanDetail.items.reduce(
          (acc, item) => {
            const existingItem = acc.find(i => i.name === item.name)
            if (existingItem) {
              existingItem.needed_quantity += item.needed_quantity
            } else {
              acc.push({ ...item, quantity: 0 })
            }
            return acc
          },
          []
        )

        setReturnItems(aggregatedReturnItems)

        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex + 1) {
                  return {
                    ...waypoint,
                    status: "到着",
                    end_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )

        setConfirmationStatus(aggregatedReturnItems.map(() => false))
        setExclusionStatus(aggregatedReturnItems.map(() => false))
        setButtonText("戻し完了")
      } else {
        setShowMap(false)
        setLocationInfo({
          name: exRoute.waypoints_names[currentLocationIndex].name,
          address: exRoute.waypoints_names[currentLocationIndex].address,
        })
        setShowItems(false)
        setShowGraphData(false)
        setButtonText("補充を開始")

        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex) {
                  return {
                    ...waypoint,
                    status: "到着",
                    end_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )
      }
    } else if (buttonText === "補充を開始") {
      const currentLocationName =
        exRoute.waypoints_names[currentLocationIndex].name

      const currentLocation =
        deliveryPlanDetail.locations.waypoints[currentLocationIndex]

      dispatch(fetchSoldOut({ locId: currentLocation.id }))
      dispatch(
        fetchItemsForLoading({
          deliveryPlanId: deliveryPlanDetail.id,
          vehicleId: deliveryPlanDetail.vehicle_id,
        })
      )

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        locations: {
          ...deliveryPlanDetail.locations,
          waypoints: deliveryPlanDetail.locations.waypoints.map(
            (waypoint, index) => {
              if (index === currentLocationIndex) {
                return {
                  ...waypoint,
                  status: "補充開始",
                  start_refilling_time: new Date().toISOString(),
                }
              }
              return waypoint
            }
          ),
        },
      }

      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: updatedDeliveryPlan,
        })
      )

      const filteredItems = deliveryPlanDetail.items.filter(
        item =>
          item.loc_id ===
          deliveryPlanDetail.locations.waypoints[currentLocationIndex].id
      )
      const aggregated = filteredItems.reduce((acc, item) => {
        const existingItem = acc.find(i => i.name === item.name)
        if (existingItem) {
          existingItem.needed_quantity += item.needed_quantity
          existingItem.current_quantity += item.current_quantity
        } else {
          acc.push({ ...item })
        }
        return acc
      }, [])

      setAggregatedItems(aggregated)

      setShowItems(true)
      setButtonText("最新の補充を確認")
    } else if (buttonText === "最新の補充を確認") {
      const currentLocationName =
        exRoute.waypoints_names[currentLocationIndex].name
      const currentLocationIndexInPlan =
        deliveryPlanDetail.location_names.findIndex(
          name => name === currentLocationName
        )

      const currentLocation =
        deliveryPlanDetail.locations.waypoints[currentLocationIndexInPlan]
      if (currentLocation && currentLocation.id) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)

        dispatch(
          fetchGraphData(null, currentLocation.id, "時", today, yesterday)
        )
        setShowGraphData(true)
      } else {
        console.error("Current location is undefined or does not have loc_id")
      }
    } else if (step === 2) {
      // 確認済みの商品の情報を配送計画に含める
      const confirmedItems = returnItems.filter(
        (item, index) => confirmationStatus[index] || exclusionStatus[index]
      )

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        actual_end_date: new Date().toISOString(),
        confirmedItems,
      }

      // 配送計画の更新
      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: { ...updatedDeliveryPlan, status: 5 },
        })
      )

      // 次の画面に遷移
      navigate("/delivery-plan/complete", {
        state: {
          aggregatedItems: aggregatedItems,
          deliveryPlan: { ...updatedDeliveryPlan, status: 5 },
        },
      })
    }
  }

  const handleQuantityChange = (index, value) => {
    const newReturnItems = [...returnItems]
    if (newReturnItems[index]) {
      newReturnItems[index].needed_quantity = Math.max(0, value)
      setReturnItems(newReturnItems)
    }
  }

  const handleConfirmItem = index => {
    const newConfirmationStatus = [...confirmationStatus]
    newConfirmationStatus[index] = !newConfirmationStatus[index]
    setConfirmationStatus(newConfirmationStatus)
  }

  const handleExcludeItem = index => {
    const newExclusionStatus = [...exclusionStatus]
    newExclusionStatus[index] = !newExclusionStatus[index]
    setExclusionStatus(newExclusionStatus)
  }

  const handleConfirmAll = () => {
    const newStatus = confirmationStatus.some(status => !status)
    setConfirmationStatus(returnItems.map(() => newStatus))
    setExclusionStatus(returnItems.map(() => !newStatus)) // 新しい部分
  }

  const allItemsConfirmed = confirmationStatus.every(status => status)

  const calculateRoute = async (origin, destination) => {
    try {
      const directionsService = new window.google.maps.DirectionsService()
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      return result
    } catch (error) {
      console.error("Error calculating route:", error)
      return null
    }
  }

  const mapRef = useRef(null)

  if (loadError) {
    return <div>Google Maps APIのロード中にエラーが発生しました。</div>
  }

  if (!isLoaded) {
    return <div>Google Maps APIをロード中...</div>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画実行" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2 || isLoading3} />
          <Row>
            <Col xs={12}>
              <Card className="border">
                <CardBody style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      marginBottom: "10px",
                      backgroundColor: "#dcdcdc",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <h2 style={{ margin: 0 }}>計画NO: {planId}</h2>
                    <p style={{ margin: 0 }}>
                      作成時刻: {new Date(createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div style={{ marginBottom: "0px" }}>
                    <p style={{ margin: "0px" }}>
                      開始時刻: {new Date(startDate).toLocaleString()}
                    </p>
                    <p style={{ margin: "0px" }}>
                      現在時刻: {currentTime.toLocaleString()}
                    </p>
                    <p style={{ margin: "0px" }}>経過時間: {elapsedTime}</p>
                  </div>
                  <FormGroup>
                    <Input
                      type="text"
                      value={userName || ""}
                      disabled
                      style={{ backgroundColor: "#e9ecef" }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {showMap ? (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {showTotalDistanceDuration && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>総距離: </strong>
                        {totalDistance} km
                        <br />
                        <strong>総時間: </strong>
                        {totalDuration} 分
                      </div>
                    )}
                    {segmentDistance && segmentDuration && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>区間距離: </strong>
                        {segmentDistance}
                        <br />
                        <strong>区間時間: </strong>
                        {segmentDuration}
                      </div>
                    )}
                    <GoogleMap
                      mapContainerStyle={{ height: "400px", width: "100%" }}
                      onLoad={map => {
                        mapRef.current = map
                        if (decodedRoute.length > 0) {
                          const bounds = new window.google.maps.LatLngBounds()
                          decodedRoute.forEach(([lat, lng]) =>
                            bounds.extend({ lat, lng })
                          )
                          map.fitBounds(bounds)
                        }
                      }}
                    >
                      {decodedRoute.length > 0 && (
                        <Polyline
                          path={decodedRoute.map(([lat, lng]) => ({
                            lat,
                            lng,
                          }))}
                          options={{ strokeColor: "#FF0000" }}
                        />
                      )}
                      {markers.map((marker, index) => (
                        <Marker
                          key={index}
                          position={marker}
                          label={
                            currentLocationIndex === 0
                              ? String.fromCharCode(65 + index)
                              : String.fromCharCode(
                                  65 + currentLocationIndex - 1 + index
                                )
                          }
                        />
                      ))}
                    </GoogleMap>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {exRoute.waypoints_names && currentLocationIndex > 0 ? (
                        <div>
                          <strong>
                            {String.fromCharCode(65 + currentLocationIndex - 1)}
                            :
                          </strong>{" "}
                          {
                            exRoute.waypoints_names[currentLocationIndex - 1]
                              .name
                          }{" "}
                          :{" "}
                          {
                            exRoute.waypoints_names[currentLocationIndex - 1]
                              .address
                          }
                          <br />
                          <strong>
                            {String.fromCharCode(65 + currentLocationIndex)}:
                          </strong>{" "}
                          {exRoute.waypoints_names[currentLocationIndex].name} :{" "}
                          {
                            exRoute.waypoints_names[currentLocationIndex]
                              .address
                          }
                        </div>
                      ) : (
                        <div>
                          {exRoute.waypoints_names &&
                            exRoute.waypoints_names.map((point, index) => {
                              const displayName =
                                point.name.split(":")[1] || point.name
                              return (
                                <div key={index}>
                                  <strong>
                                    {String.fromCharCode(65 + index)}:
                                  </strong>{" "}
                                  {displayName} :{point.address}
                                </div>
                              )
                            })}
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button color="success" onClick={handleNextLocation}>
                          {buttonText}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : showReturnItems ? (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5>戻し商品一覧</h5>
                      <Button
                        color="primary"
                        onClick={handleConfirmAll}
                        style={{ marginBottom: "10px" }}
                      >
                        一括確認
                      </Button>
                    </div>
                    <Table className="custom-table">
                      <thead>
                        <tr>
                          <th>商品名</th>
                          <th>個数</th>
                          <th>確認</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {returnItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              <Input
                                type="number"
                                min="0"
                                value={item.needed_quantity}
                                onChange={e =>
                                  handleQuantityChange(
                                    index,
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Button
                                color={
                                  confirmationStatus[index]
                                    ? "success"
                                    : "warning"
                                }
                                onClick={() => handleConfirmItem(index)}
                                disabled={exclusionStatus[index]} // 確認ボタンが無効になる条件を追加
                              >
                                {confirmationStatus[index]
                                  ? "確認済み"
                                  : "確認"}
                              </Button>
                            </td>
                            <td>
                              <Button
                                color={
                                  exclusionStatus[index]
                                    ? "danger"
                                    : "secondary"
                                }
                                onClick={() => handleExcludeItem(index)}
                                disabled={confirmationStatus[index]} // 戻さないボタンが無効になる条件を追加
                              >
                                {exclusionStatus[index] ? "除外済み" : "除外"}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        color="success"
                        onClick={() => handleNextLocation(2)}
                        disabled={
                          !confirmationStatus.every(status => status) &&
                          !exclusionStatus.every(status => status)
                        }
                      >
                        戻し完了
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {locationInfo && (
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: "20px",
                          fontSize: "18px", // 文字サイズを少し大きく
                        }}
                      >
                        <strong>到着したロケーション情報:</strong>
                        <br />
                        <strong>名前:</strong>{" "}
                        {
                          deliveryPlanDetail.route.waypoints_names[
                            currentLocationIndex
                          ].name
                        }
                      </div>
                    )}
                    {showItems && soldOutData.length > 0 && (
                      <Row>
                        {soldOutData.map(location => (
                          <Col
                            key={location.loc_id}
                            md={6}
                            lg={8}
                            className="mx-auto"
                          >
                            <Card
                              className="mb-3"
                              style={{ border: "1px solid black" }}
                            >
                              <CardBody>
                                <CardTitle
                                  tag="h5"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold", // テキストを太文字にする
                                    color: selectedLocations.includes(
                                      location.loc_id
                                    )
                                      ? "white"
                                      : "black", // 選択時はテキストを白色に
                                    backgroundColor: selectedLocations.includes(
                                      location.loc_id
                                    )
                                      ? "#ff69b4"
                                      : "", // 背景色を赤に近いピンクに
                                    padding: "5px", // タイトル周りにパディングを追加
                                    border: "1px solid black",
                                  }}
                                >
                                  {location.loc_name}
                                </CardTitle>
                                <div
                                  className="stock-header"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <span style={{ flex: 4 }}>商品名</span>
                                  <span style={{ flex: 1, textAlign: "right" }}>
                                    在庫数
                                  </span>
                                  <span style={{ flex: 1, textAlign: "right" }}>
                                    不足数
                                  </span>
                                </div>
                                {location.stocks.map((stock, index) => {
                                  const stockThreshold = includeCloseToSoldOut
                                    ? 5
                                    : 0
                                  return (
                                    stock.current_stock <= stockThreshold && (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        <span style={{ flex: 4 }}>
                                          {parseFloat(
                                            stock.sales_amount_velocity
                                          ) >= 1500 && (
                                            <span style={{ color: "red" }}>
                                              <FaBurn />
                                            </span>
                                          )}
                                          {stock.goods_name}
                                        </span>
                                        <span
                                          style={{
                                            flex: 1,
                                            color:
                                              stock.current_stock === 0
                                                ? "red"
                                                : "blue",
                                            textAlign: "right",
                                          }}
                                        >
                                          {stock.current_stock}
                                        </span>
                                        <span
                                          style={{
                                            flex: 1,
                                            textAlign: "right",
                                          }}
                                        >
                                          {stock.shortage}
                                        </span>
                                      </div>
                                    )
                                  )
                                })}
                                {location.stocks.map((stock, index) => {
                                  const stockThreshold = includeCloseToSoldOut
                                    ? 3
                                    : 0
                                  return (
                                    stock.current_stock > stockThreshold && (
                                      <div
                                        key={index}
                                        className={`stock-details ${"visible"}`}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        <span style={{ flex: 4 }}>
                                          {parseFloat(
                                            stock.sales_amount_velocity
                                          ) >= 1500 && (
                                            <span style={{ color: "red" }}>
                                              <FaBurn />
                                            </span>
                                          )}
                                          {stock.goods_name}
                                        </span>
                                        <span
                                          style={{
                                            flex: 1,
                                            color:
                                              stock.current_stock > 3
                                                ? "black"
                                                : "blue",
                                            textAlign: "right",
                                          }}
                                        >
                                          {stock.current_stock}
                                        </span>
                                        <span
                                          style={{
                                            flex: 1,
                                            textAlign: "right",
                                          }}
                                        >
                                          {stock.shortage}
                                        </span>
                                      </div>
                                    )
                                  )
                                })}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    )}
                    {showItems && aggregatedItems.length > 0 && (
                      <Row>
                        <Col md={6} lg={8} className="mx-auto">
                          <Card
                            className="mb-3"
                            style={{
                              border: "1px solid black",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            <CardBody>
                              <h5>商品一覧</h5>
                              <p>商品を補充してください</p> {/* ここに追加 */}
                              <Table className="custom-table">
                                <thead>
                                  <tr>
                                    <th>商品名</th>
                                    <th>必要数量</th>
                                    <th>確認</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {aggregatedItems.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.name}</td>
                                      <td>
                                        <Input
                                          type="number"
                                          min="0"
                                          value={item.needed_quantity}
                                          onChange={e =>
                                            handleQuantityChange(
                                              index,
                                              parseInt(e.target.value)
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          color={
                                            confirmationStatus[index]
                                              ? "success"
                                              : "warning"
                                          }
                                          onClick={() =>
                                            handleConfirmItem(index)
                                          }
                                          disabled={confirmationStatus[index]}
                                        >
                                          {confirmationStatus[index]
                                            ? "確認済み"
                                            : "確認"}
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button color="success" onClick={handleNextLocation}>
                        {buttonText}
                      </Button>
                    </div>
                    {showGraphData && results.length > 0 && (
                      <Row>
                        <Col md={6} lg={8} className="mx-auto">
                          <Card
                            className="mb-3"
                            style={{ border: "1px solid black" }}
                          >
                            <CardBody>
                              <h5>最新の補充データ</h5>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>商品名</th>
                                    <th className="stocks-count">満ﾀﾝ</th>
                                    <th className="stocks-count">在庫</th>
                                    <th className="stocks-count">不足</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {results.map((result, index) => (
                                    <tr
                                      key={index}
                                      onClick={() => handleRowClick(result)}
                                    >
                                      <td>{result.goods_name}</td>
                                      <td className="stocks-count">
                                        {result.full_stock}
                                      </td>
                                      <td
                                        style={{
                                          color:
                                            result.current_stock <= 0
                                              ? "red"
                                              : "inherit",
                                          fontWeight:
                                            result.current_stock <= 0
                                              ? "bold"
                                              : "normal",
                                        }}
                                        className="stocks-count"
                                      >
                                        {result.current_stock}
                                      </td>
                                      <td className="stocks-count">
                                        {result.shortage}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {showGraphData && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            if (
                              currentLocationIndex <
                              deliveryPlanDetail.locations.length
                            ) {
                              setShowMap(true)
                              setCurrentLocationIndex(currentLocationIndex + 1)
                              handleNextLocation(1)
                              setButtonText("ロケーションに到着")
                            } else {
                              setShowMap(true)
                              setCurrentLocationIndex(currentLocationIndex + 1)
                              handleNextLocation(1)
                              setButtonText("ロケーションに到着")
                            }
                          }}
                        >
                          次のロケーションに移動する
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanExecute
