import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_CUSTOMERS_DATA_REQUEST,
  FETCH_CUSTOMERS_DATA_REQUEST_2,
  UPDATE_CUSTOMER_DATA_REQUEST,
} from "./actionTypes"
import {
  fetchCustomersDataSuccess,
  fetchCustomersDataError,
  fetchCustomersDataSuccess2,
  fetchCustomersDataError2,
  updateCustomerDataSuccess,
  updateCustomerDataError,
} from "./actions"
import {
  getCustomersData,
  getCustomersData2,
  updateCustomerData as updateCustomerDataAPI,
} from "../../helpers/backend_helper"

function* fetchCustomersDataSaga(action) {
  try {
    const {
      genderFilter,
      ageRangeFilter,
      startDate,
      endDate,
      freeWordSearch,
      verifyFilter,
      locId,
      pageNumber,
      pageSize,
    } = action.payload

    // 他のフィルタと共にページ番号とページサイズも渡します。
    const customersData = yield call(getCustomersData, {
      locId,
      genderFilter,
      ageRangeFilter,
      startDate,
      endDate,
      freeWordSearch,
      verifyFilter,
      pageNumber,
      pageSize,
    })

    // 必要に応じてバックエンドからの応答を適切に処理します。
    const results = {
      customers: customersData.data.customers,
      totalItems: customersData.data.total_count, // 例えば、totalItemsというプロパティから総アイテム数を取得
    }

    yield put(fetchCustomersDataSuccess(results))
  } catch (error) {
    yield put(fetchCustomersDataError(error))
  }
}

function* fetchCustomersDataSaga2(action) {
  try {
    const { locId, pageNumber, pageSize, startDate, endDate } = action.payload

    // 他のフィルタと共にページ番号とページサイズも渡します。
    const customersData = yield call(getCustomersData2, {
      locId,
      pageNumber,
      pageSize,
      startDate,
      endDate,
    })

    // 必要に応じてバックエンドからの応答を適切に処理します。
    const results = {
      customers: customersData.data.sales_and_customers,
      totalItems: customersData.data.total_count,
    }

    yield put(fetchCustomersDataSuccess2(results))
  } catch (error) {
    yield put(fetchCustomersDataError2(error))
  }
}

// 新しいSaga: 顧客データの更新
function* updateCustomerDataSaga(action) {
  try {
    const {
      index,
      customer,
      genderFilter,
      ageRangeFilter,
      startDate,
      endDate,
      freeWordSearch,
      locId,
    } = action.payload

    // API呼び出し用のパラメータ
    const params = {
      genderFilter,
      ageRangeFilter,
      startDate,
      endDate,
      freeWordSearch,
      locId,
      ...customer, // customerオブジェクト内の他の情報
    }

    // バックエンドでの更新
    const response = yield call(updateCustomerDataAPI, params)
    yield put(updateCustomerDataSuccess(index, customer))
  } catch (error) {
    yield put(updateCustomerDataError(error))
  }
}

function* customersSaga() {
  yield takeEvery(FETCH_CUSTOMERS_DATA_REQUEST, fetchCustomersDataSaga)
  yield takeEvery(FETCH_CUSTOMERS_DATA_REQUEST_2, fetchCustomersDataSaga2)
  yield takeEvery(UPDATE_CUSTOMER_DATA_REQUEST, updateCustomerDataSaga) // 新しいアクションタイプの追加
}

export default customersSaga
