import { get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postDeliveryPlan = async data => {
  const response = await post(url.POST_DELIVERY_PLAN, data)
  return { data: response }
}

const getDeliveryItems = async ({ deliveryPlanId, vehicleId }) => {
  try {
    const response = await get(url.GET_DELIVERY_ITEM, {
      params: { deliveryPlanId: deliveryPlanId, vehicleId },
    })
    return response
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message)
  }
}

const getDeliveryPlans = async data => {
  return await get(`${url.GET_DELIVERY_PLANS}`, {
    params: { type: data.type },
  })
}

const updateDeliveryPlan = async data => {
  const response = await put(
    `${url.UPDATE_DELIVERY_PLAN}/${data.delivery_plan.id}`,
    data.delivery_plan
  )
  return { data: response }
}

const getDeliveryPlanDetail = async deliveryPlanId => {
  try {
    const response = await get(`${url.GET_DELIVERY_PLAN_DETAIL}`, {
      params: { id: deliveryPlanId },
    })
    return response
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message)
  }
}

export {
  postDeliveryPlan,
  getDeliveryItems,
  getDeliveryPlans,
  updateDeliveryPlan,
  getDeliveryPlanDetail,
}
