// actionTypes.js
export const FETCH_LOCATION_GROUPS = "FETCH_LOCATION_GROUPS";
export const FETCH_LOCATION_GROUPS_SUCCESS = "FETCH_LOCATION_GROUPS_SUCCESS";
export const FETCH_LOCATION_GROUPS_ERROR = "FETCH_LOCATION_GROUPS_ERROR";

export const UPDATE_LOCATION_GROUP = "UPDATE_LOCATION_GROUP";
export const UPDATE_LOCATION_GROUP_SUCCESS = "UPDATE_LOCATION_GROUP_SUCCESS";
export const UPDATE_LOCATION_GROUP_ERROR = "UPDATE_LOCATION_GROUP_ERROR";

export const DELETE_LOCATION_GROUP = "DELETE_LOCATION_GROUP";
export const DELETE_LOCATION_GROUP_SUCCESS = "DELETE_LOCATION_GROUP_SUCCESS";
export const DELETE_LOCATION_GROUP_ERROR = "DELETE_LOCATION_GROUP_ERROR";
