import { get, put, del } from "../api_helper"
import * as url from "../url_helper"

const getIpcs = async (onlyCamera, onlyCashless, onlyIpc, pastLoc) => {
  const queryParams = new URLSearchParams()

  if (onlyCamera === 2) {
    queryParams.append("only_camera", 2)
  } else if (onlyCamera === true) {
    queryParams.append("only_camera", 1)
  }

  if (onlyCashless) {
    queryParams.append("only_cashless", 1)
  }

  if (onlyIpc) {
    queryParams.append("only_ipc", 1)
  }

  if (pastLoc) {
    queryParams.append("past_loc", 1)
  }

  const queryString = queryParams.toString() ? `?${queryParams.toString()}` : ""
  const response = await get(`${url.GET_IPCS}${queryString}`)

  return { data: response }
}

const getIpcsForUser = async user_id => {
  const response = await get(`${url.GET_IPCS}/${user_id}`)
  return { data: response }
}

const putIpcs = async (userId, ipcNo) => {
  const response = await put(url.PUT_IPCS, {
    userId: userId,
    ipcNo: ipcNo,
  })

  return { data: response }
}

const deleteIpcs = async (userId, ipcNo) => {
  const queryParams = new URLSearchParams()
  queryParams.append("user_id", userId)
  queryParams.append("ipc_no", ipcNo)
  const response = await del(`${url.DEL_IPCS}?${queryParams.toString()}`)

  return { data: response }
}

const getIpcsDetail = async ipc_no => {
  const response = await get(`${url.GET_IPCS}?ipc_no_detail=${ipc_no}`)
  return { data: response }
}

const updateIpcs = async ipcData => {
  const response = await put(url.PUT_IPCS, { ipcData: ipcData })
  return { data: response }
}

export {
  getIpcs,
  putIpcs,
  deleteIpcs,
  getIpcsForUser,
  getIpcsDetail,
  updateIpcs,
}
