import { get, put } from "../api_helper"
import * as url from "../url_helper"

const getGoodsBrand = async () => {
  const response = await get(url.GET_GOODS_BRAND)

  return { data: response }
}

const putGoodsBrand = async (
  targetGoods,
  brand,
  brandName,
  deleteFlg,
  createFlg,
) => {
  const response = await put(url.PUT_GOODS_BRAND, {
    targetGoods: targetGoods,
    brand: brand,
    brandName: brandName,
    deleteFlg: deleteFlg,
    createFlg: createFlg,
  })

  return { data: response }
}

export { getGoodsBrand, putGoodsBrand }
