// errorMiddleware.js
import { putError } from "./actions" // エラーメッセージをdispatchするactionをimportします

const errorMiddleware = store => next => action => {
  if (action.error) {
    store.dispatch(putError(action.error))
  }

  return next(action)
}

export default errorMiddleware
