// reducers.js

import {
  FETCH_GOODS_BRAND_GRAPH_DATA,
  FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS,
  FETCH_GOODS_BRAND_GRAPH_DATA_ERROR,
  FETCH_GOODS_BRAND_REG_DATA,
  FETCH_GOODS_BRAND_REG_DATA_SUCCESS,
  FETCH_GOODS_BRAND_REG_DATA_ERROR,
  UPDATE_GOODS,
} from "./actionTypes"

const initialState = {
  graphData: [],
  loading: false,
  error: null,
}

const BrandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOODS_BRAND_GRAPH_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
        loading: false,
      }
    case FETCH_GOODS_BRAND_GRAPH_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_GOODS_BRAND_REG_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_GOODS_BRAND_REG_DATA_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
        loading: false,
      }
    case FETCH_GOODS_BRAND_REG_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_GOODS:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          data: {
            ...state.graphData.data,
            goodsResults: action.payload,
          },
        },
      }
    default:
      return state
  }
}

export default BrandsReducer
