import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_GOODS,
  ADD_GOOD,
  UPDATE_GOOD,
  DELETE_GOOD,
  REGISTER_SURPRISE,
  UNREGISTER_SURPRISE,
  FETCH_GOODS_DETAIL,
} from "./actionTypes"
import {
  fetchGoodsSuccess,
  fetchGoodsError,
  addGoodSuccess,
  addGoodError,
  updateGoodSuccess,
  updateGoodError,
  deleteGoodSuccess,
  deleteGoodError,
  registerSurpriseSuccess,
  registerSurpriseError,
  unregisterSurpriseSuccess,
  unregisterSurpriseError,
  fetchGoodsDetailSuccess,
  fetchGoodsDetailError,
} from "./actions"

// API関数は適宜定義または既存のヘルパー関数を利用してください
import {
  getGoods,
  postGoods,
  putGoods,
  deleteGoods,
  registerSurpriseApi,
  unregisterSurpriseApi,
  fetchGoodsDetailApi,
} from "../../helpers/backend_helper"

function* fetchGoodsSaga() {
  try {
    const goods = yield call(getGoods) // 商品データの取得API呼び出し
    yield put(fetchGoodsSuccess(goods))
  } catch (error) {
    yield put(fetchGoodsError(error))
  }
}

function* addGoodSaga(action) {
  try {
    const newGood = yield call(postGoods, action.payload) // 新商品の追加API呼び出し
    yield put(addGoodSuccess(newGood))
  } catch (error) {
    yield put(addGoodError(error))
  }
}

function* updateGoodSaga(action) {
  try {
    const updatedGood = yield call(putGoods, action.payload.id) // 商品の更新API呼び出し
    yield put(updateGoodSuccess(updatedGood))
  } catch (error) {
    yield put(updateGoodError(error))
  }
}

function* deleteGoodSaga(action) {
  try {
    const deletedGood = yield call(deleteGoods, action.payload) // 商品の削除API呼び出し
    yield put(deleteGoodSuccess(deletedGood))
  } catch (error) {
    yield put(deleteGoodError(error))
  }
}

function* registerSurpriseSaga(action) {
  try {
    const response = yield call(registerSurpriseApi, action.payload) // サプライズ商品を登録API呼び出し
    yield put(registerSurpriseSuccess(response))
  } catch (error) {
    yield put(registerSurpriseError(error))
  }
}

function* unregisterSurpriseSaga(action) {
  try {
    const response = yield call(unregisterSurpriseApi, action.payload) // サプライズ商品を解除API呼び出し
    yield put(unregisterSurpriseSuccess(response))
  } catch (error) {
    yield put(unregisterSurpriseError(error))
  }
}

function* fetchGoodsDetailSaga(action) {
  try {
    const response = yield call(fetchGoodsDetailApi, action.payload) // 商品詳細データの取得API呼び出し
    yield put(fetchGoodsDetailSuccess(response))
  } catch (error) {
    yield put(fetchGoodsDetailError(error))
  }
}

function* GoodsSaga() {
  yield takeEvery(FETCH_GOODS, fetchGoodsSaga)
  yield takeEvery(ADD_GOOD, addGoodSaga)
  yield takeEvery(UPDATE_GOOD, updateGoodSaga)
  yield takeEvery(DELETE_GOOD, deleteGoodSaga)
  yield takeEvery(REGISTER_SURPRISE, registerSurpriseSaga)
  yield takeEvery(UNREGISTER_SURPRISE, unregisterSurpriseSaga)
  yield takeEvery(FETCH_GOODS_DETAIL, fetchGoodsDetailSaga)
}

export default GoodsSaga
