// reducer.js
import * as actionTypes from './actionTypes';

const initialState = {
  locationGroups: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_GROUPS:
      return { ...state, loading: true };
    case actionTypes.FETCH_LOCATION_GROUPS_SUCCESS:
      return { ...state, locationGroups: action.payload.location_groups, allGroups: action.payload.all_groups, loading: false };
    case actionTypes.FETCH_LOCATION_GROUPS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case actionTypes.UPDATE_LOCATION_GROUP:
      return { ...state, loading: true };
    case actionTypes.UPDATE_LOCATION_GROUP_SUCCESS:
      // UPDATE_LOCATION_GROUP_SUCCESSアクションのpayloadからlocationGroupsとallGroupsを更新
      return { 
        ...state, 
        locationGroups: action.payload.location_groups, 
        allGroups: action.payload.all_groups, 
        loading: false 
      };
    case actionTypes.UPDATE_LOCATION_GROUP_ERROR:
      // エラー情報をstateのerrorに設定
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default reducer;
