import {
  endOfMonth,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from "date-fns"
import { ja } from "date-fns/locale"

import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap"

import { format } from "date-fns"
import Pagination from "react-js-pagination"
import { useLocation } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get } from "../../helpers/api_helper.js"
import { fetchGraphData } from "../../store/sales/actions"
import ErrorMessage from "../Common/ErrorMessage"
import CommonComponent from "../CommonComponent"

const Sales = () => {
  const [locId, setLoc] = useState("")

  const dispatch = useDispatch()
  const [goodsBrand, setGoodsBrand] = useState(null)
  const [timePeriod, setTimePeriod] = useState("時")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [activePage, setActivePage] = useState(1)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(25)
  const [vendor_id, setVendor] = useState(null)
  const salesData = useSelector(state => state.salesReducer.graphData)
  const isLoading = useSelector(state => state.salesReducer.loading)

  const [isAggregateView, setIsAggregateView] = useState(false)

  // クエリパラメータを取得
  const location = useLocation()

  // クエリパラメータを解析する関数
  const query = new URLSearchParams(location.search)
  const month = query.get("month")
  const loc_id = query.get("loc_id")

  // 開始日と終了日のステートを日本語形式でセットする
  // 6か月前の月の初日に設定
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd", { locale: ja })
  ) // 開始日

  const [endDate, setEndDate] = useState(
    format(new Date(), "yyyy-MM-dd", { locale: ja })
  ) // 終了日

  const results = salesData && salesData.data ? salesData.data.results : []
  const totalItemsCount =
    salesData && salesData.data ? salesData.data.totalItemsCount : 0

  const [isLoadingCSV, setIsLoadingCSV] = useState(false)

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1)
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber)

    if (isAggregateView) {
      dispatch(
        fetchGraphData(
          goodsBrand,
          locId,
          "集",
          currentDate,
          previousDate,
          pageNumber,
          itemsCountPerPage,
          startDate,
          endDate
        )
      )
    } else {
      dispatch(
        fetchGraphData(
          goodsBrand,
          locId,
          timePeriod,
          currentDate,
          previousDate,
          pageNumber,
          itemsCountPerPage,
          startDate,
          endDate
        )
      )
    }
  }

  useEffect(() => {
    if (locId !== "") {
      setActivePage(1) // ページ番号を1にリセットします
      if (isAggregateView) {
        dispatch(
          fetchGraphData(
            goodsBrand,
            locId,
            "集",
            currentDate,
            previousDate,
            1,
            itemsCountPerPage,
            startDate,
            endDate
          )
        )
      } else {
        dispatch(
          fetchGraphData(
            goodsBrand,
            locId,
            timePeriod,
            currentDate,
            previousDate,
            1,
            itemsCountPerPage,
            startDate,
            endDate
          )
        )
      }
    }
  }, [locId, startDate, endDate, itemsCountPerPage])

  useEffect(() => {
    if (month && loc_id) {
      setActivePage(1) // ページ番号を1にリセットします
      const monthDate = parseISO(month + "-01") // '2023-07-01' の形式にしてDateオブジェクトに変換
      const start = startOfMonth(monthDate) // 月の最初の日
      const end = endOfMonth(monthDate) // 月の最後の日

      // DateオブジェクトをISO 8601形式に変換してから、日付部分のみを取得
      const formattedStart = start.toISOString().split("T")[0] // 'YYYY-MM-DD' 形式
      const formattedEnd = end.toISOString().split("T")[0] // 'YYYY-MM-DD' 形式

      setStartDate(formattedStart)
      setEndDate(formattedEnd)
      setLoc(parseInt(loc_id))
    }
  }, [month, loc_id])

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  function displayPayType(payType) {
    const isMobile = window.innerWidth < 500
    // モバイル時に「決済」「"販売」を削除する

    // パラメータがundefinedまたはnullの場合、"不明"などのデフォルト値を返す
    if (!payType) return "不明"

    if (payType.includes("現金")) payType = "現金"
    else if (payType.includes("カード")) payType = "ｶｰﾄﾞ等"
    else if (payType.includes("テスト")) payType = "テスト"
    else if (payType.includes("フリーベンド")) payType = "ﾌﾘｰﾍﾞﾝﾄﾞ"
    else if (payType.includes("搬出失敗")) payType = "搬出失敗"
    else payType = "その他"

    return payType
  }

  // パラメータをクエリストリングに変換する関数
  const toQueryString = params => {
    const queryParams = new URLSearchParams()

    let re_timePeriod = ""
    if (params.timePeriod === "月") {
      re_timePeriod = "year"
    } else if (params.timePeriod === "日") {
      re_timePeriod = "month"
    } else if (params.timePeriod === "時") {
      re_timePeriod = "day"
    } else if (params.timePeriod === "3か月") {
      re_timePeriod = "threeMonth"
    } else {
      re_timePeriod = params.timePeriod
    }

    const currentDateStr = formatDate(params.currentDate)
    const previousDateStr = formatDate(params.previousDate)

    if (params.goodsBrand) {
      queryParams.append("goodsBrand", params.goodsBrand)
    }
    if (params.locId) {
      queryParams.append("locId", params.locId)
    }
    queryParams.append("timePeriod", re_timePeriod)
    queryParams.append("currentDate", currentDateStr)
    queryParams.append("previousDate", previousDateStr)
    queryParams.append("items_per_page", params.itemsCountPerPage)

    if (params.startDate) {
      queryParams.append("start_date", params.startDate)
    }
    if (params.endDate) {
      queryParams.append("end_date", params.endDate)
    }

    return queryParams.toString()
  }

  const fetchCSVData = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT || ""
    const params = {
      goodsBrand,
      locId,
      timePeriod: isAggregateView ? "集" : timePeriod,
      currentDate,
      previousDate,
      activePage,
      itemsCountPerPage,
      startDate,
      endDate,
    }

    // クエリストリングを生成
    const queryString = toQueryString(params)
    const apiUrl = `${apiBaseUrl}/api/v2/sales?${queryString}`

    try {
      // get関数を使用してAPIを呼び出し
      const data = await get(apiUrl)

      return data.results // データを返す
    } catch (error) {
      console.error("There was a problem with the get operation:", error)
      throw error
    }
  }

  const handleCSVDownload = async () => {
    setIsLoadingCSV(true) // ローディング開始

    const csvData = await fetchCSVData()
    await downloadCSV(csvData)

    setIsLoadingCSV(false) // ローディング終了
  }

  const convertToCSV = data => {
    let headers = []
    let rows = []

    if (isAggregateView) {
      // 集計モードのCSVヘッダー
      headers = ["ロケーション名", "商品名", "合計売上個数", "合計金額"]

      // 集計モードのデータ行
      rows = data.map(item =>
        [
          item.loc_name,
          `"${item.goods_name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          item.total_sales_number,
          item.total_price,
        ].join(",")
      )
    } else {
      // 通常モードのCSVヘッダー
      headers = [
        "日付",
        "ロケーション名",
        "商品名",
        "単価",
        "売上金額",
        "支払タイプ",
      ]

      // 通常モードのデータ行
      rows = data.map(item =>
        [
          item.date,
          item.loc_name,
          `"${item.goods_name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          item.price,
          item.sales_number,
          item.pay_type,
        ].join(",")
      )
    }

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const downloadCSV = data => {
    // CSVをダウンロード
    const csvString = convertToCSV(data)
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)

    // currentDateとpreviousDateをYYYYMMDD形式に変換
    let prefix = ""
    let searchString = "対象自販機名" // これは検索したい文字列です

    if (csvString && csvString.includes(searchString)) {
      prefix = "sales_aggregate_data"
    } else {
      prefix = "sales_data"
    }
    // ファイル名にcurrentDateとpreviousDateを追加
    const fileName = `${prefix}_${locId ?? "all"}_${startDate}_${endDate}.csv`
    link.setAttribute("download", fileName)

    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleAggregateClick = () => {
    setIsAggregateView(true)
    dispatch(
      fetchGraphData(
        goodsBrand,
        locId,
        "集",
        currentDate,
        previousDate,
        1,
        itemsCountPerPage,
        startDate,
        endDate
      )
    )
  }

  const handleNormalClick = () => {
    setIsAggregateView(false)
    setActivePage(1) // ページ番号を1にリセットします
    dispatch(
      fetchGraphData(
        goodsBrand,
        locId,
        timePeriod,
        currentDate,
        previousDate,
        1,
        itemsCountPerPage,
        startDate,
        endDate
      )
    )
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="販売履歴" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "750px" }}>
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <Row>
                    <Col xs={12} md={8}>
                      <CommonComponent
                        showGoodsBrand={false}
                        showDate={false}
                        showAll={true}
                        goodsBrand={goodsBrand}
                        setGoodsBrand={setGoodsBrand}
                        locId={locId}
                        setLoc={setLoc}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        previousDate={previousDate}
                        vendor_id={vendor_id}
                        setVendor={setVendor}
                        past_loc={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={4}>
                      <FormGroup>
                        <label htmlFor="startDate">開始日：</label>
                        <Input
                          type="date"
                          name="startDate"
                          id="startDate"
                          value={startDate}
                          onChange={e => setStartDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={4}>
                      <FormGroup>
                        <label htmlFor="endDate">終了日：</label>
                        <Input
                          type="date"
                          name="endDate"
                          id="endDate"
                          value={endDate}
                          onChange={e => setEndDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <FormGroup className="mb-0 w-100">
                        <label
                          htmlFor="csvButton"
                          style={{ visibility: "hidden" }}
                        >
                          ダウンロード
                        </label>
                        {isLoadingCSV && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              zIndex: 9999,
                            }}
                          >
                            <div style={{ color: "white", fontSize: "2em" }}>
                              CSVをダウンロードしています...
                            </div>
                          </div>
                        )}
                        <Button
                          id="csvButton"
                          color="info"
                          onClick={handleCSVDownload}
                          className="w-100"
                          style={{ height: "38px" }}
                        >
                          CSVをダウンロード
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                <CardBody>
                  <CardTitle>販売状況</CardTitle>
                  <Row>
                    <Col xs={12} md={8} className="d-flex align-items-center">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Row className="w-100 mb-2">
                        <Col xs={6} className="px-1">
                          <Button
                            color={isAggregateView ? "secondary" : "orange"}
                            className="w-100"
                            style={{ height: "38px" }}
                            onClick={handleNormalClick}
                          >
                            通常
                          </Button>
                        </Col>
                        <Col xs={6} className="px-1">
                          <Button
                            color={isAggregateView ? "orange" : "secondary"}
                            className="w-100"
                            style={{ height: "38px" }}
                            onClick={handleAggregateClick}
                          >
                            集計
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Input
                      type="select"
                      name="perPage"
                      id="perPageSelect"
                      value={itemsCountPerPage}
                      onChange={e =>
                        setItemsCountPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Input>
                  </FormGroup>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        {isAggregateView ? (
                          <>
                            <th>ロケーション名</th>
                            <th>商品名</th>
                            <th class="salec-count">合計売上個数</th>
                            <th class="sales-amount">合計金額</th>
                          </>
                        ) : (
                          <>
                            <th>販売日時</th>
                            <th>商品名</th>
                            <th class="sales-amount">売上</th>
                            <th class="sales-pay-type">支払</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {results.length > 0 ? (
                        results.map((result, index) => (
                          <tr key={index}>
                            {isAggregateView ? (
                              <>
                                <td>{result.loc_name}</td>
                                <td>{result.goods_name}</td>
                                <td class="sales-count">
                                  {result.sales_number !== undefined &&
                                  result.sales_number !== null
                                    ? result.sales_number.toLocaleString()
                                    : ""}
                                </td>
                                <td class="sales-amount">
                                  {result.total_price
                                    ? result.total_price.toLocaleString()
                                    : ""}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>{result.date}</td>
                                <td>{result.goods_name}</td>
                                <td class="sales-amount">
                                  {result.sales_number !== undefined &&
                                  result.sales_number !== null
                                    ? result.sales_number.toLocaleString()
                                    : ""}
                                </td>
                                <td class="sales-type">
                                  {displayPayType(result.pay_type)}
                                </td>
                              </>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4} style={{ textAlign: "center" }}>
                            データがありません
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Sales
