import React, { useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { fetchCompanies } from "../../../store/companies/actions"
import { addGood } from "../../../store/goods/actions"

const AddItemModal = ({
  isOpen,
  toggle,
  newItem,
  setNewItem,
  toggleAddItemModal,
}) => {
  const dispatch = useDispatch()
  const companies = useSelector(state => state.companyReducer.companiesData)

  const handleAddNewItem = newItem => {
    dispatch(addGood(newItem))
    toggle()
  }

  useEffect(() => {
    dispatch(fetchCompanies()) // 会社リストを取得するアクションをディスパッチ
  }, [dispatch])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>新規商品追加</ModalHeader>
      <ModalBody>
        {/* フォームの内容 */}
        <FormGroup>
          <Label for="goods_no">商品コード</Label>
          <Input
            id="goods_no"
            value={newItem.goods_no}
            onChange={e => setNewItem({ ...newItem, goods_no: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label for="goods_name">商品名</Label>
          <Input
            id="goods_name"
            value={newItem.goods_name}
            onChange={e =>
              setNewItem({ ...newItem, goods_name: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="company">会社</Label>
          <Input
            type="select"
            id="company_id"
            value={newItem.company_id}
            onChange={e =>
              setNewItem({ ...newItem, company_id: e.target.value })
            }
          >
            <option value="">会社を選択</option>
            {companies.map(company => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        {/* その他のフォーム要素 */}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={() => handleAddNewItem(newItem)}>
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddItemModal
