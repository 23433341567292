// actions.js
import {
  FETCH_VEHICLES,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_ERROR,
  ADD_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_ERROR,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_ERROR,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  FETCH_VEHICLE_STOCK,
  FETCH_VEHICLE_STOCK_SUCCESS,
  FETCH_VEHICLE_STOCK_ERROR,
  ADD_VEHICLE_STOCK,
  ADD_VEHICLE_STOCK_SUCCESS,
  ADD_VEHICLE_STOCK_ERROR,
  UPDATE_VEHICLE_STOCK,
  UPDATE_VEHICLE_STOCK_SUCCESS,
  UPDATE_VEHICLE_STOCK_ERROR,
  DELETE_VEHICLE_STOCK,
  DELETE_VEHICLE_STOCK_SUCCESS,
  DELETE_VEHICLE_STOCK_ERROR,
} from "./actionTypes"

export const fetchVehicles = () => ({
  type: FETCH_VEHICLES,
})

export const fetchVehiclesSuccess = vehicles => ({
  type: FETCH_VEHICLES_SUCCESS,
  payload: vehicles,
})

export const fetchVehiclesError = error => ({
  type: FETCH_VEHICLES_ERROR,
  error,
})

export const addVehicle = vehicle => ({
  type: ADD_VEHICLE,
  payload: vehicle,
})

export const addVehicleSuccess = vehicle => ({
  type: ADD_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const addVehicleError = error => ({
  type: ADD_VEHICLE_ERROR,
  error,
})

export const updateVehicle = vehicle => ({
  type: UPDATE_VEHICLE,
  payload: vehicle,
})

export const updateVehicleSuccess = vehicle => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const updateVehicleError = error => ({
  type: UPDATE_VEHICLE_ERROR,
  error,
})

export const deleteVehicle = vehicleId => ({
  type: DELETE_VEHICLE,
  payload: vehicleId,
})

export const deleteVehicleSuccess = vehicleId => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload: vehicleId,
})

export const deleteVehicleError = error => ({
  type: DELETE_VEHICLE_ERROR,
  error,
})

// 倉庫在庫の取得
export const fetchVehicleStock = vehicleId => ({
  type: FETCH_VEHICLE_STOCK,
  payload: vehicleId,
})

export const fetchVehicleStockSuccess = stock => ({
  type: FETCH_VEHICLE_STOCK_SUCCESS,
  payload: stock,
})

export const fetchVehicleStockError = error => ({
  type: FETCH_VEHICLE_STOCK_ERROR,
  error,
})

// 倉庫在庫の追加
export const addVehicleStock = vehicleData => ({
  type: ADD_VEHICLE_STOCK,
  payload: { vehicleData },
})

export const addVehicleStockSuccess = stock => ({
  type: ADD_VEHICLE_STOCK_SUCCESS,
  payload: stock,
})

export const addVehicleStockError = error => ({
  type: ADD_VEHICLE_STOCK_ERROR,
  error,
})

// 倉庫在庫の更新
export const updateVehicleStock = (vehicleId, goodsIds) => ({
  type: UPDATE_VEHICLE_STOCK,
  payload: { vehicleId, goodsIds },
})

export const updateVehicleStockSuccess = stock => ({
  type: UPDATE_VEHICLE_STOCK_SUCCESS,
  payload: stock,
})

export const updateVehicleStockError = error => ({
  type: UPDATE_VEHICLE_STOCK_ERROR,
  error,
})

// 倉庫在庫の削除
export const deleteVehicleStock = (vehicleStockId, vehicleId) => ({
  type: DELETE_VEHICLE_STOCK,
  payload: { vehicleStockId, vehicleId },
})

export const deleteVehicleStockSuccess = stock => ({
  type: DELETE_VEHICLE_STOCK_SUCCESS,
  payload: stock,
})

export const deleteVehicleStockError = error => ({
  type: DELETE_VEHICLE_STOCK_ERROR,
  error,
})
