// actions.js
import {
  FETCH_GOODS,
  FETCH_GOODS_SUCCESS,
  FETCH_GOODS_ERROR,
  ADD_GOOD,
  ADD_GOOD_SUCCESS,
  ADD_GOOD_ERROR,
  UPDATE_GOOD,
  UPDATE_GOOD_SUCCESS,
  UPDATE_GOOD_ERROR,
  DELETE_GOOD,
  DELETE_GOOD_SUCCESS,
  DELETE_GOOD_ERROR,
  REGISTER_SURPRISE,
  REGISTER_SURPRISE_SUCCESS,
  REGISTER_SURPRISE_ERROR,
  UNREGISTER_SURPRISE,
  UNREGISTER_SURPRISE_SUCCESS,
  UNREGISTER_SURPRISE_ERROR,
  FETCH_GOODS_DETAIL,
  FETCH_GOODS_DETAIL_SUCCESS,
  FETCH_GOODS_DETAIL_ERROR,
} from "./actionTypes"

// Fetch Goods Actions
export const fetchGoods = () => {
  return {
    type: FETCH_GOODS,
  }
}

export const fetchGoodsSuccess = goods => {
  return {
    type: FETCH_GOODS_SUCCESS,
    payload: goods,
  }
}

export const fetchGoodsError = error => {
  return {
    type: FETCH_GOODS_ERROR,
    error,
  }
}

// Add Good Actions
export const addGood = goodData => {
  return {
    type: ADD_GOOD,
    payload: goodData,
  }
}

export const addGoodSuccess = good => {
  return {
    type: ADD_GOOD_SUCCESS,
    payload: good,
  }
}

export const addGoodError = error => {
  return {
    type: ADD_GOOD_ERROR,
    error,
  }
}

// Update Good Actions
export const updateGood = (id, updateData) => {
  return {
    type: UPDATE_GOOD,
    payload: { id, updateData },
  }
}

export const updateGoodSuccess = good => {
  return {
    type: UPDATE_GOOD_SUCCESS,
    payload: good,
  }
}

export const updateGoodError = error => {
  return {
    type: UPDATE_GOOD_ERROR,
    error,
  }
}

// Delete Good Actions
export const deleteGood = (id, deleteData) => {
  return {
    type: DELETE_GOOD,
    payload: { id, deleteData },
  }
}

export const deleteGoodSuccess = good => {
  return {
    type: DELETE_GOOD_SUCCESS,
    payload: good,
  }
}

export const deleteGoodError = error => {
  return {
    type: DELETE_GOOD_ERROR,
    error,
  }
}

export const registerSurprise = (goodsId, surpriseItemId) => ({
  type: REGISTER_SURPRISE,
  payload: { goodsId, surpriseItemId },
})

export const registerSurpriseSuccess = goodsDetail => {
  return {
    type: REGISTER_SURPRISE_SUCCESS,
    payload: goodsDetail,
  }
}

export const registerSurpriseError = error => ({
  type: REGISTER_SURPRISE_ERROR,
  error,
})

export const unregisterSurprise = (goodsId, surpriseItemId) => ({
  type: UNREGISTER_SURPRISE,
  payload: { goodsId, surpriseItemId },
})

export const unregisterSurpriseSuccess = goodsDetail => {
  return {
    type: UNREGISTER_SURPRISE_SUCCESS,
    payload: goodsDetail,
  }
}

export const unregisterSurpriseError = error => ({
  type: UNREGISTER_SURPRISE_ERROR,
  error,
})

// Fetch Goods Detail Actions
export const fetchGoodsDetail = goodsId => {
  return {
    type: FETCH_GOODS_DETAIL,
    payload: goodsId,
  }
}

export const fetchGoodsDetailSuccess = goodsDetail => {
  return {
    type: FETCH_GOODS_DETAIL_SUCCESS,
    payload: goodsDetail,
  }
}

export const fetchGoodsDetailError = error => {
  return {
    type: FETCH_GOODS_DETAIL_ERROR,
    error,
  }
}
