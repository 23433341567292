// API Helperからputメソッドをインポート
import { put } from "../api_helper";
// URL Helperから必要なURLをインポート
import * as url from "../url_helper";

/**
 * ユーザープロファイルを更新する関数
 * 
 * @param {Object} user - 更新するユーザー情報が含まれるオブジェクト。
 *                         userオブジェクトにはusername, idx, email, newPassword, confirmPasswordが含まれる。
 * @returns {Promise<Object>} - APIレスポンスを返すPromiseオブジェクト。
 */
const postProfile = async (user) => {
  // パスワードが入力されているかどうかをチェックし、条件に応じてデータを構築
  const dataToSend = {
    username: user.username,
    idx: user.idx,
    email: user.email,
  };

  // 新しいパスワードが入力されている場合、それも送信データに含める
  if (user.newPassword && user.newPassword === user.confirmPassword) {
    dataToSend.password = user.newPassword;
  }

  // APIへのPUTリクエストを実行
  const response = await put(url.POST_PROFILE, dataToSend);

  return { data: response };
};

export { postProfile };
