import React from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { addWarehouseStock } from "../../../store/warehouse/actions"

const ProcurementModal = ({
  isOpen,
  toggle,
  selectedItemForProcurement,
  procurementInfo,
  handleSupplierChange,
  handleStock_countChange, // 忘れずに追加
}) => {
  const dispatch = useDispatch()

  // 仕入れ情報保存処理
  const handleSaveProcurement = () => {
    const newItem = {
      warehouse_id: selectedItemForProcurement.warehouse_id, // 必要な倉庫IDを追加
      goods_id: selectedItemForProcurement.goods_id,
      stock_count: procurementInfo.stock_count,
    }
    dispatch(addWarehouseStock(newItem))

    // 仕入れ情報保存のロジックをここに実装
    toggleProcurementModal()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>仕入れ情報</ModalHeader>
      <ModalBody>
        {selectedItemForProcurement && (
          <>
            <p>商品名: {selectedItemForProcurement.goods_name}</p>
            <p>現在の数量: {selectedItemForProcurement.stock_count}</p>
            <FormGroup>
              <Label for="supplierSelect">仕入れ先</Label>
              <Input
                type="select"
                id="supplierSelect"
                value={procurementInfo.supplier}
                onChange={handleSupplierChange}
              >
                {/* 仕入れ先の選択肢をマッピング */}
                {procurementInfo.suppliers &&
                procurementInfo.suppliers.length > 0 ? (
                  procurementInfo.suppliers.map(supplier => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))
                ) : (
                  <option value="">仕入れ先がありません</option>
                )}
              </Input>
            </FormGroup>
            <p>リードタイム: {procurementInfo.leadTime} 日</p>
            <p>連絡先: {procurementInfo.contact}</p>
            <FormGroup>
              <Label for="purchaseStock_count">仕入れ数量</Label>
              <Input
                type="number"
                id="purchaseStock_count"
                value={procurementInfo.stock_count}
                onChange={e => handleStock_countChange(e.target.value)}
              />
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleSaveProcurement}>
          実行
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProcurementModal
