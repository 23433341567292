import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { fetchVehicles, fetchVehicleStock } from "../../store/vehicle/actions"
import {
  fetchItemsForLoading,
  updateDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions"
import classnames from "classnames"

const DeliveryPlanVehicle = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { startLocationName, deliveryPlan } = location.state

  const [selectedVehicle, setSelectedVehicle] = useState(
    deliveryPlan && deliveryPlan.plan && deliveryPlan.plan.vehicle_id >= 0
      ? deliveryPlan.plan.vehicle_id
      : ""
  )
  const [showItems, setShowItems] = useState(false)
  const [confirmedItems, setConfirmedItems] = useState({})
  const [selectedIpcNos, setSelectedIpcNos] = useState([])
  const [modal, setModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [quantities, setQuantities] = useState({})
  const [groupedItems, setGroupedItems] = useState([])
  const [groupedByLocationItems, setGroupedByLocationItems] = useState({})
  const [vehicleStocksModal, setVehicleStocksModal] = useState(false) // Modal state for vehicle stocks
  const [activeTab, setActiveTab] = useState("1") // State for active tab
  const [completionModal, setCompletionModal] = useState(false)

  const vehicles = useSelector(state => state.vehicleReducer.vehicles || [])
  const items = useSelector(state => state.deliveryPlanReducer.items || [])
  const vehicleStocks = useSelector(
    state => state.vehicleReducer.vehicleStock || []
  ) // Retrieve vehicle stocks
  const isLoading1 = useSelector(state => state.vehicleReducer.loading)
  const isLoading2 = useSelector(state => state.deliveryPlanReducer.loading)

  useEffect(() => {
    dispatch(fetchVehicles())
  }, [dispatch, deliveryPlan])

  useEffect(() => {
    if (showItems) {
      const outOfStockItems = items.some(item => !item.in_warehouse_stock)
      if (outOfStockItems) {
        setAlertModal(true)
      }

      // 初期数量を設定
      const initialQuantities = items.reduce((acc, item) => {
        acc[item.id] = item.needed_quantity
        return acc
      }, {})
      setQuantities(initialQuantities)
    }
  }, [items, showItems])

  useEffect(() => {
    if (items.length > 0) {
      const groupedItems = items.reduce((acc, item) => {
        const existingItem = acc.find(i => i.name === item.name)
        if (existingItem) {
          existingItem.needed_quantity += item.needed_quantity
          existingItem.current_quantity += item.current_quantity
          existingItem.ipc_nos.push({
            ipc_no: item.ipc_no,
            needed_quantity: item.needed_quantity,
          })
        } else {
          acc.push({
            ...item,
            ipc_nos: [
              { ipc_no: item.ipc_no, needed_quantity: item.needed_quantity },
            ],
          })
        }
        return acc
      }, [])
      setGroupedItems(groupedItems)

      // loc_name毎に商品をグループ化
      const groupedByLocationItems = items.reduce((acc, item) => {
        if (!acc[item.loc_name]) {
          acc[item.loc_name] = []
        }
        acc[item.loc_name].push(item)
        return acc
      }, {})
      setGroupedByLocationItems(groupedByLocationItems)
    }
  }, [items])

  const handleSelectVehicle = e => {
    setSelectedVehicle(e.target.value)
  }

  const handleLoadItems = () => {
    const updatedPlan = {
      ...deliveryPlan,
      vehicle_id: parseInt(selectedVehicle, 10),
      status: 2,
      id: parseInt(deliveryPlan.id, 10),
    }

    dispatch(
      updateDeliveryPlanRequest({
        delivery_plan: updatedPlan,
      })
    )

    dispatch(
      fetchItemsForLoading({
        deliveryPlanId: deliveryPlan.id,
        vehicleId: selectedVehicle,
      })
    )
    setShowItems(true)
  }

  const handleConfirmItem = index => {
    setConfirmedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  const handleQuantityChange = (itemId, quantity) => {
    const newQuantity = Math.max(0, parseInt(quantity, 10))
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [itemId]: newQuantity,
    }))
  }

  const handleCompleteLoading = () => {
    const updatedItems = groupedItems.map(item => ({
      ...item,
      needed_quantity: quantities[item.id],
    }))

    const updatedPlan = {
      ...deliveryPlan,
      vehicle_id: parseInt(selectedVehicle, 10),
      status: 3,
      items: updatedItems,
      id: parseInt(deliveryPlan.id, 10),
    }

    dispatch(
      updateDeliveryPlanRequest({
        delivery_plan: updatedPlan,
      })
    )

    setCompletionModal(true)
  }

  const handleCloseCompletionModal = () => {
    setCompletionModal(false)
    navigate("/delivery-plan/loading") // 商品積込画面に戻る
  }

  const handleCancel = () => {
    const updatedPlan = {
      ...deliveryPlan,
      status: 1,
      id: parseInt(deliveryPlan.id, 10),
    }

    dispatch(
      updateDeliveryPlanRequest({
        delivery_plan: updatedPlan,
      })
    )

    navigate("/delivery-plan/select", {
      state: {
        deliveryPlan: updatedPlan,
      },
    })
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleAlertModal = () => {
    setAlertModal(!alertModal)
  }

  const toggleVehicleStocksModal = () => {
    if (!vehicleStocksModal && selectedVehicle) {
      dispatch(fetchVehicleStock(selectedVehicle))
    }
    setVehicleStocksModal(!vehicleStocksModal)
  }

  const handleItemClick = item => {
    setSelectedIpcNos(item)
    toggleModal()
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const allItemsConfirmed =
    Object.keys(confirmedItems).length > 0 &&
    Object.values(confirmedItems).every(value => value === true)

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#dcdcdc" }}>
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="ホーム"
            title="補充計画"
            breadcrumbItem="配送車選択"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2} />
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Card className="border p-3">
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    配送計画詳細
                  </CardTitle>
                  <hr />
                  <div
                    className="border p-3 mb-3"
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          margin: 0,
                        }}
                      >
                        計画No: {deliveryPlan.id}
                      </p>
                      <p style={{ margin: 0 }}>
                        作成時間: {deliveryPlan.created_at}
                      </p>
                    </div>
                  </div>
                  <div className="text-left">
                    <p>担当者: {deliveryPlan.user_name}</p>
                    <p>開始日時: {deliveryPlan.start_date}</p>
                  </div>
                  <CardTitle tag="h5">配送車選択</CardTitle>
                  <FormGroup>
                    <Label for="startLocationSelect">スタート地点</Label>
                    <Input
                      type="select"
                      id="startLocationSelect"
                      value={deliveryPlan.start_warehouse_id}
                      disabled
                    >
                      <option value={deliveryPlan.start_warehouse_id}>
                        {deliveryPlan.start_warehouse_name}
                      </option>
                    </Input>
                  </FormGroup>
                  <div className="text-center">↓</div>
                  <FormGroup>
                    <Label for="vehicleSelect">配送車を選択してください</Label>
                    <Input
                      type="select"
                      id="vehicleSelect"
                      value={selectedVehicle}
                      onChange={handleSelectVehicle}
                    >
                      <option value="">選択してください</option>
                      {vehicles.map(vehicle => (
                        <option
                          key={vehicle.vehicle_id}
                          value={vehicle.vehicle_id}
                        >
                          {vehicle.vehicle_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <div className="text-center mt-4">
                    <Button
                      color="primary"
                      onClick={handleLoadItems}
                      disabled={!selectedVehicle}
                      style={{ width: "150px" }}
                    >
                      商品積込
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={toggleVehicleStocksModal}
                      disabled={!selectedVehicle}
                      style={{ width: "150px" }}
                    >
                      配送車在庫確認
                    </Button>
                  </div>
                  {showItems && (
                    <div className="mt-4">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            まとめ
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            個別
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <h5 className="text-center">商品一覧（まとめ）</h5>
                          <Table bordered>
                            <thead>
                              <tr>
                                <th>商品名</th>
                                <th>個数</th>
                                <th>倉庫在庫</th>
                                <th>確認</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedItems.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() =>
                                      handleItemClick(item.ipc_nos)
                                    }
                                  >
                                    {item.name}
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      value={quantities[item.id]}
                                      min="0"
                                      onChange={e =>
                                        handleQuantityChange(
                                          item.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    {item.in_warehouse_stock
                                      ? "在庫あり"
                                      : "在庫なし"}
                                  </td>
                                  <td>
                                    <Button
                                      color={
                                        confirmedItems[index]
                                          ? "success"
                                          : "warning"
                                      }
                                      onClick={() => handleConfirmItem(index)}
                                      style={{
                                        width: "100px",
                                        backgroundColor: confirmedItems[index]
                                          ? "green"
                                          : "orange",
                                      }}
                                    >
                                      {confirmedItems[index]
                                        ? "確認済み"
                                        : "確認"}
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </TabPane>
                        <TabPane tabId="2">
                          <h5 className="text-center">商品一覧（個別）</h5>
                          {Object.entries(groupedByLocationItems).map(
                            ([locName, locItems], locIndex) => (
                              <div key={locIndex}>
                                <h6>{locName}</h6>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th>商品名</th>
                                      <th>個数</th>
                                      <th>倉庫在庫</th>
                                      <th>確認</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {locItems.map((item, index) => (
                                      <tr key={index}>
                                        <td
                                          onClick={() =>
                                            handleItemClick([
                                              {
                                                ipc_no: item.ipc_no,
                                                needed_quantity:
                                                  item.needed_quantity,
                                              },
                                            ])
                                          }
                                        >
                                          {item.name}
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            value={quantities[item.id]}
                                            min="0"
                                            onChange={e =>
                                              handleQuantityChange(
                                                item.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          {item.in_warehouse_stock
                                            ? "在庫あり"
                                            : "在庫なし"}
                                        </td>
                                        <td>
                                          <Button
                                            color={
                                              confirmedItems[index]
                                                ? "success"
                                                : "warning"
                                            }
                                            onClick={() =>
                                              handleConfirmItem(index)
                                            }
                                            style={{
                                              width: "100px",
                                              backgroundColor: confirmedItems[
                                                index
                                              ]
                                                ? "green"
                                                : "orange",
                                            }}
                                          >
                                            {confirmedItems[index]
                                              ? "確認済み"
                                              : "確認"}
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )
                          )}
                        </TabPane>
                      </TabContent>
                      <div className="text-center mt-3">
                        <Button
                          color="success"
                          onClick={handleCompleteLoading}
                          disabled={!allItemsConfirmed}
                          style={{ width: "150px" }}
                        >
                          積込完了
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="text-center mt-3">
                    <Button
                      color="danger"
                      onClick={handleCancel}
                      style={{ width: "150px" }}
                    >
                      キャンセル
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>IPC No</ModalHeader>
        <ModalBody>
          {selectedIpcNos &&
            selectedIpcNos.map((ipc, index) => (
              <div key={index}>
                {ipc.ipc_no}: {ipc.needed_quantity}個
              </div>
            ))}
        </ModalBody>
      </Modal>
      <Modal isOpen={alertModal} toggle={toggleAlertModal} centered>
        <ModalHeader
          toggle={toggleAlertModal}
          style={{
            backgroundColor: "rgba(255, 69, 0, 0.7)", // 赤みがかったオレンジ色
            color: "white", // 文字色を白に
          }}
        >
          注意
        </ModalHeader>
        <ModalBody
          style={{
            backgroundColor: "rgba(255, 99, 71, 0.7)", // 赤みがかったオレンジ色
            color: "white", // 文字色を白に
            textAlign: "center", // テキストを中央揃え
            fontSize: "1.2em", // 文字サイズを少し大きく
          }}
        >
          倉庫に在庫がない商品があります。責任者に確認してください。
        </ModalBody>
      </Modal>
      <Modal
        isOpen={vehicleStocksModal}
        toggle={toggleVehicleStocksModal}
        centered
      >
        <ModalHeader toggle={toggleVehicleStocksModal}>配送車在庫</ModalHeader>
        <ModalBody>
          <Table bordered>
            <thead>
              <tr>
                <th>商品コード</th>
                <th>商品名</th>
                <th>数量</th>
              </tr>
            </thead>
            <tbody>
              {vehicleStocks.map(stock => (
                <tr key={stock.goods_id}>
                  <td>{stock.goods_no}</td>
                  <td>{stock.goods_name}</td>
                  <td>{stock.stock_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
      <Modal isOpen={completionModal} toggle={handleCloseCompletionModal}>
        <ModalHeader toggle={handleCloseCompletionModal}>積込完了</ModalHeader>
        <ModalBody>
          積込が完了しました。配送実行は別途実施してください。
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCloseCompletionModal}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DeliveryPlanVehicle
