// actions.js
import {
  FETCH_USERS_DATA,
  FETCH_USERS_DATA_SUCCESS,
  FETCH_USERS_DATA_ERROR,
  FETCH_USERS_IPC_DATA,
  FETCH_USERS_IPC_DATA_SUCCESS,
  FETCH_USERS_IPC_DATA_ERROR,
  ADD_IPC_TO_USER,
  ADD_IPC_TO_USER_SUCCESS,
  ADD_IPC_TO_USER_ERROR,
  REMOVE_IPC_FROM_USER,
  REMOVE_IPC_FROM_USER_SUCCESS,
  REMOVE_IPC_FROM_USER_ERROR,
  FETCH_USER_DETAIL,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchUsersData = () => {
  return {
    type: FETCH_USERS_DATA,
    payload: {},
  }
}

// Fetch graph data success action
export const fetchUsersDataSuccess = payload => {
  return {
    type: FETCH_USERS_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchUsersDataError = error => {
  return {
    type: FETCH_USERS_DATA_ERROR,
    error,
  }
}
// Fetch graph data action
export const fetchUsersIPCData = user_id => {
  return {
    type: FETCH_USERS_IPC_DATA,
    payload: { user_id },
  }
}

// Fetch graph data success action
export const fetchUsersIPCDataSuccess = payload => {
  return {
    type: FETCH_USERS_IPC_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchUsersIPCDataError = error => {
  return {
    type: FETCH_USERS_IPC_DATA_ERROR,
    error,
  }
}

export const addIpcToUser = (userId, ipcNo) => {
  return {
    type: ADD_IPC_TO_USER,
    payload: {
      userId,
      ipcNo,
    },
  }
}

// Fetch graph data success action
export const addIpcToUserSuccess = payload => {
  return {
    type: ADD_IPC_TO_USER_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const addIpcToUserError = error => {
  return {
    type: ADD_IPC_TO_USER_ERROR,
    error,
  }
}

export const removeIpcFromUser = (userId, ipcNo) => {
  return {
    type: REMOVE_IPC_FROM_USER,
    payload: {
      userId,
      ipcNo,
    },
  }
}

// Fetch graph data success action
export const removeIpcFromUserSuccess = payload => {
  return {
    type: REMOVE_IPC_FROM_USER_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const removeIpcFromUserError = error => {
  return {
    type: REMOVE_IPC_FROM_USER_ERROR,
    error,
  }
}

export const fetchUserDetail = userId => {
  return {
    type: FETCH_USER_DETAIL,
    payload: userId,
  }
}

export const fetchUserDetailSuccess = payload => {
  return {
    type: FETCH_USER_DETAIL_SUCCESS,
    payload,
  }
}

export const fetchUserDetailFailure = error => {
  return {
    type: FETCH_USER_DETAIL_FAILURE,
    error,
  }
}

export const updateUserAction = updatedUser => {
  return {
    type: UPDATE_USER,
    payload: updatedUser,
  }
}

export const updateUserSuccess = payload => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  }
}

export const updateUserError = error => {
  return {
    type: UPDATE_USER_ERROR,
    error,
  }
}
